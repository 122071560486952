import { Dispatch } from "redux";

export const setAccountSignOn = (memberID, orgID, loginToken, loginTime) => {
  let tokenval = memberID+"."+orgID;
  localStorage.setItem('shakeTokenID', loginToken);
  localStorage.setItem('shakeTokenTime', loginTime);
  localStorage.setItem('shakeTokenVal', tokenval);
  return async (dispatch) => {
    dispatch({
      type: "SET_ACCOUNT_SIGNON",
      payload: {member_id: memberID, org_id:orgID, token: loginToken, token_time: loginTime},
    });
  };
};

export const clearAccountSignOn = () => {
  localStorage.removeItem('shakeTokenID');
  localStorage.removeItem('shakeTokenTime');
  localStorage.removeItem('shakeTokenVal');
  return async (dispatch) => {
    dispatch({
      type: "SET_ACCOUNT_SIGNON",
      payload: {member_id: 0, org_id: 0, token: '', token_time: ''},
    });
  };
};