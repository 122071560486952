import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { 
    WaitlistForm,
    SplashHeader, 
    FooterSplash } from '../../components'

export const Splash = (props) => {
    const { memberID, orgID } = useSelector((state) => state.login);

    useEffect(() => {
        document.body.style.backgroundColor="#4360CD";
      }, []);

    document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.modal');
        var instances = window.M.Modal.init(elems);
    });

    function openWaitlistForm() {
        var signupbox = document.getElementById("waitlist-form-modal");
        var signupbox_instance = window.M.Modal.getInstance(signupbox);
        signupbox_instance.open();
    }

    return (
    <><SplashHeader />
        <div className="row" style={{position:'relative',padding:'0px',margin:'0px',paddingBottom:'80px'}}>
            <div className="col s12 l6" style={{padding:'0px 40px',display:'none'}}>
                <img src="./assets/global/handshake.gif" style={{width:'100%',borderRadius:'20px'}} />
            </div>
            <div className="col s12 l6 white-text center-align" style={{padding:'0px 40px'}}>
                <div className="row headline-font" style={{marginTop:'4vh',marginBottom:'0px',padding:"20px",lineHeight:'1.25',fontSize:'56px'}}>
                    Take Your Creator Business to the Next Level
                </div>
                <div className="row subheadline-font" style={{marginBottom:'60px',fontSize:'28px',marginLeft:'10%',width:'80%'}}>
                    The creator tools you need to earn more, save time, & look professional
                </div>
                <div className="row center-align">
                    <div className="waves-effect waves-light white-text z-depth-2 left hide-on-med-and-down show-on-large modal-trigger" 
                        style={{background:'#132C51',width:'40%',marginLeft:'30%',borderRadius:'10px',fontSize:'24px',padding:'15px 20px',cursor:'pointer',border:'1px solid #132C51'}}
                        data-target="waitlist-form-modal" >
                        LET'S SHAKE
                    </div>
                    <div className="waves-effect waves-light white-text z-depth-2 left show-on-medium-and-down hide-on-large-only modal-trigger" 
                        style={{background:'#132C51',width:'80%',marginLeft:'10%',borderRadius:'10px',fontSize:'24px',padding:'15px 20px',cursor:'pointer',border:'1px solid #132C51',marginBottom:"50px"}} 
                        data-target="waitlist-form-modal">
                        LET'S SHAKE
                    </div>
                </div>
            </div>
            <div className="col s12 l6 transparent" id="contentpeoplegrid" style={{marginTop:'20px',marginLeft:'-20px',height:'65vh'}}>
                <div className="col s4" style={{height:'100%',textAlign:'center',overflow:'hidden'}}>
                    <div style={{height:'48.5%',marginBottom:'5%',width:'100%',textAlign:'center',overflow:'hidden',borderRadius:'5px',
                    background: 'url(assets/samples/creator_yoga_splash.jpg)', backgroundSize:'cover', backgroundPosition:'center'}}>
                    </div>
                    <div style={{height:'48.5%',width:'100%',textAlign:'center',overflow:'hidden',borderRadius:'5px',
                    background: 'url(assets/samples/creator_diy_splash.jpg)', backgroundSize:'cover', backgroundPosition:'center'}}>
                    </div>
                </div>
                <div className="col s4" style={{height:'99%',textAlign:'center',overflow:'hidden',borderRadius:'5px',
                background: 'url(assets/samples/creator_chef_splash.jpg)', backgroundSize:'cover',backgroundPosition:'center'}}>
                </div>
                <div className="col s4" style={{height:'100%',textAlign:'center',overflow:'hidden'}}>
                    <div style={{height:'48.5%',marginBottom:'5%',width:'100%',textAlign:'center',overflow:'hidden',borderRadius:'5px',
                        background: 'url(assets/samples/creator_plants_splash.jpg)', backgroundSize:'cover', backgroundPosition:'center'}}>
                    </div>
                    <div style={{height:'48.5%',width:'100%',textAlign:'center',overflow:'hidden',borderRadius:'5px',
                    background: 'url(assets/samples/creator_cooking_splash.jpg)', backgroundSize:'cover', backgroundPosition:'center'}}>
                    </div>
                </div>
            </div>
        </div>
        <div className="row" style={{position:'relative',padding:'40px',margin:'0px',background:'#CEDDE7'}}>
            <div className="col s12 m10 offset-m1 center-align" style={{marginTop:'40px',marginBottom:'30px'}}>
                <div className="row headline-font" style={{fontSize:'40px',color:'#132C51'}}>
                    The Workflow Trusted by <u style={{textDecorationColor:'#FF5052',textUnderlineOffset:'5px'}}>50K+ Creators</u>
                </div>
            </div>
        </div>
        <div className="row" style={{position:'relative',padding:'80px 40px',margin:'0px',background:'#FCF5EC',color:'#132C51'}}>
            <div className="col s12 m10 offset-m1">
                <div className="col s12 center-align">
                    <div className="row headline-font" style={{fontSize:'44px',padding:'10px 20px'}}>
                        GREAT COLLABS START WITH SHAKE
                    </div>
                </div>
                <div className="col s12 m6" style={{padding:'20px'}}>
                    <div className="left headline-font" style={{width:'100%',fontSize:'24px'}}>
                        <h5 className="subheadlines ub-fonts ub_subheadline-fonts" style={{fontWeight:'700'}}>
                            <span style={{verticalAlign:'sub'}}>
                                <i className="small material-icons material-symbols-outlined">dashboard_customize</i>
                            </span>
                            <span style={{marginLeft:'10px',verticalAlign:'text-top'}}> 
                                DYNAMIC RATE CARDS
                            </span>
                        </h5>
                    </div>
                    <div className="left" style={{width:'100%',fontSize:'16px'}}>
                        The power of an agent, without the commission. Track your deal flow, maximize your time, and automate follow ups.
                    </div>
                </div>
                <div className="col s12 m6" style={{padding:'20px'}}>
                    <div className="left headline-font" style={{width:'100%',fontSize:'24px'}}>
                        <h5 className="subheadlines ub-fonts ub_subheadline-fonts" style={{fontWeight:'700'}}>
                            <span style={{verticalAlign:'sub'}}>
                                <i className="small material-icons material-symbols-outlined">request_quote</i>
                            </span>
                            <span style={{marginLeft:'10px',verticalAlign:'text-top'}}> 
                                BEAUTIFUL PROPOSALS
                            </span>
                        </h5>
                    </div>
                    <div className="left" style={{width:'100%',fontSize:'16px'}}>
                        Win more business by being professional. Brands want to work with creators they can trust. Put your best foot forward.
                    </div>
                </div>
                <div className="col s12 m6" style={{padding:'20px'}}>
                    <div className="left headline-font" style={{width:'100%',fontSize:'24px'}}>
                        <h5 className="subheadlines ub-fonts ub_subheadline-fonts" style={{fontWeight:'700'}}>
                            <span style={{verticalAlign:'sub'}}>
                                <i className="small material-icons material-symbols-outlined">history_edu</i>
                            </span>
                            <span style={{marginLeft:'10px',verticalAlign:'text-top'}}> 
                                STREAMLINED CONTRACTS
                            </span>
                        </h5>
                    </div>
                    <div className="left" style={{width:'100%',fontSize:'16px'}}>
                        Professional agreements made for collabs. Fast to create, easy to sign, powerful protection of your work.
                    </div>
                </div>
                <div className="col s12 m6" style={{padding:'20px'}}>
                    <div className="left headline-font" style={{width:'100%',fontSize:'24px'}}>
                        <h5 className="subheadlines ub-fonts ub_subheadline-fonts" style={{fontWeight:'700'}}>
                            <span style={{verticalAlign:'sub'}}>
                                <i className="small material-icons material-symbols-outlined">payments</i>
                            </span>
                            <span style={{marginLeft:'10px',verticalAlign:'text-top'}}> 
                                FLEXIBLE PAYOUTS
                            </span>
                        </h5>
                    </div>
                    <div className="left" style={{width:'100%',fontSize:'16px'}}>
                        Secure and flexible. Payments on your terms, easy invoicing and pro level analytics to share.
                    </div>
                </div>
            </div>
        </div>
        <div className="row" style={{position:'relative',padding:'80px 40px',margin:'0px',background:'#77E7A5',color:'#132C51'}}>
            <div className="col s12 m6 center-align">
                <div className="row headline-font" style={{fontSize:'30px',marginBottom:'30px',width:'80%',marginLeft:'10%'}}>
                    About Us
                </div>
                <div className="row center-align" style={{width:'80%',marginLeft:'10%'}}>
                Shake is built by the team at BrandBacker, a pioneer in the influencer space that has been working with creators for over a decade. 
                Our workflow and tools have been meticulously refined after working with 50,000+ creators across hundreds of thousands of collabs & partnerships. 
                <br/><br/>
                Shake lets you formalize your collaboration with any brand, creator, client, customer or project. It adds transparency, security, keeps 
                everyone on the same page, and streamlines your workflow. Free up your time so you can get back to creating.
                </div>
            </div>
            <div className="col s12 m6 center-align">
                <div className="row headline-font" style={{fontSize:'60px',marginBottom:'50px'}}>
                    Signup Free
                </div>
                <div className="row center-align">
                    <div className="waves-effect waves-light white-text z-depth-2 left hide-on-med-and-down show-on-large modal-trigger" 
                        style={{background:'#132C51',width:'50%',marginLeft:'25%',borderRadius:'10px',fontSize:'24px',padding:'15px 20px',cursor:'pointer',border:'0px solid #29b6f6'}} 
                        data-target="waitlist-form-modal">
                        LET'S SHAKE
                    </div>
                    <div className="waves-effect waves-light white-text z-depth-2 left show-on-medium-and-down hide-on-large-only modal-trigger" 
                        style={{background:'#132C51',width:'80%',marginLeft:'10%',borderRadius:'10px',fontSize:'24px',padding:'15px 20px',cursor:'pointer',border:'0px solid #29b6f6',marginBottom:"50px"}} 
                        data-target="waitlist-form-modal">
                        LET'S SHAKE
                    </div>
                </div>
            </div>
        </div>
        <FooterSplash />
        <div id="waitlist-form-modal" className="modal">
            <WaitlistForm />
        </div>
    </>
    );
};