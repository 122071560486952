import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import {axiosInstance, closeAllModals} from "../../utils";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import '../../stylesheets/payments.css';
import {StripeCheckoutForm, InvoicePaidBox, InvoiceDeleteBox} from "../../components";

let invoice_info = [];
let stripe_form_loaded = 0;
let final_amount_due = 0;
let final_invoice_currency = 0;
// stripe live publishable API key.
//const stripePromise = loadStripe("pk_live_51OZmxrLR1BXIiMOrr2xEjKNOLLW66ZDeLOiCXpbx1MNDI5PDXG8JUbOecVXdtZ1dGSmu7zCVTaQMJvRBuv3DIc0I000cBX0D80");

// stripe test publishable API key.
const stripePromise = loadStripe("pk_test_51OZmxrLR1BXIiMOrTZwRfXgqyJjKcYct1R1H5nnh0yoNwsT3pXv0F97hnBiNBUMN0drgKbl2X4IuK5OX81TVHGwV00ObysoQz4");
const InvoiceViewer = (props) => {    
    const { memberID, orgID } = useSelector((state) => state.login);

    const [pageSet, setPage] = useState(false);
    const [headerHTML, setHeaderHTML] = useState(null);
    const [linksHTML, setLinksHTML] = useState(null);
    const [optionHTML, setOptionHTML] = useState(null);
    const [invoiceHTML, setInvoiceHTML] = useState([]);
    const [invoicePaymentHTML, setInvoicePaymentHTML] = useState([]);
    const [backButtonHTML,setBackButtonHTML] = useState(null);
    const [invoiceSummary, setInvoiceSummary] = useState([]);
    const [invoiceActionButtons,setInvoiceActionButtons] = useState(null);
    const [paymentSuccessHTML, setPaymentSuccessHTML] = useState([]);
    const [invoicePaidModal,setInvoicePaidModal] = useState(null);
    const [invoiceDeleteModal,setInvoiceDeleteModal] = useState(null);
    const [invoiceID, setInvoiceID] = useState(props.invoiceID);
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [invoiceTitle, setInvoiceTitle] = useState('');
    const [stripeFormHTML,setStripeFormHTML] = useState(null);
    const [stripeClientSecret, setStripeClientSecret] = useState(null);

    let startingRate = 0;

    useEffect(() => {
        document.body.style.backgroundColor="#F7F7F7";
    }, []);

    function createStripeForm(amount,currency) {
        var non_decimal_amount = amount*100;
        var addData = new Object();
        addData.item = encodeURIComponent('free'+non_decimal_amount);
        axiosInstance.post('payments/create_payment/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var session_details = result.data[0]; 
                if(session_details['status'] == 'success') {
                    var clientSecret = session_details['checkout']['clientSecret'];
                    setStripeClientSecret(clientSecret);
                    loadStripeForm(clientSecret,amount,currency);
                } else {
                    console.log('Stripe Issue ',session_details['checkout']['error']);
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function loadBackButton() {
        if(props.publicPage == 1) { 
            setBackButtonHTML(<div className="right" style={{marginRight:'10px',marginTop:'7px',fontSize:'16px',cursor:'pointer'}}>
                            <a href="/" className="white-text"><img src="./assets/global/shake_logo_blue.png" height="35px"/></a>
                            </div>);
        } else {
            setBackButtonHTML(<div className="right" style={{marginRight:'20px',marginTop:'12px',fontSize:'16px',cursor:'pointer'}} onClick={()=>props.goBackLink()}>Back</div>);
        }
    }

    function loadStripeForm(clientSecret,amount,currency) {
        if(clientSecret) {
            const appearance = {
                theme: 'stripe',
            };
            let tmp_options = {
                clientSecret,
                appearance,
                
            }
            let tmp_html = <Elements options={tmp_options} stripe={stripePromise}>
                                <StripeCheckoutForm paymentSuccess={showPaymentSuccess} 
                                                    invoiceID={props.invoiceID} 
                                                    orgID={orgID} 
                                                    paymentAmount={amount} 
                                                    paymentCurrency={currency} />
                            </Elements>;
            setStripeFormHTML(tmp_html);
        }
    }

    function grabInvoiceInfo() {
        if(props.invoiceID != null) {
            setInvoiceID('ILN7pOy9Id');
        } else {
            setInvoiceID(props.invoiceID);
        }
        var addData = new Object();
        addData.invoiceUniqID = encodeURIComponent(invoiceID);
        axiosInstance.post('payments/invoice_info/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var invoice_details = result.data; 
                console.log(invoice_details);
                invoice_info = invoice_details;
                if(invoice_details.length > 0) {
                    loadInvoiceButtons();
                    loadInvoiceInfo(invoice_details[0]);
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function loadInvoiceInfo(idetails) {
        var invoice_id = idetails['invoice_id'];
        var invoice_unique_id = idetails['invoice_unique_id'];
        var invoice_number_alt = idetails['invoice_alternate_number'];
        var invoice_status = idetails['invoice_status'];

        var org_owner_name = idetails['org_first_name']+' '+idetails['org_last_name'];
        var org_location = idetails['org_location'];
        var org_email = idetails['org_email_address'];
        var org_name = idetails['org_name'];

        var client_name = idetails['client_name'];
        var client_website = idetails['client_website'];
        var client_email = idetails['client_email_address'];
        var client_company = idetails['client_company_name'];

        var invoice_due_date_terms = idetails['due_date_terms_days'];
        var invoice_date_start = idetails['date_invoice_start'];
        var invoice_date_due = idetails['date_invoice_due'];
        var start_date_month = idetails['invoice_start_month'];
        var due_date_month = idetails['invoice_due_month'];
        var start_date_text = idetails['invoice_start_monthname'].substring(0,3)+" "+idetails['invoice_start_day']+", "+ idetails['invoice_start_year'];
        var due_date_text = idetails['invoice_due_monthname'].substring(0,3)+" "+idetails['invoice_due_day']+", "+ idetails['invoice_due_year'];
        var invoice_days_remaining = idetails['days_remaining']; 

        var invoice_subtotal = idetails['subtotal'];
        var invoice_fees = idetails['platform_fees'];
        var invoice_amount_paid = idetails['amount_paid'];
        var invoice_total_billed = idetails['total_due'];
        var invoice_total_due = idetails['total_due'] - idetails['amount_paid'];
        var invoice_currency = idetails['invoice_currency'];
        var invoice_custom_notes = idetails['custom_notes'];
        var invoice_items = idetails['invoice_items'];
        var payment_items = idetails['payment_items'];

        final_amount_due = invoice_total_due;
        final_invoice_currency = invoice_currency;

        var currency_symbol = '$';
        if(invoice_currency == 'USD') {
            currency_symbol = '$';
            final_invoice_currency = invoice_currency;
        }
        setInvoiceNumber(invoice_number_alt);

        if(props.publicPage == 1) { 
            setInvoiceTitle('Payment Invoice');
        } else {
            setInvoiceTitle('Invoice #'+invoice_number_alt);
        }

        var invoice_notes = <>Thank you for your business! <br/>Please contact us at support@shake.com for any questions about this invoice.</>;
        if(invoice_custom_notes.length > 0) {
            invoice_notes = invoice_custom_notes;
        }

        var item_html = [];
        var item_subtext = '';
        for(var j = 0; j < invoice_items.length;j++) {
            var item_name = invoice_items[j]['item_name'];
            var item_description = invoice_items[j]['item_description'];
            var item_price = invoice_items[j]['item_price'];
            var item_quantity = invoice_items[j]['item_quantity'];
            var item_total = invoice_items[j]['item_total'];
            var tmp_item_html = <><div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px'}}>
                                    <div className="col s12 m2" style={{padding:'0px 10px'}}>
                                        {item_name}
                                    </div>
                                    <div className="col s12 m4" style={{padding:'0px 10px'}}>
                                        {item_description}
                                    </div>
                                    <div className="col s12 m2 right-align" style={{padding:'0px 10px'}}>
                                        {currency_symbol+item_price.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    </div>
                                    <div className="col s12 m2 center" style={{padding:'0px 10px'}}>
                                        {item_quantity}
                                    </div>
                                    <div className="col s12 m2 right-align" style={{padding:'0px 10px'}}>
                                        {currency_symbol+item_total.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    </div>
                                </div>
                                <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px'}}>
                                    <div className="section-divider"></div>
                                </div></>;
            item_html.push(tmp_item_html);

            if(j > 0) {
                item_subtext = item_subtext+" + ";
            }
            item_subtext = item_subtext+item_description;
        }


        var payment_html = [];
        for(var j = 0; j < payment_items.length;j++) {
            var payment_date = payment_items[j]['payment_month']+'/'+payment_items[j]['payment_day']+'/'+payment_items[j]['payment_year'];
            var payment_total = payment_items[j]['payment_amount'];
            var payment_source = payment_items[j]['payment_source'];
            var payment_method = payment_items[j]['payment_method'];
            var payment_unique_id = payment_items[j]['payment_unique_id'];
        
            var payment_description = 'Paid by '+payment_method;
            if(payment_source == 'stripe') {
                var final_payment_method = 'Credit Card';
                var final_payment_status = 'APPROVED';
                var final_payment_card_type = 'Visa'
                var final_payment_card_last4 = '8251';
                payment_description = final_payment_method+' - '+final_payment_status+' - '+final_payment_card_type+' | Last 4 Digits: '+final_payment_card_last4;
            } else if(payment_method == 'wire') {
                payment_description = 'Paid by Wire Transfer - RECEIVED';
            } else if(payment_method == 'check') {
                payment_description = 'Paid by Check - RECEIVED';
            } else if(payment_method == 'app') {
                payment_description = 'Completed via Payment App - RECEIVED';
            } else if(payment_method == 'cash') {
                payment_description = 'Paid by Cash - RECEIVED';
            } else if(payment_method == 'crypto') {
                payment_description = 'Paid via Crypto Transfer - RECEIVED';
            } else if(payment_method == 'credit') {
                payment_description = 'Completed via Credit Card - External - APPROVED';
            } else if(payment_method == 'other') {
                payment_description = 'Paid via Other Method - RECEIVED';
            }

            var tmp_payment_html = <><div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px'}}>
                                    <div className="col s12 m2" style={{padding:'0px 10px'}}>
                                        {payment_date}
                                    </div>
                                    <div className="col s12 m8" style={{padding:'0px 10px'}}>
                                        {payment_description}
                                    </div>
                                    <div className="col s12 m2 right-align" style={{padding:'0px 10px'}}>
                                        {currency_symbol+payment_total.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    </div>
                                </div>
                                <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px'}}>
                                    <div className="section-divider"></div>
                                </div></>;
            payment_html.push(tmp_payment_html);
        }

        // Setup Total HTML For Different States (Payment Made Or Not Made)
        var tmp_html_subtotal = <>
                                <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'10px'}}>
                                    <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                        Subtotal
                                    </div>
                                    <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                        {currency_symbol+invoice_subtotal.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    </div>
                                </div>
                                <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'10px'}}>
                                    <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                        Platform Fees
                                    </div>
                                    <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                        {currency_symbol+invoice_fees.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    </div>
                                </div>
                                <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'0px'}}>
                                    <div className="section-divider"></div>
                                </div>
                                <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'10px'}}>
                                    <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                        Total Billed
                                    </div>
                                    <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                        {currency_symbol+invoice_total_billed.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    </div>
                                </div>
                                </>;

        var tmp_html_full = <>
                            <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'10px'}}>
                                <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                    Subtotal
                                </div>
                                <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                    {currency_symbol+invoice_subtotal.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                </div>
                            </div>
                            <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'10px'}}>
                                <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                    Platform Fees
                                </div>
                                <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                    {currency_symbol+invoice_fees.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                </div>
                            </div>
                            <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'10px'}}>
                                <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                    Amount Paid
                                </div>
                                <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                    -{currency_symbol+invoice_amount_paid.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                </div>
                            </div>
                            <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'0px'}}>
                                <div className="section-divider"></div>
                            </div>
                            <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'10px',fontSize:'20px',fontWeight:'bold'}}>
                                <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                    Total
                                </div>
                                <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                    {currency_symbol+invoice_total_due.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                </div>
                            </div>
                            </>;

        var tmp_html_paidtotal =  <>
                            <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'10px'}}>
                                <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                    Total Billed
                                </div>
                                <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                    {currency_symbol+invoice_total_billed.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                </div>
                            </div>
                            <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'10px'}}>
                                <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                    Amount Paid
                                </div>
                                <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                    -{currency_symbol+invoice_amount_paid.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                </div>
                            </div>
                            <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'0px'}}>
                                <div className="section-divider"></div>
                            </div>
                            <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'10px',fontSize:'20px',fontWeight:'bold'}}>
                                <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                    Total Due
                                </div>
                                <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                    {currency_symbol+invoice_total_due.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                </div>
                            </div></>;
        

        // If Payment Made Move The Notes Section to Bottom
        var items_total_rows = <><div className="left-align" style={{flex:'1',padding:'0px 10px'}}>
                                        <div className="row hide-on-med-and-down show-on-large-only" style={{borderRadius:'10px',padding:'0px 10px',marginTop:'30px',marginBottom:'10px'}}>
                                            Notes:
                                        </div>
                                        <div className="row hide-on-med-and-down show-on-large-only" style={{borderRadius:'10px',padding:'0px 10px',marginTop:'10px',marginBottom:'10px',maxWidth:'300px'}}>
                                            {invoice_notes}
                                        </div>
                                    </div>
                                    <div className="right-align" style={{padding:'0px 10px',minWidth:'300px'}}>
                                        {tmp_html_full}
                                    </div></> ;
        if(payment_items.length > 0) {
            items_total_rows = <><div className="left-align" style={{flex:'1',padding:'0px 10px'}}>
                                                &nbsp;
                                        </div>
                                        <div className="right-align" style={{padding:'0px 10px',minWidth:'300px'}}>
                                            {tmp_html_subtotal}
                                        </div></>;
        }       

        // Setup Organization Header If Public Page
        var org_rows = <></>;
        if(props.publicPage == 1) {
            org_rows = <div className="row" style={{marginTop:'0px',marginBottom:'10px'}}>
                                <div className="col s12 m6" style={{padding:'10px'}}>
                                    <div className="row" style={{margin:'0px',padding:"0px 5px"}}>
                                        {org_name}
                                    </div>
                                    <div className="row" style={{margin:'0px',padding:"0px 5px"}}>
                                        {org_owner_name}
                                    </div>
                                    <div className="row" style={{margin:'0px',padding:"0px 5px"}}>
                                        {org_email}
                                    </div>
                                </div>
                            </div>;
        }


        // Primary HTML 
        var tmp_html = <>
                        {org_rows}
                        <div className="row">
                            <div className="col s12 m6" style={{padding:'10px'}}>
                                <div className="row" style={{margin:'0px',padding:"0px 5px"}}>
                                    Bill To
                                </div>
                                <div className="row" style={{margin:'0px',padding:"0px 5px"}}>
                                    {client_company}
                                </div>
                                <div className="row" style={{margin:'0px',padding:"0px 5px"}}>
                                    {client_name}
                                </div>
                                <div className="row" style={{margin:'0px',padding:"0px 5px"}}>
                                    {client_website}
                                </div>
                            </div>
                            <div className="col s12 m6 right-align  show-on-med-and-up hide-on-small-only" style={{padding:'10px'}}>
                                <div className="row" style={{margin:'0px',padding:"0px 5px"}}>
                                    <div className="col s7 m8">
                                        Invoice ID:
                                    </div>
                                    <div className="col s5 m4">
                                        {invoice_number_alt}
                                    </div>
                                </div>
                                <div className="row" style={{margin:'0px',padding:"0px 5px"}}>
                                    <div className="col s7 m8">
                                        Invoice Date:
                                    </div>
                                    <div className="col s5 m4">
                                        {start_date_text}
                                    </div>
                                </div>
                                <div className="row" style={{margin:'0px',padding:"0px 5px"}}>
                                    <div className="col s7 m8">
                                        Terms:
                                    </div>
                                    <div className="col s5 m4">
                                        Net {invoice_due_date_terms} Days
                                    </div>
                                </div>
                                <div className="row" style={{margin:'0px',padding:"0px 5px",fontWeight:'bold'}}>
                                    <div className="col s7 m8">
                                        Due Date:
                                    </div>
                                    <div className="col s5 m4">
                                        {due_date_text}
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 left-align show-on-small-only hide-on-med-and-up" style={{padding:'10px'}}>
                                <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px'}}>
                                    <div className="section-divider"></div>
                                </div>
                                <div className="row" style={{margin:'0px',padding:"0px 5px"}}>
                                    <div className="col s7 m8">
                                        Invoice ID:
                                    </div>
                                    <div className="col s5 m4">
                                        {invoice_number_alt}
                                    </div>
                                </div>
                                <div className="row" style={{margin:'0px',padding:"0px 5px"}}>
                                    <div className="col s7 m8">
                                        Invoice Date:
                                    </div>
                                    <div className="col s5 m4">
                                        {start_date_text}
                                    </div>
                                </div>
                                <div className="row" style={{margin:'0px',padding:"0px 5px"}}>
                                    <div className="col s7 m8">
                                        Terms:
                                    </div>
                                    <div className="col s5 m4">
                                        Net {invoice_due_date_terms} Days
                                    </div>
                                </div>
                                <div className="row" style={{margin:'0px',padding:"0px 5px",fontWeight:'bold'}}>
                                    <div className="col s7 m8">
                                        Due Date:
                                    </div>
                                    <div className="col s5 m4">
                                        {due_date_text}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12" style={{padding:'10px 20px'}}>
                                <div className="row light-blue-background" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px'}}>
                                    <div className="col s12 m2" style={{padding:'10px'}}>
                                        Item
                                    </div>
                                    <div className="col s12 m4" style={{padding:'10px'}}>
                                        Description
                                    </div>
                                    <div className="col s12 m2 right-align" style={{padding:'10px'}}>
                                        Unit Price
                                    </div>
                                    <div className="col s12 m2 center" style={{padding:'10px'}}>
                                        Quantity
                                    </div>
                                    <div className="col s12 m2 right-align" style={{padding:'10px'}}>
                                        Total
                                    </div>
                                </div>
                                {item_html}
                                <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',display:'flex',flexDirection:'row'}}>
                                    {items_total_rows}
                                </div>
                            </div>
                        </div>
                            </>;       
        
        var tmp_html_payments = <>
                                        <div className="row">
                                            <div className="col s12" style={{padding:'10px 20px'}}>
                                                <div className="row light-blue-background" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px'}}>
                                                    <div className="col s12 m2" style={{padding:'10px'}}>
                                                        Date
                                                    </div>
                                                    <div className="col s12 m8" style={{padding:'10px'}}>
                                                        Payment Details
                                                    </div>
                                                    <div className="col s12 m2 right-align" style={{padding:'10px'}}>
                                                        Total Paid
                                                    </div>
                                                </div>
                                                {payment_html}
                                                <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',display:'flex',flexDirection:'row'}}>
                                                    <div className="left-align" style={{flex:'1',padding:'0px 10px'}}>
                                                        <div className="row hide-on-med-and-down show-on-large-only" style={{borderRadius:'10px',padding:'0px 10px',marginTop:'20px',marginBottom:'10px'}}>
                                                            Notes:
                                                        </div>
                                                        <div className="row hide-on-med-and-down show-on-large-only" style={{borderRadius:'10px',padding:'0px 10px',marginTop:'10px',marginBottom:'10px',maxWidth:'300px'}}>
                                                            {invoice_notes}
                                                        </div>
                                                    </div>
                                                    <div className="right-align" style={{padding:'0px 10px',minWidth:'300px'}}>
                                                        {tmp_html_paidtotal}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>;  
        
        var tmp_html2 = <><div className="row">
                            <div className="col s12" style={{padding:'10px'}}>
                                <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',display:'flex',flexDirection:'row'}}>
                                    <div className="col s12" style={{flex:'1',padding:'0px 10px'}}>
                                        <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'0px',marginTop:'20px'}}>
                                            Pay {org_name}
                                        </div>
                                        <div className="row headline-font" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',fontSize:'30px'}}>
                                            {currency_symbol+invoice_total_due.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                        </div>
                                        <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',marginTop:'40px',fontSize:'20px'}}>
                                            <b>Payment Details</b>
                                        </div>
                                        <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'0px'}}>
                                            <div className="section-divider"></div>
                                        </div>
                                        <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'10px',marginTop:'0px'}}>
                                            <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                                <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'0px'}}>
                                                    Content Creation Contract
                                                </div>
                                                <div className="row grey-text darken-4" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'0px'}}>
                                                    {item_subtext}
                                                </div>
                                            </div>
                                            <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                                {currency_symbol+invoice_subtotal.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                            </div>
                                        </div>
                                        <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'0px'}}>
                                            <div className="section-divider"></div>
                                        </div>
                                        <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'10px'}}>
                                            <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                                Subtotal
                                            </div>
                                            <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                                {currency_symbol+invoice_subtotal.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                            </div>
                                        </div>
                                        <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'10px'}}>
                                            <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                                Platform Fees
                                            </div>
                                            <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                                {currency_symbol+invoice_fees.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                            </div>
                                        </div>
                                        <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'10px'}}>
                                            <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                                Amount Paid
                                            </div>
                                            <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                                -{currency_symbol+invoice_amount_paid.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                            </div>
                                        </div>
                                        <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'0px'}}>
                                            <div className="section-divider"></div>
                                        </div>
                                        <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'10px',fontSize:'20px',fontWeight:'bold'}}>
                                            <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                                Total Due
                                            </div>
                                            <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                                {currency_symbol+invoice_total_due.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            </>;
        
        var tmp_html3 = <><div className="row">
            <div className="col s12" style={{padding:'10px'}}>
                <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',display:'flex',flexDirection:'row'}}>
                    <div className="col s12 center-align" style={{flex:'1',padding:'0px 10px'}}>
                        <div className="row headline-font" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',fontSize:'30px'}}>
                        <span className="material-symbols-outlined green-text" style={{fontSize:'60px'}}>task_alt</span>
                        </div>
                        <div className="row headline-font" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',fontSize:'30px'}}>
                            Payment Successful
                        </div>
                        <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',fontSize:'30px'}}>
                            {currency_symbol+invoice_total_due.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </div>
                        <div className="row" id="email-sent-section" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'0px',marginTop:'20px'}}>
                            Paid {org_name}
                        </div>
                        <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'0px',marginTop:'40px'}}>
                            <div className="section-divider"></div>
                        </div>
                        <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',marginTop:'0px',fontSize:'18px'}}>
                            <span className="material-symbols-outlined" style={{verticalAlign:'text-bottom'}}>print</span>&nbsp;&nbsp;Print Receipt
                        </div>
                        <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'0px'}}>
                            <div className="section-divider"></div>
                        </div>
                        <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',marginTop:'0px',fontSize:'18px'}}>
                            <span className="material-symbols-outlined" style={{verticalAlign:'text-bottom'}}>download</span>&nbsp;&nbsp;Download to PDF
                        </div>
                        <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'0px'}}>
                            <div className="section-divider"></div>
                        </div>
                        <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',marginTop:'0px',fontSize:'18px'}}>
                            <span className="material-symbols-outlined" style={{verticalAlign:'text-bottom'}}>home</span>&nbsp;&nbsp;Project Overview
                        </div>
                        <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'0px'}}>
                            <div className="section-divider"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>;



        setInvoiceHTML(tmp_html);
        setInvoiceSummary(tmp_html2);
        setPaymentSuccessHTML(tmp_html3);

        if(payment_items.length > 0) {
            setInvoicePaymentHTML(tmp_html_payments);
        } else {
            setInvoicePaymentHTML([]);
        }

        setInvoicePaidModal(<InvoicePaidBox invoiceID={props.invoiceID} paymentAmount={final_amount_due} paymentCurrency={final_invoice_currency} pageUpdate={reloadInvoiceInfo} />);
        setInvoiceDeleteModal(<InvoiceDeleteBox invoiceID={props.invoiceID} pageRedirect={props.goBackLink} />);
    }

    function reloadInvoiceInfo() {
        grabInvoiceInfo('reload_details');
    }

    function showPaymentForm() {
        if(stripe_form_loaded == 0) {
            createStripeForm(final_amount_due,final_invoice_currency);
            $("#payment-form").show();
            $("#payment-success-section").hide();
        }
        if($("#pay-now-form").is(":visible")) {
            $("#pay-now-form").slideUp();
        } else {
            $("#pay-now-form").slideDown();
        }
    }

    function showPaymentSuccess(emailedto) {
        $("#payment-form").fadeOut();
        setTimeout(function(){
            if(emailedto.length > 0) {
                $("#email-sent-section").html('Email Receipt Sent:<br/>'+emailedto);
                $("#email-sent-section").show();
            }
            $("#payment-success-section").fadeIn();

            
        },2000);
    }

    function loadInvoiceButtons() {
        var tmp_buttons = <></>;
        if(props.publicPage == 0) { 
            if(invoice_info[0]['invoice_status'] == 'C') {
                tmp_buttons = <>
                    <div className="left" style={{cursor:'pointer',borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',marginTop:'0px',fontSize:'18px'}} 
                            onClick={()=>openInvoiceLink()} >
                            <span className="material-symbols-outlined" style={{verticalAlign:'text-bottom'}}>globe</span>&nbsp;&nbsp;Invoice Link
                    </div>
                    <div className="left" style={{cursor:'pointer',borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',marginTop:'0px',fontSize:'18px'}} 
                            onClick={()=>openInvoiceLink()} >
                            <span className="material-symbols-outlined" style={{verticalAlign:'text-bottom'}}>download</span>&nbsp;&nbsp;Download to PDF
                    </div></>;
            } else {
                tmp_buttons = <>
                    <div className="left" style={{cursor:'pointer',borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',marginTop:'0px',fontSize:'18px'}} 
                            onClick={()=>openInvoiceLink()} >
                            <span className="material-symbols-outlined" style={{verticalAlign:'text-bottom'}}>globe</span>&nbsp;&nbsp;Pay Invoice Link
                    </div>
                    <div className="left" style={{cursor:'pointer',borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',marginTop:'0px',fontSize:'18px'}} 
                            onClick={()=>openInvoiceLink()} >
                            <span className="material-symbols-outlined" style={{verticalAlign:'text-bottom'}}>download</span>&nbsp;&nbsp;Download to PDF
                    </div>
                    <div className="left" style={{cursor:'pointer',borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',marginTop:'0px',fontSize:'18px'}} 
                            onClick={()=>loadMarkPaidModal()} >
                            <span className="material-symbols-outlined" style={{verticalAlign:'text-bottom'}}>check</span>&nbsp;&nbsp;Mark as Paid
                    </div>

                    <div className="right" style={{cursor:'pointer',borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',marginTop:'0px',fontSize:'18px'}} 
                            onClick={()=>loadDeleteInvoiceModal()} >
                            <span className="material-symbols-outlined" style={{verticalAlign:'text-bottom'}}>delete</span>&nbsp;&nbsp;Delete
                    </div>
                    <div className="right" style={{cursor:'pointer',borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',marginTop:'0px',fontSize:'18px'}} 
                            onClick={()=>editInvoiceForm()} >
                            <span className="material-symbols-outlined" style={{verticalAlign:'text-bottom'}}>edit</span>&nbsp;&nbsp;Edit Invoice
                    </div></>;
            }
            
        } else if(invoice_info[0]['invoice_status'] == 'P') {
            tmp_buttons = <div id="pay-now-button" className="waves-effect waves-light white-text z-depth-2" 
                                    style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD',marginRight:'20px'}} 
                                    onClick={()=>showPaymentForm()} >
                                    Pay Invoice Now
                            </div>;
        } else if(invoice_info[0]['invoice_status'] == 'C') {
            tmp_buttons = <>
            <div className="right" style={{cursor:'pointer',borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',marginTop:'0px',fontSize:'18px'}} 
                    onClick={()=>openInvoiceLink()} >
                    <span className="material-symbols-outlined" style={{verticalAlign:'text-bottom'}}>download</span>&nbsp;&nbsp;Download to PDF
            </div>
            <div className="right" style={{cursor:'pointer',borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',marginTop:'0px',fontSize:'18px'}} 
                    onClick={()=>openInvoiceLink()} >
                    <span className="material-symbols-outlined" style={{verticalAlign:'text-bottom'}}>print</span>&nbsp;&nbsp;Print Receipt
            </div>
            <div className="left" style={{cursor:'pointer',borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',marginTop:'0px',fontSize:'18px'}} 
                    onClick={()=>openInvoiceLink()} >
                    <span className="material-symbols-outlined" style={{verticalAlign:'text-bottom'}}>home</span>&nbsp;&nbsp;Project Overview
            </div>
            
            
            </>;
        }

        setInvoiceActionButtons(tmp_buttons);
    }

    function editInvoiceForm() {
        props.editInvoiceLink(props.invoiceID);
    }

    function openInvoiceLink() {
        window.open(window.location.origin+'/#/invoice/'+props.invoiceID);
    }

    function loadMarkPaidModal() {
        var modal_elem = document.getElementById("mark-paid-modal");
        var modal_instance = window.M.Modal.getInstance(modal_elem);
        modal_instance.open();
    }

    function loadDeleteInvoiceModal() {
        var modal_elem2 = document.getElementById("remove-invoice-modal");
        var modal_instance2 = window.M.Modal.getInstance(modal_elem2);
        modal_instance2.open();
    }

    if(pageSet == false) {
        setPage(true);
        setTimeout(function(){
            grabInvoiceInfo();
            loadBackButton();
        },50);
    }

    return (
    <div className="row subheadline-font" style={{position:'relative',fontSize:'16px',padding:'0px',margin:'0px',marginTop:'0px'}}>
                <div className="col s12 black-text" style={{padding:'10px'}}>
                    <div id="collab-request-section" className="row rounded-section white z-depth-1" style={{margin:'0px',padding:"0px",minHeight:'80vh'}}>
                        <div id="project-settings-form" className="col s12" style={{margin:'0px',padding:"20px 30px"}}>
                            <div className="row" style={{fontSize:'30px',margin:'0px 10px 15px 10px'}}>
                                <div className="left headline-font">{invoiceTitle}</div>
                                {backButtonHTML}
                            </div>
                            <div className="section-divider"></div>
                            <div className="row" style={{marginTop:'20px',marginBottom:'50px',display:'flex',flexDirection:'row'}}>
                                <div className="col s12" style={{flex:'1',padding:'0px 20px'}}>
                                    {invoiceHTML}
                                    {invoicePaymentHTML}
                                </div>
                            </div>
                            <div id="pay-now-divider" className="section-divider"></div>
                            <div className="row center-align" style={{fontSize:'30px',marginBottom:'0px',padding:'20px 30px'}}>
                                {invoiceActionButtons}
                            </div>
                            <div id="pay-now-divider" className="section-divider" style={{display:'none'}}></div>
                            <div id="pay-now-form" className="row" style={{display:'none',margin:'0px',border:'1px solid #EBEBEB',borderRadius:'10px'}}>
                                <div className="col s12 l6" style={{padding:'10px'}}>
                                    <div className="row" style={{margin:'0px'}}>
                                        <div className="col s12 m10 offset-m1" style={{padding:'10px'}}>
                                            {invoiceSummary}
                                        </div>
                                    </div>
                                </div>
                                <div className="col s12 show-on-medium-and-down hide-on-large-only">
                                    <div className="section-divider"></div>
                                </div>
                                <div id="payment-form" className="col s12 l6" style={{display:'none',padding:'0px 0px 20px 0px'}}>
                                    <div className="row" style={{margin:'0px',padding:'20px 40px',borderLeft:'1px solid #EBEBEB',marginTop:'30px'}}>
                                        {stripeFormHTML}
                                    </div>
                                </div>
                                <div id="payment-success-section" className="col s12 l6" style={{display:'none',padding:'0px 0px 20px 0px'}}>
                                    <div className="row" style={{margin:'0px',padding:'20px 40px',borderLeft:'1px solid #EBEBEB',marginTop:'30px'}}>
                                        {paymentSuccessHTML}
                                    </div>
                                </div>
                            </div>
                            <div className="row center-align" style={{fontSize:'30px',marginBottom:'20px'}}>
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
                {invoicePaidModal}
                {invoiceDeleteModal}
            </div>
    );
};

export default InvoiceViewer;