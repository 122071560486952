import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import {axiosInstance, closeAllModals, validateEmailText } from "../../utils";
import {setAccountSignOn} from "../../redux/Login/actions";

const LoginForm = (props) => {    
    const dispatch = useDispatch();
    const { memberID, orgID } = useSelector((state) => state.login);

    const [pageSet, setPage] = useState(false);
    const [currentEmailAddress, setCurrentEmailAddress] = useState(null);

    let startingRate = 0;

    useEffect(() => {
        document.body.style.backgroundColor="#F7F7F7";
    }, []);

    function submitEmailLogin() {
        var member_email = $("#memberEmail").val();

        var addData = new Object();
        addData.email = member_email;

        if(validateEmailText(member_email) == 'fail') {
            alert('Add Valid Email Address');
        } else {
            $("#submit-email-button").hide();
            $("#submit-email-loading").show();
            setCurrentEmailAddress(member_email);

            axiosInstance.post('member/login_email/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    let login_info = result.data;
                    if (login_info['status'] == 'success') {
                        setTimeout(function() {
                            $("#submit-email-button").show();
                            $("#submit-email-loading").hide();
                            showEmailCodeForm();
                        },2000);
                    } else {
                        setTimeout(function() {
                            $("#submit-email-button").show();
                            $("#submit-email-loading").hide();
                            alert('No Account Under This Email');
                        },2000);
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function confirmEmailLoginCode() {
        var member_email = $("#memberEmail").val();
        var email_code = $("#memberEmailCode").val();

        var addData = new Object();
        addData.email = member_email;
        addData.emailCode = email_code;

        if(email_code.length == 0) {
            alert('Add Confirmation Code Sent To Your Email');
        } else {
            $("#submit-code-button").hide();
            $("#submit-code-loading").show();

            axiosInstance.post('member/login_email_check/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    
                    let login_info = result.data[0];
                    if (login_info['status'] == 'success') {
                        // New User - Add to Users Table
                        let member_id = login_info['id'];
                        let org_id = login_info['orgID'];
                        let logintime = login_info['time'];
                        let logintoken = login_info['token'];

                        // Login to Site
                        dispatch(setAccountSignOn(member_id,org_id,logintoken,logintime));

                        setTimeout(function() {
                            $("#submit-code-button").show();
                            $("#submit-code-loading").hide();
                            goToNextStep();
                        },2000);
                    } else if (login_info['status'] == 'failed_code') {
                        setTimeout(function() {
                            $("#submit-code-button").show();
                            $("#submit-code-loading").hide();
                            alert('Enter an Active Email Code. Refresh and Try Again To Get A New Code');
                        },2000);
                    } else {
                        setTimeout(function() {
                            $("#submit-code-button").show();
                            $("#submit-code-loading").hide();
                            alert('No Account Under This Email');
                        },2000);
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function showEmailCodeForm() {
        $("#login-email-form").hide();
        $("#login-email-code-form").fadeIn();
    }

    function switchForms(formname) {
        props.changeForm(formname);
    }

    function goToNextStep() {
        window.location.href="#/payments";
    }

    if(pageSet == false) {
        setPage(true);
    }

    return (<>
        <div id="login-email-form">
            <div className="row" style={{fontSize:'30px',margin:'0px',marginTop:'10px'}}>
                <div className="left headline-font">Login To Account</div>
            </div>
            <div className="section-divider"></div>
            <div className="row" style={{marginTop:'10px',marginBottom:'0px',display:'flex',flexDirection:'row'}}>
                <div className="col s12" style={{flex:'1',padding:'0px 20px'}}>
                    <div className="row">
                        <div className="col s12" style={{padding:'10px 20px'}}>
                            <div className="row" style={{marginBottom:'10px'}}>
                                <div className="col s12" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                    <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                        Email Address
                                    </div>
                                    <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                        <input type="text" id="memberEmail"
                                        className="left browser-default rounded-input z-depth-1"
                                        style={{width:"98%",marginRight:'2%'}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom:'0px'}}>
                        <div id="submit-email-divider" className="section-divider"></div>
                    </div>
                    <div className="row" style={{fontSize:'18px',marginBottom:'0px',padding:'20px 30px',paddingRight:'10px'}}>
                        <div className="navy-link left" style={{cursor:'pointer',marginTop:'10px'}} onClick={()=>switchForms('signup')}>
                                Signup
                        </div>
                        <div id="submit-email-button" className="waves-effect waves-light white-text z-depth-2 right" 
                                style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD',marginRight:'20px'}} 
                                onClick={()=>submitEmailLogin()} >
                                Submit
                        </div>
                        <div id="submit-email-loading" style={{display:'none'}}>
                            <div className="preloader-wrapper small active right">
                                <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div><div className="gap-patch">
                                    <div className="circle"></div>
                                </div><div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="login-email-code-form" style={{display:'none'}}>
            <div className="row" style={{fontSize:'30px',margin:'0px',marginTop:'10px'}}>
                <div className="left headline-font">Confirm Your Email</div>
            </div>
            <div className="section-divider"></div>
            <div className="row" style={{marginTop:'10px',marginBottom:'0px',display:'flex',flexDirection:'row'}}>
                <div className="col s12" style={{flex:'1',padding:'0px 20px'}}>
                    <div className="row">
                        <div className="col s12" style={{padding:'10px 20px'}}>
                            <div className="row" style={{marginBottom:'10px'}}>
                                <div className="col s12">
                                    <div className="row" style={{fontSize:'20px',padding:'0px 10px',marginBottom:'30px'}}>
                                        Email Sent: {currentEmailAddress}
                                    </div>
                                </div>
                                <div className="col s12" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                    <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                        Email Confirmation Code:
                                    </div>
                                    <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                        <input type="text" id="memberEmailCode"
                                        className="left browser-default rounded-input z-depth-1"
                                        style={{width:"98%",marginRight:'2%'}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div id="submit-email-divider" className="section-divider"></div>
                    </div>
                    <div className="row" style={{fontSize:'18px',marginBottom:'0px',padding:'20px 30px',paddingRight:'10px'}}>
                        <div className="navy-link left" style={{cursor:'pointer',marginTop:'10px'}} onClick={()=>switchForms('signup')}>
                                Signup
                        </div>
                        <div id="submit-code-button" className="waves-effect waves-light white-text z-depth-2 right" 
                                style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD',marginRight:'20px'}}
                                onClick={()=>confirmEmailLoginCode()} >
                                Confirm Email
                        </div>
                        <div id="submit-code-loading" style={{display:'none'}}>
                            <div className="preloader-wrapper small active right">
                                <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div><div className="gap-patch">
                                    <div className="circle"></div>
                                </div><div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);
};

export default LoginForm;