import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import $ from "jquery";
import {axiosInstance, closeAllModals} from "../../utils";
import {ProjectSettings} from "../../components";

let project_info = []
let currentuser = 'creator';
let currentstep = 'confirm_proposal';
let current_project_id = 0;

export const ProjectManagerPublic = (props) => {    
    const { memberID, orgID } = useSelector((state) => state.login);

    const [pageSet, setPage] = useState(false);
    const [headerHTML, setHeaderHTML] = useState(null);
    const [linksHTML, setLinksHTML] = useState(null);
    const [deliverableListHTML, setDeliverableListHTML] = useState(null);
    const [messagesHTML, setMessagesHTML] = useState([]);
    const [progressHTML, setProgressHTML] = useState('');
    const [currentStepHTML, setCurrentStepHTML] = useState('');
    const [project_settings,setProjectSettings] = useState('');
    const [actionBoxSubheaderHTML, setActionBoxSubheaderHTML] = useState('');
    const [currentPrimaryActionText, setCurrentPrimaryActionText] = useState('Continue');
    const [currentPrimaryActionSubtext, setCurrentPrimaryActionSubtext] = useState('');
    const [primary_page_width, setPrimaryPageWidth] = useState('col s12 l10 offset-l1');
    const [primary_page_padding, setPrimaryPagePadding] = useState('20px');
    const [projectID, setProjectID] = useState(5);    
    const [currentRate, setCurrentRate] = useState(0);
    const [projectTitle, setProjectTitle] = useState('');
    const [project_budget,setProjectBudget] = useState(0);
    
    let startingRate = 0;

    useEffect(() => {
        document.body.style.backgroundColor="#F7F7F7";
        setTimeout(function(){
            resizeRequestWindow();
        },500);
        if(project_info.length > 0) {
            loadProjectInfo(project_info[0]);
        }
        if(props.projectID !== undefined) {
            setPrimaryPageWidth('col s12');
            setPrimaryPagePadding('0px');
            $("#project-public-header").hide();
            if(props.goBackLink !== undefined) {
                $("#project-back-button").show();
            }
            if(current_project_id != props.projectID) {
                current_project_id = props.projectID;
                setProjectSettings(<ProjectSettings projectID={current_project_id} cancelCallback={loadViewProject} />);
            }
        }
      }, []);

    function grabProjectInfo() {
        var addData = new Object();
        if(props.projectID !== undefined) {
            if(current_project_id != props.projectID) {
                current_project_id = props.projectID;
                setProjectSettings(<ProjectSettings projectID={current_project_id} cancelCallback={loadViewProject} />);
            }
            setPrimaryPageWidth('col s12');
            setPrimaryPagePadding('0px');
            $("#project-public-header").hide();
            setProjectID(props.projectID);
            addData.pid = encodeURIComponent(props.projectID);
            console.log(props.projectID);
        } else {
            addData.pid = encodeURIComponent(projectID);
        }

        axiosInstance.post('projects/project_info/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var project_details = result.data; 
                project_info = project_details;
                loadProjectInfo(project_info[0]);

                currentstep = 'confirm_proposal';
                if(project_info[0]['project_status'] == 'C') {
                    currentstep = 'project_summary';
                } else if (project_info[0]['project_status'] == 'A') {
                    currentstep = 'send_products';
                }
                
                loadCurrentStep(currentstep);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function loadProjectInfo(pdetails) {
        console.log(pdetails['project_name']);
        var projectname = pdetails['project_name']+' Collaboration';
        //projectname = 'Evian Podcast Feature';
        setProjectTitle(projectname);

        if(pdetails['project_amount'] == null) {
            pdetails['project_amount'] = 0;
        }

        var budget_text = pdetails['project_amount'].toLocaleString()+" "+pdetails['project_currency'];
        if(pdetails['project_currency'] == 'USD') {
            budget_text = '$'+budget_text;
        }
        setProjectBudget(budget_text);

        var main_background = '';
        if(main_background.length > 0) {
            document.body.style.background = main_background;
        }
        // var main_title = pdetails['rate_card_headline'];
        // var main_subtitle = pdetails['rate_card_bio'];
        // var main_location = pdetails['rate_card_location'];
        var main_title = 'Carmen Sandiego';
        var main_subtitle = 'Award Winning Designer, Creator and World Traveler';
        // main_location = 'Project Status: Project Proposal';
        var tmp_header = <>
                            <div className="row headline-font" style={{margin:'0px',fontSize:'36px'}}>
                                {main_title}
                            </div>
                            <div className="row" style={{margin:'0px',marginTop:'5px'}}>
                                {main_subtitle}
                            </div>
                            <div className="rounded-section" style={{border:'0px solid #EBEBEB',padding:'5px 7px',position:'absolute',top:'20px',right:'25px'}}>
                                
                            </div>
                        </>;
        setHeaderHTML(tmp_header);
        loadRateCardOptions(pdetails['project_deliverables']);
    }

    function loadRateCardLinks(links_list) {
        
        var final_links = new Array();
        for(var i = 0;i < links_list.length;i++) {
            var link_id = links_list[i]['rate_card_link_id'];
            var link_network = links_list[i]['link_type'];
            var link_title = links_list[i]['link_title'];
            var link_url = links_list[i]['link_url'];
            var tmp_link = <div key={i} className="col s12 m6" style={{padding:'10px'}}>
                            <div className="row rounded-section white z-depth-1" style={{margin:'0px',padding:"10px 15px",borderRadius:'5px'}}>
                                <span className="left">{link_title}</span>
                            </div>
                        </div>
            final_links.push(tmp_link);
        }

        setLinksHTML(final_links);
    }

    function loadRateCardOptions(options_list) {
        
        var final_options = new Array();
        for(var i = 0;i < options_list.length;i++) {
            var option_id = options_list[i]['project_deliverable_id'];
            var option_network = options_list[i]['deliverable_type'];
            var option_title = options_list[i]['deliverable_title'];
            var option_rate = options_list[i]['deliverable_rate'];
            var tmp_option = <div className="col s12" style={{marginBottom:'10px'}}>
                                    1 x {option_title}
                                </div>
                        
            final_options.push(tmp_option);
        }

        setDeliverableListHTML(final_options);
    }

    function toggleOptionSelect(e) {
        e.preventDefault();
        var option_id = e.target.dataset.optionid;
        var option_title = e.target.dataset.optiontitle;
        if($("#ig-static-post-"+option_id).html() == 'add') {
            $("#ig-static-post-"+option_id).html('check');
            $("#ig-static-post-"+option_id).addClass('green-text');
        } else {
            $("#ig-static-post-"+option_id).html('add');
            $("#ig-static-post-"+option_id).removeClass('green-text');
        }
        updateRequestSelectedRail();
    }

    function updateRequestSelectedRail() {
        var final_selections = new Array();
        var selected_rate = startingRate;
        var optionnum = 0;
        $(".rate-option").each(function(){
            if($(this).html() != 'add') {
                var tmp_selection = <><div key={optionnum} className="row" style={{margin:'0px',padding:'5px 10px',paddingTop:'0px',fontSize:'16px'}}>
                                    <span className="left">1 x {$(this).data('optiontitle')}</span>
                                    <span className="right material-icons material-symbols-outlined" data-optionid={$(this).data('optionid')} title="Remove Option"
                                        onClick={(e)=>toggleOptionSelect(e)} style={{cursor:'pointer',marginTop:'5px',fontSize:'16px'}}>delete</span>
                                </div>
                                <div className="section-divider"></div></>;
                final_selections.push(tmp_selection);
                selected_rate = selected_rate+parseInt($(this).data('optionrate'));
                optionnum = optionnum+1;
            }
        });
        if(final_selections.length == 0) {
            var tmp_selection = <><div key={0} className="row" style={{margin:'0px',padding:'5px 10px',paddingTop:'0px',fontSize:'16px'}}>
                                    Select An Option
                                </div>
                                <div className="section-divider"></div></>;
            final_selections.push(tmp_selection);
        }
        setCurrentRate(selected_rate);
        setTimeout(function(){
            resizeRequestWindow();
        },100);
    }

    function getSelectedOptions() {
        var final_selections = new Array();
        $(".rate-option").each(function(){
            if($(this).html() != 'add') {
                var tmp_selection = $(this).data('optionid');
                final_selections.push(tmp_selection);
            }
        });
        return final_selections;
    }

    function continueCollabForm() {
        var selectedOptions = getSelectedOptions();

        if(selectedOptions.length == 0) {
            alert('Choose a Collaboration Option');
        } else {
            $("#base-collab-form").hide();
            $("#finish-collab-form").fadeIn();
            $("#base-form-button").hide();
            $("#finish-form-button").fadeIn();
            resizeRequestWindow();
        }
    }

    function returnCollabForm() {
        $("#base-collab-form").fadeIn();
        $("#finish-collab-form").hide();
        $("#base-form-button").fadeIn();
        $("#finish-form-button").hide();
        resizeRequestWindow();
    }

    function resizeRequestWindow() {
        var newheight = ($("#collab-request-section").height()/2) - 20;
        var otherheight = $("#collab-right-summary").outerHeight()/2;
        $("#collab-message-summary").css({'min-height':newheight+'px'});
        $("#messages-text-section").css({'min-height':(newheight-240)+'px'});
        $("#collab-progress-summary").css({'min-height':newheight+'px'});
        // $("#progress-section").css({'min-height':(newheight-200)+'px'});
        // if(newheight > otherheight) {
        //     $("#collab-message-summary").css({'min-height':newheight+'px'});
        //     $("#messages-text-section").css({'min-height':(newheight-200)+'px'});
        //     $("#collab-progress-summary").css({'min-height':newheight+'px'});
        //     $("#progress-section").css({'min-height':(newheight-200)+'px'});
        // } else {
        //     $("#collab-message-summary").css({'min-height':otherheight+'px'});
        //     $("#messages-text-section").css({'min-height':(otherheight-200)+'px'});
        //     $("#collab-progress-summary").css({'min-height':otherheight+'px'});
        //     $("#progress-section").css({'min-height':(otherheight-200)+'px'});
        // }
    }

    function loadCurrentStep(stepname) {
        var actionboxadditional = <></>;
        var actionboxsub = <></>;
        var primaryactiontext = 'Inactive';
        var primarysubtext = 'This project is inactive, contact the project owner to get started.';

        if (stepname == 'send_products') {
            primaryactiontext = 'Confirm Materials Received';
            primarysubtext = 'Confirm when you receive all the necessary project materials to start working on the project.';
            actionboxsub = <><div className="row" style={{margin:'0px',marginBottom:'20px'}}>
                                    <div className="left headline-font">STEP 2:</div>
                                    <div className="left" style={{marginLeft:'5px'}}>Confirm Your Received Project Materials</div>
                                </div>
                                <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                    <div className="left waves-effect waves-light white-text z-depth-2" 
                                        style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD'}} 
                                        title="Confirm Project Materials Received"
                                        onClick={()=>continueNextStep()}>
                                        Confirm Received Materials
                                    </div>
                                </div></>;
            actionboxadditional = <><div className="col s12" style={{margin:'0px',padding:"0px 30px 20px 20px"}}>
                                        <div className="row" style={{fontSize:'16px',margin:'0px',marginTop:'0px',padding:'20px 0px',border:'1px solid #EBEBEB',borderRadius:'10px'}}>
                                            <div className="col s12 m6">
                                                <div className="col s12 headline-font center-align">
                                                    MAILING ADDRESS
                                                </div>
                                                <div className="col s12 center-align">
                                                    123 Lorimer St. Unit 123
                                                </div>
                                                <div className="col s12 center-align">
                                                    Brooklyn, NY 11211
                                                </div>
                                            </div>
                                            <div className="col s12 m6">
                                                <div className="col s12 headline-font center-align">
                                                    EMAIL
                                                </div>
                                                <div className="col s12 center-align">
                                                    carmensandiego@gmail.com
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </>

            if(currentuser == 'client') {
                primaryactiontext = 'Confirm  Materials Sent';
                primarysubtext = 'Send the necessary project materials including products and links to move forward with the project.';   
                actionboxsub = <><div className="row" style={{margin:'0px',marginBottom:'20px'}}>
                                    <div className="left headline-font">STEP 2:</div>
                                    <div className="left" style={{marginLeft:'5px'}}>Send all Required Project Materials. Confirm Once Sent.</div>
                                </div>
                                <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                    <div className="left waves-effect waves-light white-text z-depth-2" 
                                        style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD'}} 
                                        title="Confirm Project Materials Sent"
                                        onClick={()=>continueNextStep()}>
                                        Confirm Materials Sent
                                    </div>
                                </div></>;
            }

        } else if (stepname == 'submit_deliverable') {
            primaryactiontext = 'Submit Deliverable';
            primarysubtext = 'Submit your final deliverables for this project.';
            actionboxsub = <><div className="row" style={{margin:'0px',marginBottom:'20px'}}>
                                    <div className="left headline-font">STEP 3:</div>
                                    <div className="left" style={{marginLeft:'5px'}}>Submit your final deliverables for this project.</div>
                                </div>
                                <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                    <div className="left waves-effect waves-light white-text z-depth-2" 
                                        style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD'}} 
                                        title="Submit Project Deliverables"
                                        onClick={()=>continueNextStep()}>
                                        Submit Deliverables
                                    </div>
                                </div></>;
            actionboxadditional = <><div className="col s12" style={{margin:'0px',padding:"0px 30px 20px 20px"}}>
                                            <div className="row" style={{fontSize:'16px',margin:'0px',marginTop:'0px',padding:'20px 0px',border:'1px solid #EBEBEB',borderRadius:'10px'}}>
                                                <div className="col s12 headline-font center-align" style={{marginBottom:'10px'}}>
                                                    UPLOADED DELIVERABLES
                                                </div>
                                                <div className="col s12 center-align" style={{marginBottom:'10px'}}>
                                                    <b>Instagram Post:</b> https://www.instagram.com/p/CrbCMgEv3A3/
                                                </div>
                                                <div className="col s12 center-align" style={{marginBottom:'10px'}}>
                                                    <b>Instagram Story:</b>  https://www.instagram.com/p/CrbCMgEv3A3/
                                                </div>
                                            </div>
                                        </div>
                                </>
            if(currentuser == 'client') {
                primaryactiontext = 'Project In Progress';
                primarysubtext = 'The project is currently in progress. Use the messaging tool to get updates.';
                actionboxsub = <><div className="row" style={{margin:'0px',marginBottom:'20px'}}>
                                    <div className="left headline-font">STEP 3:</div>
                                    <div className="left" style={{marginLeft:'5px'}}>Project in Progress. Get Status Update Using Messaging Window.</div>
                                </div>
                                <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                    <div className="left waves-effect waves-light white-text z-depth-2" 
                                        style={{borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD'}} 
                                        title="Project In Progress"
                                        onClick={()=>continueNextStep()}>
                                        Project In Progress
                                    </div>
                                </div></>;   
            }
            
        } else if (stepname == 'pay_invoice') {
            primaryactiontext = 'Invoice Sent';
            primarysubtext = <>Deliverables submitted and your invoice is ready to be paid. <br/>The client has been notified and will complete the project by paying the invoice.</>;
            actionboxsub = <><div className="row" style={{margin:'0px',marginBottom:'20px'}}>
                                    <div className="left headline-font">STEP 4:</div>
                                    <div className="left" style={{marginLeft:'5px'}}>Client to Review & Pay Invoice</div>
                                </div>
                                <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                    <div className="left waves-effect waves-light white-text z-depth-2" 
                                        style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD'}} 
                                        title="Submit Additional Deliverables"
                                        onClick={()=>continueNextStep()}>
                                        Submit Additional Deliverables
                                    </div>
                                </div></>;
            actionboxadditional = <><div className="col s12" style={{margin:'0px',padding:"0px 30px 20px 20px"}}>
                                                <div className="row" style={{fontSize:'16px',margin:'0px',marginTop:'0px',padding:'20px 0px',border:'1px solid #EBEBEB',borderRadius:'10px'}}>
                                                    <div className="col s12 headline-font center-align" style={{marginBottom:'10px'}}>
                                                        FINAL DELIVERABLES
                                                    </div>
                                                    <div className="col s12 center-align" style={{marginBottom:'10px'}}>
                                                        <b>Instagram Post:</b> https://www.instagram.com/p/CrbCMgEv3A3/
                                                    </div>
                                                    <div className="col s12 center-align" style={{marginBottom:'10px'}}>
                                                        <b>Instagram Story:</b>  https://www.instagram.com/p/CrbCMgEv3A3/
                                                    </div>
                                                </div>
                                            </div>
                                    </>
            if(currentuser == 'client') {
                primaryactiontext = 'Confirm & Pay Invoice';
                primarysubtext = 'Review the final project deliverables. Pay the final invoice to close this project.';   
                actionboxsub = <><div className="row" style={{margin:'0px',marginBottom:'20px'}}>
                                    <div className="left headline-font">STEP 4:</div>
                                    <div className="left" style={{marginLeft:'5px'}}>Review Deliverables & Pay Invoice</div>
                                </div>
                                <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                    <div className="left waves-effect waves-light white-text z-depth-2" 
                                        style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD'}} 
                                        title="Confirm and Pay Invoice"
                                        onClick={()=>continueNextStep()}>
                                            Confirm & Pay Invoice
                                    </div>
                                </div></>;
            }
            
        } else if (stepname == 'project_summary') {
            primaryactiontext = 'Project Complete';
            primarysubtext = 'Find the final project deliverables and payment receipt.';
            actionboxsub = <><div className="row" style={{margin:'0px',marginBottom:'20px'}}>
                                    <div className="left headline-font">STATUS:</div>
                                    <div className="left" style={{marginLeft:'5px'}}>Project Complete</div>
                                </div>
                                <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                    <div className="left waves-effect waves-light white-text z-depth-2" 
                                        style={{borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD'}} 
                                        title="Project Complete">
                                        Project Complete
                                    </div>
                                </div></>;
            actionboxadditional = <><div className="col s12" style={{margin:'0px',padding:"0px 30px 20px 20px"}}>
                                            <div className="row" style={{fontSize:'16px',margin:'0px',marginTop:'0px',padding:'20px 0px',border:'1px solid #EBEBEB',borderRadius:'10px'}}>
                                                <div className="col s12 headline-font center-align" style={{marginBottom:'10px'}}>
                                                    FINAL DELIVERABLES
                                                </div>
                                                <div className="col s12 center-align" style={{marginBottom:'10px'}}>
                                                    <b>Instagram Post:</b> https://www.instagram.com/p/CrbCMgEv3A3/
                                                </div>
                                                <div className="col s12 center-align" style={{marginBottom:'10px'}}>
                                                    <b>Instagram Story:</b>  https://www.instagram.com/p/CrbCMgEv3A3/
                                                </div>
                                            </div>
                                        </div>
                                </>
            if(currentuser == 'client') {
                primaryactiontext = 'Project Complete';
                primarysubtext = 'Find the final project deliverables and your payment receipt.';   
            }
            
        } else if (stepname == 'confirm_proposal') {
            primaryactiontext = 'Edit Proposal';
            primarysubtext = 'Work with the client to adjust and accept your proposal.';
            actionboxsub = <><div className="row" style={{margin:'0px',marginBottom:'20px'}}>
                                    <div className="left headline-font">NEXT:</div>
                                    <div className="left" style={{marginLeft:'5px'}}>Client Confirms Details of Proposal</div>
                                </div>
                                <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                    <div className="left waves-effect waves-light white-text z-depth-2" 
                                        style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD'}} 
                                        onClick={()=>loadEditProject()}>
                                        Edit Proposal
                                    </div>
                                </div></>;

            if(currentuser == 'client') {
                primaryactiontext = 'Confirm & Get Started';
                primarysubtext = 'Get started on this project by agreeing to the collaboration details.';   
                actionboxsub = <><div className="row" style={{margin:'0px',marginBottom:'20px'}}>
                                    <div className="left headline-font">NEXT:</div>
                                    <div className="left" style={{marginLeft:'5px'}}>Confirm Details of Proposal to Get Started</div>
                                </div>
                                <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                    <div className="left waves-effect waves-light white-text z-depth-2" 
                                        style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD'}} 
                                        onClick={()=>continueNextStep()}>
                                        Confirm & Get Started
                                    </div>
                                </div></>;
            }
            
        } else {
            
        }
        setActionBoxSubheaderHTML(actionboxsub);
        setCurrentStepHTML(actionboxadditional);
        setCurrentPrimaryActionText(primaryactiontext);
        setCurrentPrimaryActionSubtext(primarysubtext);

        loadCurrentProgressBox(stepname);
    }


    function loadCurrentProgressBox(stepname) {
        var color_confirm_proposal = 'grey-text';
        var color_send_products = 'grey-text';
        var color_submit_deliverable = 'grey-text';
        var color_pay_invoice = 'grey-text';
        var color_project_complete = 'grey-text';

        var icon_confirm_proposal = 'radio_button_unchecked';
        var icon_send_products = 'radio_button_unchecked';
        var icon_submit_deliverable = 'radio_button_unchecked';
        var icon_pay_invoice = 'radio_button_unchecked';
        var icon_project_complete = 'radio_button_unchecked';

        if (stepname == 'send_products') {
            color_send_products = 'black-text';
            icon_confirm_proposal = 'check_circle';
            icon_send_products = 'radio_button_checked';

        } else if (stepname == 'submit_deliverable') {
            color_submit_deliverable = 'black-text';

            icon_confirm_proposal = 'check_circle';
            icon_send_products = 'check_circle';
            icon_submit_deliverable = 'radio_button_checked';
            
        } else if (stepname == 'pay_invoice') {
            color_pay_invoice = 'black-text';

            icon_confirm_proposal = 'check_circle';
            icon_send_products = 'check_circle';
            icon_submit_deliverable = 'check_circle';
            icon_pay_invoice = 'radio_button_checked';
            
        } else if (stepname == 'project_summary') {
            color_project_complete = 'green-text';

            icon_confirm_proposal = 'check_circle';
            icon_send_products = 'check_circle';
            icon_submit_deliverable = 'check_circle';
            icon_pay_invoice = 'check_circle';
            icon_project_complete = 'check_circle';
            
        } else if (stepname == 'confirm_proposal') {
            color_confirm_proposal = 'black-text';

            icon_confirm_proposal = 'radio_button_checked';
        } else {
            
        }
        var tmp_progress_html = <><div className={"col s12 "+color_confirm_proposal} style={{padding:'5px',display:'flex',flexDirection:'row'}}>
                                    <div className="left" style={{marginTop:'7px',marginRight:'10px'}}>
                                        <span class="material-symbols-outlined">{icon_confirm_proposal}</span>
                                    </div>
                                    <div className="left" style={{padding:'5px',flex:'1'}}>
                                        <div className="left headline-font" style={{width:'100%',fontSize:'16px',margin:'0px'}}>
                                            STEP 1: CONFIRM PROJECT DETAILS
                                        </div>
                                        <div className="left" style={{width:'100%',margin:'0px'}}>
                                            Review and agree to the project scope, details and budget.
                                        </div>
                                    </div>
                                </div>
                                <div className={"col s12 "+color_send_products} style={{padding:'5px',display:'flex',flexDirection:'row'}}>
                                    <div className="left" style={{marginTop:'7px',marginRight:'10px'}}>
                                        <span class="material-symbols-outlined">{icon_send_products}</span>
                                    </div>
                                    <div className="left" style={{padding:'5px',flex:'1'}}>
                                        <div className="left headline-font" style={{width:'100%',fontSize:'14px',margin:'0px'}}>
                                            STEP 2: SEND PRODUCTS OR MATERIALS
                                        </div>
                                        <div className="left" style={{width:'100%',margin:'0px'}}>
                                            All necessary materials needed to begin work on the project are sent.
                                        </div>
                                    </div>
                                </div>
                                <div className={"col s12 "+color_submit_deliverable} style={{padding:'5px',display:'flex',flexDirection:'row'}}>
                                    <div className="left" style={{marginTop:'7px',marginRight:'10px'}}>
                                        <span class="material-symbols-outlined">{icon_submit_deliverable}</span>
                                    </div>
                                    <div className="left" style={{padding:'5px',flex:'1'}}>
                                        <div className="left headline-font" style={{width:'100%',fontSize:'16px',margin:'0px'}}>
                                            STEP 3: SUBMIT DELIVERABLES
                                        </div>
                                        <div className="left" style={{width:'100%',margin:'0px'}}>
                                            Project work begins and final deliverables are submitted once complete.
                                        </div>
                                    </div>
                                </div>
                                <div className={"col s12 "+color_pay_invoice} style={{padding:'5px',display:'flex',flexDirection:'row'}}>
                                    <div className="left" style={{marginTop:'7px',marginRight:'10px'}}>
                                        <span class="material-symbols-outlined">{icon_pay_invoice}</span>
                                    </div>
                                    <div className="left" style={{padding:'5px',flex:'1'}}>
                                        <div className="left headline-font" style={{width:'100%',fontSize:'16px',margin:'0px'}}>
                                            STEP 4: REVIEW & PAY INVOICE
                                        </div>
                                        <div className="left" style={{width:'100%',margin:'0px'}}>
                                            Project deliverables are reviewed and the project invoice is paid.
                                        </div>
                                    </div>
                                </div>
                                <div className={"col s12 "+color_project_complete} style={{padding:'5px',display:'flex',flexDirection:'row'}}>
                                    <div className="left" style={{marginTop:'7px',marginRight:'10px'}}>
                                        <span class="material-symbols-outlined">{icon_project_complete}</span>
                                    </div>
                                    <div className="left" style={{padding:'5px',flex:'1'}}>
                                        <div className="left headline-font" style={{width:'100%',fontSize:'16px',margin:'0px'}}>
                                            STEP 5: PROJECT COMPLETE
                                        </div>
                                        <div className="left" style={{width:'100%',margin:'0px'}}>
                                            Project work has finished and invoices are paid.
                                        </div>
                                    </div>
                                </div></>;
        setProgressHTML(tmp_progress_html);
    }

    function continueNextStep() {
        if(currentstep == 'confirm_proposal') {
            currentstep = 'send_products';
        } else if(currentstep == 'send_products') {
            currentstep = 'submit_deliverable';
        } else if(currentstep == 'submit_deliverable') {
            currentstep = 'pay_invoice';
        } else if(currentstep == 'pay_invoice') {
            currentstep = 'project_summary';
        } else if(currentstep == 'project_summary') {
            
        }
        loadCurrentStep(currentstep);
        
    }

    function loadEditProject() {
        $("#project-edit-section").show();
        $("#project-view-section").hide();
    }

    function loadViewProject() {
        $("#project-edit-section").hide();
        $("#project-view-section").show();
    }

    function goBackwards() {
        if(props.goBackLink !== undefined) {
            props.goBackLink();
        } else {
            window.history.back();
        }
    }

    if(pageSet == false) {
        setPage(true);
        setTimeout(function(){
            grabProjectInfo();
        },50);
    }

    return (
    <div className="row" style={{padding:primary_page_padding,margin:'0px',minHeight:'100vh'}}>
        <div className={primary_page_width}>
            <div id="project-public-header" className="row subheadline-font" style={{position:'relative',padding:'0px',margin:'0px',fontSize:'16px'}}>
                <div className="col s12 black-text" style={{padding:'10px',marginTop:'0px'}}>
                    <div className="row rounded-section white z-depth-1" style={{position:'relative',margin:'0px',minHeight:'150px',overflow:'hidden', 
                    background:'url(https://blog.inzpire.me/wp-content/uploads/2019/10/imgonline-com-ua-resize-77c4a4yRndpDVrf9-1160x607.jpg)', 
                        backgroundPosition:'center',backgroundSize:'cover'}}>
                        <div className="row" style={{margin:'0px',height:'225px'}}>
                            
                        </div>
                        <div className="row white" style={{margin:'0px',padding:'20px 30px 30px'}}>
                            {headerHTML}
                        </div>
                        
                        <div className="rounded-section" style={{position:'absolute',bottom:'10px',right:'30px'}}>
                            <a href="/" className="white-text"><img src="./assets/global/shake_logo_blue.png" height="30px"/></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row subheadline-font" style={{position:'relative',fontSize:'16px',padding:'0px',margin:'0px',marginTop:'0px'}}>
                <div className="col s12 l8 black-text" id="collab-request-section" style={{padding:'10px'}}>
                    <div id="project-view-section">
                        <div className="row rounded-section white z-depth-1" style={{margin:'0px',padding:"0px",marginBottom:'20px',position:'relative'}}>
                            <div className="col s12" style={{margin:'0px',padding:"20px 30px 20px 20px"}}>
                                <div className="row" style={{fontSize:'16px',margin:'0px',marginTop:'10px',padding:'0px 0px'}}>
                                    <div className="col s12 m3">
                                        <img src="https://images.brandbacker.com/opps/overview/llt0cBAnS861yQqSI60L_hi_res-42-The%20Wilkes%20Group_r3_02B_2942.jpg" 
                                        width="100%" style={{borderRadius:'5px'}} />
                                    </div>
                                    <div className="col s12 m9">
                                        <div className="row" style={{margin:'0px',marginBottom:'10px'}}>
                                            <div className="left headline-font" style={{fontSize:'30px'}}>{projectTitle}</div>
                                        </div>
                                        {actionBoxSubheaderHTML}
                                    </div>
                                </div>
                            </div>
                            {currentStepHTML}
                            <div id="project-back-button" style={{display:'none',position:'absolute',top:'15px',right:'20px',fontSize:'14px',cursor:'pointer'}} title="Go Back To Project List" onClick={()=>goBackwards()}>
                                <i className="material-icons material-symbols-outlined black-text" 
                                    style={{fontSize:'14px',verticalAlign:'sub'}}>
                                        keyboard_return</i>&nbsp;Back
                            </div>
                        </div>
                        <div className="row rounded-section white z-depth-1" style={{margin:'0px',padding:"0px",marginBottom:'20px'}}>
                            <div className="col s12" style={{margin:'0px',padding:"20px 30px 10px 20px"}}>
                                <div className="row" style={{margin:'0px',marginTop:'5px'}}>
                                    <div className="col s12 m5">
                                        <div className="row" style={{fontSize:'24px',margin:'0px',padding:'0px 5px'}}>
                                            <span className="left headline-font">DELIVERABLE</span>
                                        </div>
                                        <div className="section-divider"></div>
                                        <div className="row" style={{marginTop:'10px',padding:'0px 10px'}}>
                                            {deliverableListHTML}
                                        </div>
                                    </div>
                                    <div className="col s12 m4">
                                        <div className="row" style={{fontSize:'24px',margin:'0px',padding:'0px 5px'}}>
                                            <span className="left headline-font">TIMELINE</span>
                                        </div>
                                        <div className="section-divider"></div>
                                        <div className="row" style={{marginTop:'0px',padding:'0px 10px'}}>
                                            <div className="col s12" style={{fontSize:'24px'}}>
                                                2-3 Weeks
                                            </div>
                                            <div className="col s12" style={{fontSize:'14px',marginTop:'5px'}}>
                                                Timeslines post receiving collaboration materials and products from client.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col s12 m3">
                                        <div className="row" style={{fontSize:'24px',margin:'0px',padding:'0px 5px'}}>
                                            <span className="left headline-font">BUDGET</span>
                                        </div>
                                        <div className="section-divider"></div>
                                        <div className="row" style={{marginTop:'0px',padding:'0px 10px'}}>
                                            <div className="col s12" style={{fontSize:'24px'}}>
                                                {project_budget}
                                            </div>
                                            <div className="col s12" style={{fontSize:'14px',marginTop:'5px'}}>
                                                Paid at the completion of the collaboration.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row rounded-section white z-depth-1" style={{margin:'0px',padding:"0px",marginBottom:'20px'}}>
                            <div className="col s12" style={{margin:'0px',padding:"20px 30px 40px 20px"}}>
                                <div className="row" style={{fontSize:'24px',margin:'0px',padding:'5px 5px'}}>
                                    <div className="left headline-font">THE DETAILS</div>
                                </div>
                                <div className="section-divider"></div>
                                <div className="row" style={{margin:'0px',padding:'0px 5px'}}>
                                    It's never been more important to talk about the benefits of clean, sustainable beauty products. 
                                    I'll be showcasing Evian Facial Spray in my morning skincare routine. 
                                    <br/><br/>
                                    <b>Collaboration Requirments: </b>
                                    <br/>
                                    Creating an Instagram Post, Reel and TikTok Video talking about how you use Evian Facial Spray. 
                                    Tagging @evianspray with the hashtags: #EvianSprayClean #EvianSpray #CleanBeauty
                                    <br/><br/>
                                    <b>Project Requirements:</b>
                                    <br/>
                                    One (1) full-size Evian Facial Spray 5 oz. to use in my posts
                                    <br/><br/>
                                    <b>Licensing and Image Rights:</b>
                                    <br/>
                                    1 Year Term: Content created for this collaboration can be repurposed on Evian's own marketing properties including social channels, email marketing, and website for 1 year.
                                </div>
                            </div>
                        </div>
                        <div className="row rounded-section white z-depth-1" style={{margin:'0px',padding:"0px",marginBottom:'20px'}}>
                            <div className="col s12" style={{margin:'0px',padding:"20px 30px 10px 20px"}}>
                                <div className="row center headline-font" style={{margin:'0px',marginTop:'20px',fontSize:'30px'}}>
                                    NEXT STEP
                                </div>
                                <div className="row center" style={{margin:'0px',marginTop:'10px',fontSize:'16px'}}>
                                    {currentPrimaryActionSubtext}
                                </div>
                                <div className="row" style={{margin:'0px',marginTop:'25px',marginBottom:'20px'}}>
                                    <div id="continue-project-button" className="row center-align" style={{marginBottom:'10px'}}>
                                        <div className="col s10 offset-s1 m6 offset-m3 waves-effect waves-light white-text z-depth-2" 
                                            style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 10px',cursor:'pointer',border:'3px solid #4360CD', background:'#4360CD'}} 
                                            onClick={()=>continueNextStep()}>
                                            {currentPrimaryActionText}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="project-edit-section" className="col s12" style={{display:'none',margin:'0px',padding:"0px"}}>
                        {project_settings}
                    </div>
                </div>
                <div id="collab-right-summary" className="col s12 l4 black-text" style={{padding:'10px'}}>
                    <div id="collab-message-summary" className="row rounded-section white z-depth-1"  style={{position:'relative',margin:'0px',padding:"20px 30px",minHeight:'10vh'}}>
                        <div className="row headline-font" style={{fontSize:'24px',margin:'0px'}}>
                            MESSAGES
                        </div>
                        <div className="section-divider"></div>
                        <div id="messages-section" className="row" style={{margin:'0px',padding:'0px',marginTop:'10px',border:'1px solid #EBEBEB',borderRadius:'10px',overflow:'hidden'}}>
                            <div className="row" style={{margin:'10px',fontSize:'14px'}}>
                                {">>"} Shake PM: Chat about proposals, project details and other key communication needed for this project.
                            </div>
                            <div id="messages-text-section" className="row" style={{margin:'0px',minHeight:'100px',overflowY:'scroll'}}>
                                {messagesHTML}
                            </div>
                            <div className="section-divider" style={{margin:'0px'}}></div>
                            <div id="messages-new-section" className="row" style={{lineHeight:'1',padding:'0px',margin:'0px',marginTop:'0px',position:'relative',height:'46px'}}>
                                <textarea id="new-message-text" style={{width:'100%',margin:'0px',padding:'3% 7% 1.5% 2%',minHeight:'40px',border:'0px'}}></textarea>
                                <div className="material-icons" style={{cursor:'pointer',fontSize:'28px',borderLeft:'1px solid #EBEBEB',paddingLeft:'10px',position:'absolute',top:'20%',right:'2%',color:'#4360CD'}}>send</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col s12 m4 black-text" style={{padding:'10px'}}>
                    <div id="collab-progress-summary" className="row rounded-section white z-depth-1"  style={{position:'relative',margin:'0px',padding:"20px 30px",minHeight:'10vh'}}>
                        <div className="row headline-font" style={{fontSize:'24px',margin:'0px'}}>
                            PROGRESS
                        </div>
                        <div className="section-divider"></div>
                        <div id="progress-section" className="row" style={{margin:'0px',padding:'0px',marginTop:'10px',borderRadius:'10px',fontSize:'14px'}}>
                            {progressHTML}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    );
};