import { React, useEffect, useState} from "react";
import './stylesheets/global.css'
import { useSelector, useDispatch } from "react-redux";
import { HashRouter, Routes, Route } from "react-router-dom";

import {
  Splash,
  SplashStealth,
  RateCardPublic,
  RateCardCreator,
  ProposalManager,
  ProjectManager,
  ProjectManagerPublic,
  PaymentsManager,
  AccountSettings,
  AccountSignup,
  InvoicePublic
} from "./pages/"
import $ from "jquery";

let pagehash = '';

function App() {

  const [currentLocation, setCurrentLocation] = useState('');

  useEffect(() => {  
    window.addEventListener("hashchange",
    function(){ 
      if(pagehash !== window.location.hash) {
        
      }
    }, false);
    window.onpopstate = e => {
      if(pagehash !== window.location.hash) {
        
      }
    }
  }, []);

  /* Check For Changes in Signup Loading */
  useEffect(() => {
    if (window !== undefined) {
      
    }
  });

  return (
    <div className="App">
      <HashRouter basename="/">
        <Routes>
          <Route path="invoice/:invoiceID" 
            element={<InvoicePublic />} 
            action={({ params }) => {}}
          />
          <Route path="payments" 
            element={<PaymentsManager />} 
          />
          <Route path="project" 
            element={<ProjectManagerPublic />} 
          />
          <Route path="manager" 
            element={<ProjectManager />} 
          />
          <Route path="ratecard_creator" 
            element={<RateCardCreator />} 
          />
          <Route path="ratecard" 
            element={<RateCardPublic />} 
          />
          <Route path="account" 
            element={<AccountSettings />} 
          />
          <Route path="signup" 
            element={<AccountSignup />} 
          />
          <Route path="splash2" 
            element={<SplashStealth />} 
          /> 
          <Route path="/" 
            element={<Splash />} 
          />
          <Route path="*" 
            element={<Splash />} 
          />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
