import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import {axiosInstance, closeAllModals, validateEmailText } from "../../../../utils";

export const OrgDetails = (props) => {    
    
    const { memberID, orgID } = useSelector((state) => state.login);

    const [pageSet, setPage] = useState(false);
    const [headerHTML, setHeaderHTML] = useState(null);
    const [linksHTML, setLinksHTML] = useState(null);
    const [optionHTML, setOptionHTML] = useState(null);
    const [clientSelectHTML, setClientSelectHTML] = useState([]);

    let startingRate = 0;

    useEffect(() => {
        document.body.style.backgroundColor="#F7F7F7";
    }, []);


    function getOrgDetails() {
        var addData = new Object();
        addData.id = memberID;
        addData.orgID = orgID;

        axiosInstance.post('member/member_orgs/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var org_details = result.data; 
                $("#orgName").val(org_details[0]['org_name']);
                $("#orgAddress").val(org_details[0]['org_address']);
                $("#orgEmail").val(org_details[0]['org_email']);
                $("#orgCountry").val(org_details[0]['org_country']);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function saveOrgDetails() {
        var org_id = $("#orgID").val();
        var org_name = $("#orgName").val();
        var org_email = $("#orgEmail").val();
        var org_country = $("#orgCountry").val();
        var org_address = $("#orgAddress").val();

        org_id = orgID;

        var addData = new Object();
        addData.id = memberID;
        addData.orgID = org_id;
        addData.orgName = org_name;
        addData.orgEmail = org_email;
        addData.orgCountry = org_country;
        addData.orgAddress = org_address;

        if((org_name.length == 0)) {
            alert('Add Valid Entity Name');
        } else if(validateEmailText(org_email) == 'fail') {
            alert('Add Valid Email Address');
        } else {
            $("#save-new-button").hide();
            $("#save-new-loading").show();
            axiosInstance.post('member/org_update/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    setTimeout(function() {
                        $("#save-new-button").show();
                        $("#save-new-loading").hide();
                        getOrgDetails();
                    },2000);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }


    if(pageSet == false) {
        setPage(true);
        getOrgDetails();
    }

    return (
    <div className="row subheadline-font" style={{position:'relative',fontSize:'16px',padding:'0px',margin:'0px',marginTop:'0px'}}>
        <div className="col s12 black-text" style={{padding:'10px'}}>
            <div id="collab-request-section" className="row rounded-section white z-depth-1" style={{margin:'0px',padding:"0px"}}>
                <div id="project-settings-form" className="col s12" style={{margin:'0px',padding:"20px 30px"}}>
                    <div className="row" style={{fontSize:'30px',margin:'0px'}}>
                        <div className="left headline-font">Step 1: Setup Your Entity</div>
                    </div>
                    <div className="section-divider"></div>
                    <div className="row" style={{margin:'0px'}}>
                        &nbsp;
                    </div>
                    <div className="row" style={{marginTop:'10px',marginBottom:'50px',display:'flex',flexDirection:'row'}}>
                        <div className="col s12" style={{flex:'1',padding:'0px 20px'}}>
                            <div className="row">
                                <div className="col s12" style={{padding:'10px 20px'}}>
                                    <div className="row" style={{marginBottom:'10px'}}>
                                        <div className="col s12 l6">
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                Entity Name (e.g. Name of Content Channel, Company, Organization)
                                            </div>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                <input type="text" id="orgName"
                                                className="left browser-default rounded-input z-depth-1"
                                                style={{width:"98%",marginRight:'2%'}} />
                                            </div>
                                        </div>
                                        <div className="col s12 l6" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                Entity Email Address
                                            </div>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                <input type="text" id="orgEmail"
                                                className="left browser-default rounded-input z-depth-1"
                                                style={{width:"98%",marginRight:'2%'}} />
                                            </div>
                                        </div>
                                        <div className="col s12 l6" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                Entity Country
                                            </div>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                <input type="text" id="orgCountry"
                                                className="left browser-default rounded-input z-depth-1"
                                                style={{width:"98%",marginRight:'2%'}} />
                                            </div>
                                        </div>
                                        <div className="col s12 l6">
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                Address
                                            </div>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                <input type="text" id="orgAddress"
                                                className="left browser-default rounded-input z-depth-1"
                                                style={{width:"98%",marginRight:'2%'}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div id="save-new-divider" className="section-divider"></div>
                </div>
                <div className="row center-align" style={{fontSize:'30px',marginBottom:'0px',padding:'20px 30px'}}>
                    <div id="save-new-button" className="waves-effect waves-light white-text z-depth-2" 
                            style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD',marginRight:'20px'}} 
                            onClick={()=>saveOrgDetails()} >
                            Save Entity
                    </div>
                    <div id="save-new-loading" style={{display:'none'}}>
                        <div class="preloader-wrapper small active">
                            <div class="spinner-layer spinner-blue-only">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div><div class="gap-patch">
                                <div class="circle"></div>
                            </div><div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};