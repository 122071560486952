import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import {axiosInstance, closeAllModals} from "../../utils";
import rate_card_info from "./rate_card_sample.json";

let card_info = []
export const RateCardPublic = () => {    
    const { memberID, orgID } = useSelector((state) => state.login);

    const [pageSet, setPage] = useState(false);
    const [headerHTML, setHeaderHTML] = useState(null);
    const [linksHTML, setLinksHTML] = useState(null);
    const [optionHTML, setOptionHTML] = useState(null);
    const [selectionHTML, setSelectionHTML] = useState([]);
    const [rateCardID, setRateCardID] = useState(1);    
    const [currentRate, setCurrentRate] = useState(0);
    
    let startingRate = 0;

    useEffect(() => {
        document.body.style.backgroundColor="#F7F7F7";
        setTimeout(function(){
            resizeRequestWindow();
        },500);
        if(card_info.length > 0) {
            loadRateCardInfo(card_info[0]);
        }
      }, []);

    function grabRateCard() {
        var addData = new Object();
        addData.rcid = encodeURIComponent(rateCardID);

        axiosInstance.post('ratecard/card_info/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var card_details = result.data; 
                card_info = card_details;
                loadRateCardInfo(card_info[0]);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function loadRateCardInfo(card_details) {
        startingRate = parseInt(rate_card_info['starting_rate']);
        setCurrentRate(parseInt(rate_card_info['starting_rate']));
        var main_background = rate_card_info['card_background'];
        if(main_background.length > 0) {
            document.body.style.background = main_background;
        }
        var main_title = card_details['rate_card_headline'];
        var main_subtitle = card_details['rate_card_bio'];
        var main_location = card_details['rate_card_location'];
        var tmp_header = <>
                            <div className="row headline-font" style={{margin:'0px',fontSize:'36px'}}>
                                {main_title}
                            </div>
                            <div className="row" style={{margin:'0px'}}>
                                {main_subtitle+" - "+main_location}
                            </div>
                            <div className="rounded-section" style={{border:'0px solid #EBEBEB',padding:'5px 7px',position:'absolute',top:'20px',right:'25px'}}>
                                {linksHTML}
                            </div>
                        </>;
        setHeaderHTML(tmp_header);
        loadRateCardOptions(card_details['rate_card_options']);
        loadRateCardLinks(card_details['rate_card_links'])
    }

    function loadRateCardLinks(links_list) {
        
        var final_links = new Array();
        for(var i = 0;i < links_list.length;i++) {
            var link_id = links_list[i]['rate_card_link_id'];
            var link_network = links_list[i]['link_type'];
            var link_title = links_list[i]['link_title'];
            var link_url = links_list[i]['link_url'];
            var tmp_link = <div key={i} className="col s12 m6" style={{padding:'10px'}}>
                            <div className="row rounded-section white z-depth-1" style={{margin:'0px',padding:"10px 15px",borderRadius:'5px'}}>
                                <span className="left">{link_title}</span>
                            </div>
                        </div>
            final_links.push(tmp_link);
        }

        setLinksHTML(final_links);
    }

    function loadRateCardOptions(options_list) {
        
        var final_options = new Array();
        for(var i = 0;i < options_list.length;i++) {
            var option_id = options_list[i]['rate_card_option_id'];
            var option_network = options_list[i]['option_type'];
            var option_title = options_list[i]['option_title'];
            var option_rate = options_list[i]['option_rate'];
            var tmp_option = <div key={i} className="col s12 m6" style={{padding:'10px'}}>
                            <div className="row rounded-section white z-depth-1" style={{margin:'0px',padding:"10px 15px",borderRadius:'5px'}}>
                                <span id={"ig-static-post-"+option_id} data-optionid={option_id} data-optiontitle={option_title} data-optionrate={option_rate} 
                                    className="left material-icons rate-option" style={{marginRight:'10px',marginTop:'5px',fontSize:'16px',cursor:'pointer'}} 
                                    onClick={(e)=>toggleOptionSelect(e)}>add</span>
                                <span className="left" style={{marginRight:'15px',borderRight:'1px solid #EBEBEB',width:'1px',height:'25px'}}></span>
                                <span className="left">{option_title}</span>
                            </div>
                        </div>
            final_options.push(tmp_option);
        }

        setOptionHTML(final_options);
        updateRequestSelectedRail();
    }

    function toggleOptionSelect(e) {
        e.preventDefault();
        var option_id = e.target.dataset.optionid;
        var option_title = e.target.dataset.optiontitle;
        if($("#ig-static-post-"+option_id).html() == 'add') {
            $("#ig-static-post-"+option_id).html('check');
            $("#ig-static-post-"+option_id).addClass('green-text');
        } else {
            $("#ig-static-post-"+option_id).html('add');
            $("#ig-static-post-"+option_id).removeClass('green-text');
        }
        updateRequestSelectedRail();
    }

    function updateRequestSelectedRail() {
        var final_selections = new Array();
        var selected_rate = startingRate;
        var optionnum = 0;
        $(".rate-option").each(function(){
            if($(this).html() != 'add') {
                var tmp_selection = <><div key={optionnum} className="row" style={{margin:'0px',padding:'5px 10px',paddingTop:'0px',fontSize:'16px'}}>
                                    <span className="left">1 x {$(this).data('optiontitle')}</span>
                                    <span className="right material-icons material-symbols-outlined" data-optionid={$(this).data('optionid')} title="Remove Option"
                                        onClick={(e)=>toggleOptionSelect(e)} style={{cursor:'pointer',marginTop:'5px',fontSize:'16px'}}>delete</span>
                                </div>
                                <div className="section-divider"></div></>;
                final_selections.push(tmp_selection);
                selected_rate = selected_rate+parseInt($(this).data('optionrate'));
                optionnum = optionnum+1;
            }
        });
        if(final_selections.length == 0) {
            var tmp_selection = <><div key={0} className="row" style={{margin:'0px',padding:'5px 10px',paddingTop:'0px',fontSize:'16px'}}>
                                    Select An Option
                                </div>
                                <div className="section-divider"></div></>;
            final_selections.push(tmp_selection);
        }
        setCurrentRate(selected_rate);
        setSelectionHTML(final_selections);
        setTimeout(function(){
            resizeRequestWindow();
        },100);
    }

    function getSelectedOptions() {
        var final_selections = new Array();
        $(".rate-option").each(function(){
            if($(this).html() != 'add') {
                var tmp_selection = $(this).data('optionid');
                final_selections.push(tmp_selection);
            }
        });
        return final_selections;
    }

    function continueCollabForm() {
        var selectedOptions = getSelectedOptions();

        if(selectedOptions.length == 0) {
            alert('Choose a Collaboration Option');
        } else {
            $("#base-collab-form").hide();
            $("#finish-collab-form").fadeIn();
            $("#base-form-button").hide();
            $("#finish-form-button").fadeIn();
            resizeRequestWindow();
        }
    }

    function returnCollabForm() {
        $("#base-collab-form").fadeIn();
        $("#finish-collab-form").hide();
        $("#base-form-button").fadeIn();
        $("#finish-form-button").hide();
        resizeRequestWindow();
    }

    function resizeRequestWindow() {
        var newheight = $("#collab-request-section").height();
        var otherheight = $("#collab-request-summary").outerHeight();
        if(newheight > otherheight) {
            $("#collab-request-summary").css({'min-height':newheight+'px'});
        } else {
            $("#collab-request-section").css({'min-height':otherheight+'px'});
        }
    }

    function sendCollabRequest() {
        let request_name = $("#request-name").val();
        let request_email = $("#request-email").val();
        let request_brand = $("#request-brand").val();
        let request_website = $("#request-website").val();
        let request_notes = $("#request-notes").val();
        let request_quote = currentRate;
        var request_options = getSelectedOptions();

        var addData = new Object();
        addData.reqID = rateCardID;
        addData.reqName = encodeURIComponent(request_name);
        addData.reqEmail = encodeURIComponent(request_email);
        addData.reqOptions = request_options;
        addData.reqCompany = encodeURIComponent(request_brand);
        addData.reqWebsite = encodeURIComponent(request_website);
        addData.reqQuote = encodeURIComponent(request_quote);
        addData.reqMsg = encodeURIComponent(request_notes);
        
        
        if(request_name.length <= 2) {
            alert('Please Add Your Full Name and Fill Out All Required Fields.');
        } else if(request_email.length <= 4) {
            alert('Please Add Your Email Address and Fill Out All Required Fields.');
        } else if(request_website.length <= 4) {
            alert('Please Add Your Brand or Companies Website and Fill Out All Required Fields.');
        } else if(request_notes.length <= 4) {
            alert('Please Add Your Collaboration Notes and Fill Out All Required Fields.');
        } else {
            axiosInstance.post('ratecard/work_request/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    $("#finish-collab-form").hide();
                    $("#completed-collab-form").fadeIn();
                    $("#finish-collab-form").hide();
                    $("#completed-collab-form").fadeIn();
    
                    $("#finish-form-button").hide();
                    $("#sent-form-button").show();
                    resizeRequestWindow();
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    if(pageSet == false) {
        setPage(true);
        setTimeout(function(){
            grabRateCard();
        },50);
    }

    return (
    <div className="row" style={{padding:'0px 20px',margin:'0px',minHeight:'100vh'}}>
        <div className="col s12 m10 offset-m1">
            <div className="row subheadline-font" style={{position:'relative',padding:'0px',margin:'0px',fontSize:'16px'}}>
                <div className="col s12 black-text" style={{padding:'10px',marginTop:'0px'}}>
                    <div className="row rounded-section white z-depth-1" style={{position:'relative',margin:'0px',padding:"15px 30px",minHeight:'150px'}}>
                        {headerHTML}
                        <div className="rounded-section" style={{position:'absolute',bottom:'0px',right:'30px'}}>
                            <a href="/" className="white-text"><img src="./assets/global/shake_logo_blue.png" height="30px"/></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row subheadline-font" style={{position:'relative',fontSize:'16px',padding:'0px',margin:'0px',marginTop:'0px'}}>
                <div className="col s12 l8 black-text" style={{padding:'10px'}}>
                    <div id="collab-request-section" className="row rounded-section white z-depth-1" style={{margin:'0px',padding:"0px",minHeight:'65vh'}}>
                        <div id="base-collab-form" className="col s12" style={{margin:'0px',padding:"20px 30px"}}>
                            <div className="row" style={{fontSize:'30px',margin:'0px'}}>
                                <span className="left headline-font">Let's Collaborate</span>
                                <span className="right" style={{fontSize:'16px',marginTop:'20px'}}>View Examples</span>
                            </div>
                            <div className="section-divider"></div>
                            <div className="row" style={{margin:'0px'}}>
                                Use the form below to select which collaboration options you're looking to work on. This request form will 
                                be the starting point from which the collaboration is based on.
                            </div>
                            <div className="row" style={{marginTop:'20px'}}>
                                {optionHTML}
                            </div>
                        </div>
                        <div id="finish-collab-form" className="col s12" style={{margin:'0px',padding:"20px 30px",display:'none'}}>
                            <div className="row" style={{fontSize:'30px',margin:'0px'}}>
                                <span className="left headline-font">Finish Your Request</span>
                                <span className="right" 
                                    style={{fontSize:'16px',marginTop:'20px',cursor:'pointer'}}
                                    onClick={()=>returnCollabForm()}>
                                        Back to Selection</span>
                            </div>
                            <div className="section-divider"></div>
                            <div className="row" style={{margin:'0px'}}>
                                Explain your collaboration request in more details including your brand or client's brand and any information about 
                                the collaboration requirements, timelines and budget.
                            </div>
                            <div className="row" style={{marginTop:'10px'}}>
                                <div className="col s12 m6" style={{padding:'10px'}}>
                                    <div className="row" style={{margin:'0px',padding:"5px"}}>
                                        Your Name*
                                    </div>
                                    <div className="row" style={{margin:'0px'}}>
                                        <input type="text" id="request-name" className="browser-default rounded-input z-depth-1"/>
                                    </div>
                                </div>
                                <div className="col s12 m6" style={{padding:'10px'}}>
                                    <div className="row" style={{margin:'0px',padding:"5px"}}>
                                        Your Email*
                                    </div>
                                    <div className="row" style={{margin:'0px'}}>
                                        <input type="email" id="request-email" className="browser-default rounded-input z-depth-1"/>
                                    </div>
                                </div>
                                <div className="col s12 m6" style={{padding:'10px'}}>
                                    <div className="row" style={{margin:'0px',padding:"5px"}}>
                                        Brand/Client Name
                                    </div>
                                    <div className="row" style={{margin:'0px'}}>
                                        <input type="text" id="request-brand" className="browser-default rounded-input z-depth-1"/>
                                    </div>
                                </div>
                                <div className="col s12 m6" style={{padding:'10px'}}>
                                    <div className="row" style={{margin:'0px',padding:"5px"}}>
                                        Brand/Client Website*
                                    </div>
                                    <div className="row" style={{margin:'0px'}}>
                                        <input type="text" id="request-website" className="browser-default rounded-input z-depth-1"/>
                                    </div>
                                </div>
                                <div className="col s12" style={{padding:'10px'}}>
                                    <div className="row" style={{margin:'0px',padding:"5px"}}>
                                        Additional Notes, Dates and Project Scope*
                                    </div>
                                    <div className="row" style={{margin:'0px'}}>
                                        <textarea id="request-notes" className="browser-default rounded-input z-depth-1" style={{minHeight:'150px'}}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="completed-collab-form" className="col s12" style={{margin:'0px',padding:"20px 30px",display:'none'}}>
                            <div className="row" style={{fontSize:'30px',margin:'0px'}}>
                                <span className="left headline-font">Request Submitted</span>
                            </div>
                            <div className="section-divider"></div>
                            <div className="row" style={{margin:'0px',marginTop:'20px',fontSize:'16px'}}>
                                You're Collaboration Request had been submitted! We'll get back to you as soon as we can.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col s12 l4 black-text" style={{padding:'10px'}}>
                    <div id="collab-request-summary" className="row rounded-section white z-depth-1"  style={{position:'relative',margin:'0px',padding:"20px 30px",minHeight:'65vh'}}>
                        <div className="row headline-font" style={{fontSize:'30px',margin:'0px'}}>
                            Your Request
                        </div>
                        <div className="section-divider"></div>
                        <div id="option-selection-section" className="row" style={{margin:'0px',marginBottom:'125px'}}>
                            {selectionHTML}
                        </div>
                        <div className="center-align" style={{width:'100%',margin:'0px',position:'absolute',bottom:'20px',left:'0px'}}>
                            <div className="row center-align" style={{marginBottom:'10px',fontSize:'20px'}}>
                                <span style={{fontSize:'16px'}}>Starting at:&nbsp;&nbsp;</span><span style={{fontWeight:'900'}} >${currentRate.toLocaleString()}</span>
                            </div>
                            <div id="base-form-button" className="row center-align" style={{marginBottom:'10px'}}>
                                <div className="col s10 offset-s1 m6 offset-m3 waves-effect waves-light white black-text z-depth-2" 
                                    style={{borderRadius:'10px',fontSize:'18px',padding:'5px 10px',cursor:'pointer',border:'3px solid #29b6f6'}}
                                    onClick={()=>continueCollabForm()}>
                                    Continue
                                </div>
                            </div>
                            <div id="finish-form-button" className="row center-align" style={{marginBottom:'10px',display:'none'}}>
                                <div className="col s10 offset-s1 m6 offset-m3 waves-effect waves-light white-text z-depth-2" 
                                    style={{borderRadius:'10px',fontSize:'18px',padding:'5px 10px',cursor:'pointer',border:'3px solid #29b6f6', background:'#29b6f6'}} 
                                    onClick={()=>sendCollabRequest()} >
                                    Send Request
                                </div>
                            </div>
                            <div id="sent-form-button" className="row center-align" style={{marginBottom:'10px',display:'none'}}>
                                <div className="col s10 offset-s1 m6 offset-m3 waves-effect waves-light white-text z-depth-2" 
                                    style={{borderRadius:'10px',fontSize:'18px',padding:'5px 10px',cursor:'pointer',border:'3px solid green', background:'green'}} >
                                    Request Sent
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};