import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import {axiosInstance, closeAllModals, dateSlashToDash} from "../../utils";
import '../../stylesheets/payments.css';

const InvoiceDeleteBox = (props) => {    
    const { memberID, orgID } = useSelector((state) => state.login);
    const [pageSet, setPage] = useState(false);

    useEffect(() => {
        document.body.style.backgroundColor="#F7F7F7";
        loadRemoveForms();
    }, []);

    function deleteInvoiceConfirmed() {
        var addData = new Object();
        addData.id = encodeURIComponent(memberID);
        addData.invoiceUniqID = encodeURIComponent(props.invoiceID);
        
        $("#remove-invoice-button").hide();
        $("#remove-invoice-loading").show();

        axiosInstance.post('payments/invoice_remove/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                setTimeout(function(){
                    $("#remove-invoice-button").show();
                    $("#remove-invoice-loading").hide();
                    $("#remove-invoice-form").hide();
                    $("#remove-invoice-confirmed").show();
                },2000);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function refreshPage() {
        props.pageRedirect();
        setTimeout(function(){
            window.location.reload();
        },100);
    }

    function loadRemoveForms() {
        var elems = document.getElementById('remove-invoice-modal');
        var instances = window.M.Modal.init(elems);
    }

    if(pageSet == false) {
        setPage(true);
    }

    return (<div id="remove-invoice-modal" className="modal page-modal-medium">
                <div className="row" style={{margin:'0px'}}>
                    <div id="remove-invoice-form" className="col s10 offset-s1 center-align">
                        <div className="row headline-font" style={{fontSize:'40px',marginTop:'30px'}}>
                            REMOVE INVOICE
                        </div>
                        <div className="row">
                            <div className="col s12 m10 offset-m1">
                                Are you sure you want to delete this invoice? Once removed, this invoice will not be accessible and cannot be used to receive or record payments.
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12" style={{padding:'10px'}}>
                                
                                <div className="row" style={{margin:'0px'}}>
                                    <div className="col s12 m8 offset-m2 l6 offset-l3 center-align" style={{padding:'0px 5px',marginBottom:'20px'}}>
                                        <div id="remove-invoice-button" className="waves-effect waves-light white-text z-depth-2" 
                                            style={{background:'#132C51',width:'100%',borderRadius:'10px',fontSize:'14px',padding:'10px 15px',cursor:'pointer',border:'1px solid #132C51'}}
                                            onClick={()=>deleteInvoiceConfirmed()}>
                                                REMOVE
                                        </div>
                                        <div id="remove-invoice-loading" className="center-align" style={{display:'none'}}>
                                            <div className="preloader-wrapper small active" style={{marginTop:'4px'}}>
                                                <div className="spinner-layer spinner-blue-only">
                                                <div className="circle-clipper left">
                                                    <div className="circle"></div>
                                                </div><div className="gap-patch">
                                                    <div className="circle"></div>
                                                </div><div className="circle-clipper right">
                                                    <div className="circle"></div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="remove-invoice-confirmed" className="col s10 offset-s1 center-align" style={{display:'none',marginBottom:'20px',minHeight:'40vh'}}>
                        <div className="row headline-font" style={{fontSize:'40px',marginTop:'30px'}}>
                            INVOICE REMOVED
                        </div>
                        <div className="row">
                            <div className="col s12 m10 offset-m1">
                            This invoice has now been removed. You can find all of your invoices in the Payments Manager.
                            </div>
                        </div>
                        <div className="row" style={{margin:'0px'}}>
                            <div className="col s12 m8 offset-m2 l6 offset-l3" style={{padding:'0px 5px',marginTop:'50px',marginBottom:'20px'}}>
                                <div className="waves-effect waves-light white-text z-depth-2" 
                                    style={{background:'#132C51',width:'100%',borderRadius:'10px',fontSize:'14px',padding:'10px 15px',cursor:'pointer',border:'1px solid #132C51'}}
                                    onClick={()=>refreshPage()}>
                                        CLOSE
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default InvoiceDeleteBox;