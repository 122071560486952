import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import {GlobalHeader, GlobalHeaderDarkMode, GlobalLeftRail} from '../../components';
import {axiosInstance, closeAllModals} from "../../utils";
import {ProjectManagerPublic} from "../ProjectManagerPublic";
import projects_json from "./projects_sample.json"; 

let current_pagename = '';
let current_page_status = 'P';
let current_table_page = 0;
let current_rows_html = [];
let active_project_data = [];
let current_project_view = 0;
let tableDateText= '';
let current_color_scheme = 'light';
let tableStatusText = '';
let pageTextColorClass = 'white-text';
let pageTableColorClass = 'table-row-dark';
let pageBorderColorClass = 'white';
let pageAccentColorClass = '#77E7A5';
let pageGlobalHeader = <GlobalHeaderDarkMode />;
export const ProjectManager = () => {    
    const { memberID, orgID } = useSelector((state) => state.login);

    const [pageSet, setPage] = useState(false);
    const [headlineText, setHeadlineText] = useState('');
    const [tableLabelsHTML, setTableLabelsHTML] = useState([]);
    const [tableRowsHTML, setTableRowsHTML] = useState([]);
    const [modalHTML, setModalHTML] = useState([]);
    const [currentProjectView,setCurrentProjectView] = useState('');
    const [rateCardID, setRateCardID] = useState(0);    
    const [currentRate, setCurrentRate] = useState(0);
    const [new_proposal_count, setCountProposals] = useState(0);
    const [qualified_count, setCountQualified] = useState(0);
    const [closing_count, setCountClosing] = useState(0);
    const [in_progress_count, setCountProgress] = useState(0);
    const [completed_count, setCountCompleted] = useState(0);
    const [declined_count, setCountDeclined] = useState(0);

    const user_member_id = 1;
    const user_org_id = 1;
    let startingRate = 0;
    
    if(current_color_scheme == 'light') {
        pageBorderColorClass = '#132C51';
        pageAccentColorClass = '#36dc7a';
        pageGlobalHeader = <GlobalHeader />;
    } else {
        pageBorderColorClass = 'white';
        pageAccentColorClass = '#77E7A5';
        pageGlobalHeader = <GlobalHeaderDarkMode />;
    }
    
    useEffect(() => {
        if(current_color_scheme == 'light') {
            document.body.style.background="#FCF5EC";
            document.body.style.backgroundColor="#FCF5EC";
            document.body.style.background="radial-gradient(#FFF7F7,#FCF5EC)";
            document.body.style.background="radial-gradient(#FFFFFF,#F0EDE9)";

            pageTextColorClass = 'navy-text';
            pageTableColorClass = 'table-row-light';
            $(".white-text").each(function(){
                $(this).removeClass("white-text").addClass("navy-text");
            });
            $(".table-row-dark").each(function(){
                $(this).removeClass("table-row-dark").addClass("table-row-light");
            });
        } else {
            document.body.style.background="radial-gradient(#4360CD, #132C51)";
            pageTextColorClass = 'white-text';
            pageTableColorClass = 'table-row-dark';
            $(".navy-text").each(function(){
                $(this).removeClass("navy-text").addClass("white-text");
            });
            $(".table-row-light").each(function(){
                $(this).removeClass("table-row-light").addClass("table-row-dark");
            });
        } 
      }, []);


    function grabProjectRows() {
        var addData = new Object();
        addData.id = encodeURIComponent(user_member_id);
        //addData.page = current_table_page;
        addData.page = 0;
        addData.status = current_page_status;

        if((current_pagename == 'new')||(current_pagename == 'declined')) {
            addData.type = 'request';
        } else {
            addData.type = 'project';
        }

        axiosInstance.post('projects/project_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var project_data = result.data;
                if (current_table_page > 0) {
                    active_project_data = active_project_data.concat(project_data);
                } else {
                    active_project_data = project_data;
                }
                loadProjectTableRows(project_data,current_table_page);
            } else {
                loadProjectTableRows([],current_table_page);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function loadProjectTableRows(itemarr=[],tablepage) {
        var startnum = 0;
        if(tablepage > 0) {
            startnum = tableRowsHTML.length;
        }
        var final_html = new Array();
        if((current_pagename == 'new')||(current_pagename == 'declined')) {
            final_html = getProposalsRows(itemarr,startnum);
        } else {
            final_html = getProjectsRows(itemarr,startnum);
        }
        
        if (tablepage == 0) {
            setTableRowsHTML(final_html);
        } else {
            var tmp_rows_html = tableRowsHTML.concat(final_html);
            setTableRowsHTML(tmp_rows_html);
        }
        setTimeout(function(){
            showSelectForms();
            
        },250);
    }

    function showMoreRows() {
        current_table_page = current_table_page+1;
        grabProjectRows();
    }

    function getProposalsRows(proposals_arr,startnum) {
        var final_arr = proposals_arr;
        var final_rows = new Array();
        for(var i = 0;i < final_arr.length;i++) {
            var work_request_id = final_arr[i]['work_request_id'];
            var request_status = final_arr[i]['request_status'];
            var full_name = final_arr[i]['first_name']+' '+final_arr[i]['last_name'];
            var company_name = final_arr[i]['company_name'];
            var company_website = final_arr[i]['company_website'];
            var request_quote_amount = final_arr[i]['request_quote_amount'];
            if(request_quote_amount == null) {
                request_quote_amount = 0;
            }
            var request_date = final_arr[i]['request_month']+'/'+final_arr[i]['request_day']+'/'+final_arr[i]['request_year'];
            var request_client = company_name;
            if((company_name.length == 0)||(company_name == 'null')||(company_name == '')||(company_name == undefined)) {
                request_client = full_name;
            }
            var deliverable_list = '';
            var dcnt = 0;
            for(var j = 0; j < final_arr[i]['request_deliverables'].length;j++) {
                if(final_arr[i]['request_deliverables'][j]) {
                    if(dcnt > 0) {
                        deliverable_list = deliverable_list+", ";
                    }
                    deliverable_list = deliverable_list+final_arr[i]['request_deliverables'][j]['option_title'];
                    dcnt++;
                }
            }
            deliverable_list = deliverable_list.replace(/Instagram/g,"IG");

            var pendingsel = <option value="P">Pending</option>;
            var qualifiedsel = <option value="Q">Qualified</option>;
            var declinedsel = <option value="D">Declined</option>;

            var closingsel = <></>;
            var progresssel = <></>;
            if((current_pagename == 'qualified')||(current_pagename == 'closing')) {
                var closingsel = <option value="C">Closing</option>;
                var progresssel = <option value="A">In Progress</option>;
            }
            if(request_status == 'Q') {
                qualifiedsel = <option value="Q" selected>Qualified</option>;
            } else if (request_status == 'D') {
                declinedsel = <option value="D" selected>Declined</option>;
            } else if (request_status == 'C') {
                closingsel = <option value="C" selected>Closing</option>;
            } else {
                pendingsel = <option value="P" selected>Pending</option>;
            }

            var selectcolorclass = 'in-row-select';
            if(current_color_scheme == 'light') {
                selectcolorclass = 'in-row-select-light';
            }
            var statusselect = <select id={"request_status_"+work_request_id} className={"browser-default right "+selectcolorclass}
                                    onChange={(e)=>changeStatusTableRow(e)}>
                                {pendingsel}
                                {qualifiedsel}
                                {declinedsel}
                                {closingsel}
                                {progresssel}
                            </select>

            var website_html = <a id={"ext_link_"+work_request_id} href={company_website} target="_blank" className="material-icons material-icons-outlined pageTextColorClass"
                                    title="Visit Client Website" 
                                    style={{fontSize:'20px',verticalAlign:'sub',marginLeft:'5px'}}>
                                public
                                </a>;

            var tmp_row = <div key={i+startnum} id={"project_"+work_request_id} className={"col s12 rounded-section z-depth-1 "+pageTableColorClass}>
                                <div className="row" style={{margin:"0px",padding:"20px 0px 15px 0px",fontSize:'14px',marginBottom:'0px'}}>
                                    <div className="col s12 m3 l3" onClick={(e)=>openProject(e)}>
                                        {i+1+startnum}. {request_client} {website_html}
                                    </div>
                                    <div className="col s12 m2 l2 center-align" onClick={(e)=>openProject(e)}>
                                        {request_date}
                                    </div>
                                    <div className="col s12 m2 l1" onClick={(e)=>openProject(e)}>
                                        ${request_quote_amount.toLocaleString()}
                                    </div>
                                    <div className="col s12 m3 l5" onClick={(e)=>openProject(e)}>
                                        {deliverable_list}
                                    </div>
                                    <div className="col s12 m2 l1 right-align" style={{lineHeight:'1'}}>
                                        {statusselect}
                                    </div>
                                </div>
                            </div>
            final_rows.push(tmp_row);
        }

        if((final_rows.length == 0)&&(startnum == 0)) {
            var borderc = "1px solid "+pageBorderColorClass;
            var tmp_row = <div key={"no_results_available"} id={"no_results_available"}  className={"col s12 rounded-section"}>
                                <div className="row navy-text" style={{margin:"0px",marginTop:'10px',padding:"20px 10px",fontSize:'16px',borderTop:borderc, marginBottom:'0px'}}>
                                    No Proposals Available
                                </div>
                            </div>;
            final_rows.push(tmp_row);
        }

        return final_rows;
    }

    function getProjectsRows(proposals_arr,startnum) {
        var final_arr = proposals_arr;
        var final_rows = new Array();
        for(var i = 0;i < final_arr.length;i++) {
            var project_id = final_arr[i]['project_id'];
            var project_status = final_arr[i]['project_status'];
            var full_name = final_arr[i]['client_name'];
            var company_name = final_arr[i]['project_name'];
            var company_website = final_arr[i]['company_website'];
            var request_quote_amount = final_arr[i]['project_amount'];
            if(request_quote_amount == null) {
                request_quote_amount = 0;
            }
            var request_date = final_arr[i]['start_month']+'/'+final_arr[i]['start_day']+'/'+final_arr[i]['start_year'];
            var request_client = company_name;
            if((company_name == 'null')||(company_name == '')||(company_name == undefined)) {
                request_client = full_name;
            }
            var deliverable_list = '';
            var dcnt = 0;
            for(var j = 0; j < final_arr[i]['project_deliverables'].length;j++) {
                if(final_arr[i]['project_deliverables'][j]) {
                    if(dcnt > 0) {
                        deliverable_list = deliverable_list+", ";
                    }
                    deliverable_list = deliverable_list+final_arr[i]['project_deliverables'][j]['deliverable_title'];
                    dcnt++;
                }
            }
            deliverable_list = deliverable_list.replace(/Instagram/g,"IG");
            
            var pendingsel = <option value="P">Proposal</option>;
            var activesel = <option value="A">Active Project</option>;
            var completedsel = <option value="C">Completed</option>;
            if(project_status == 'C') {
                completedsel = <option value="C" selected>Completed</option>;
            } else if(project_status == 'A') {
                activesel = <option value="A" selected>Active Project</option>;
            } else {
                pendingsel = <option value="P" selected>Proposal</option>;
            }
            var selectcolorclass = 'in-row-select';
            if(current_color_scheme == 'light') {
                selectcolorclass = 'in-row-select-light';
            }
            var statusselect = <select id={"project_status_"+project_id} className={"browser-default right "+selectcolorclass} onChange={(e)=>changeStatusTableRow(e)}>
                                {pendingsel}
                                {activesel}
                                {completedsel}
                                </select>

            var website_html = <a id={"ext_link_"+project_id} href={company_website} target="_blank" className="material-icons material-icons-outlined pageTextColorClass"
                                    title="Visit Client Website" 
                                    style={{fontSize:'20px',verticalAlign:'sub',marginLeft:'5px'}}>
                                public
                                </a>;

            var tmp_row = <div key={i+startnum} id={"project_"+project_id} className={"col s12 rounded-section z-depth-1 "+pageTableColorClass}>
                                <div className="row" style={{margin:"0px",padding:"20px 0px 15px 0px",fontSize:'14px',marginBottom:'0px',position:'relative'}}>
                                    <div className="col s12 m2 l2" onClick={(e)=>openProject(e)}>
                                        {i+1+startnum}. {request_client}
                                    </div>
                                    <div className="col s12 m2 l2 center-align" style={{lineHeight:'1'}}>
                                        {statusselect}
                                    </div>
                                    <div className="col s12 m2 l2 center-align" onClick={(e)=>openProject(e)}>
                                        {request_date}
                                    </div>
                                    <div className="col s12 m2 l1" onClick={(e)=>openProject(e)}>
                                        ${request_quote_amount.toLocaleString()}
                                    </div>
                                    <div className="col s12 m4 l5" onClick={(e)=>openProject(e)}>
                                        {deliverable_list}
                                    </div>
                                    <div style={{position:'absolute',right:'0px',top:'20px'}} onClick={(e)=>openProject(e)}>
                                        <span class="material-symbols-outlined black-text" style={{fontSize:'20px'}}>
                                            more_vert
                                        </span>
                                    </div>
                                </div>
                            </div>
            final_rows.push(tmp_row);
        }

        if((final_rows.length == 0)&&(startnum == 0)) {
            var borderc = "1px solid "+pageBorderColorClass;
            var tmp_row = <div key={"no_results_available"} id={"no_results_available"}  className={"col s12 rounded-section"}>
                                <div className="row navy-text" style={{margin:"0px",marginTop:'10px',padding:"20px 10px",fontSize:'16px',borderTop:borderc, marginBottom:'0px'}}>
                                    No Projects Available
                                </div>
                            </div>;
            final_rows.push(tmp_row);
        }
        
        return final_rows;
    }

    function showPageSectionRowHeader(pagename) {

        var table_label_row = <></>;

        if((pagename == 'new')||(pagename == 'declined')) {
            table_label_row = <div className="row" style={{position:'relative',margin:'0px',padding:"0px",fontSize:'14px',marginBottom:'5px',fontWeight:'900'}}>
                                    <div className="col s12 m3 l3">
                                        Client Name
                                    </div>
                                    <div className="col s12 m2 l2 center-align">
                                        {tableDateText}
                                    </div>
                                    <div className="col s12 m2 l1">
                                        Value
                                    </div>
                                    <div className="col s12 m3 l5">
                                        Deliverables
                                    </div>
                                    <div className="col s12 m2 l1">
                                        Status
                                    </div>
                                </div>;
        } else {
            table_label_row = <div className="row" style={{position:'relative',margin:'0px',padding:"0px",fontSize:'14px',marginBottom:'5px',fontWeight:'900'}}>
                                <div className="col s12 m3 l2">
                                    Client Name
                                </div>
                                <div className="col s12 m2 l2 center-align">
                                    Status
                                </div>
                                <div className="col s12 m2 l2 center-align">
                                    {tableDateText}
                                </div>
                                <div className="col s12 m2 l1">
                                    Value
                                </div>
                                <div className="col s12 m3 l5">
                                    Deliverables
                                </div>
                            </div>;
        }
        setTableLabelsHTML(table_label_row);
        
    }

    function changePageSection(pagename) {
        current_table_page = 0;
        current_pagename = pagename;
        setTableRowsHTML(<></>);
        $(".section-left-rail-button").each(function(){
            $(this).removeClass("section-left-rail-button-selected");
        });
        $("#"+pagename+"_proposals_button").addClass("section-left-rail-button-selected");
        
        if(pagename == 'new') {
            setHeadlineText('New Requests');
            tableDateText = 'Submitted';
            tableStatusText = 'Pending';
            current_page_status = 'P';
        } else if(pagename == 'qualified') {
            setHeadlineText('Qualified Proposals');
            tableDateText = 'Submitted';
            tableStatusText = 'Qualified';
            current_page_status = 'P';
        } else if(pagename == 'closing') {
            setHeadlineText('Active Proposals');
            tableDateText = 'Updated';
            tableStatusText = 'Closing';
            current_page_status = 'P';
        } else if(pagename == 'progress') {
            setHeadlineText('Active Projects');
            tableDateText = 'Updated';
            tableStatusText = 'Creating';
            current_page_status = 'A';
        } else if(pagename == 'completed') {
            setHeadlineText('Completed Projects');
            tableDateText = 'Completed';
            tableStatusText = 'Complete';
            current_page_status = 'C';
        } else if(pagename == 'declined') {
            setHeadlineText('Declined Projects');
            tableDateText = 'Updated';
            tableStatusText = 'Declined';
            current_page_status = 'D';
        }
        showPageSectionRowHeader(pagename);
        grabProjectRows();
        showProjectManagerRows();
    }

    function loadProjectManagerCounts() {
        var addData = new Object();
        addData.id = encodeURIComponent(user_member_id);
        //addData.page = current_table_page;
        addData.page = 0;
        addData.status = 'P';

        $(".leftrailcount").each(function(){
            $(this).hide();
        });
        axiosInstance.post('projects/project_count/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var count_data = result.data;
                var upd_proposal_count = 0;
                var upd_qualified_count = 0;
                var upd_closing_count = 0;
                var upd_progress_count = 0;
                var upd_completed_count = 0;
                var upd_declined_count = 0;
                for(var i = 0;i < count_data.length;i++) {
                    if(count_data[i]['project_category'] == 'RP') {
                        upd_proposal_count = count_data[i]['total_count'];
                        $("#new_proposal_count").show();
                    } else if(count_data[i]['project_category'] == 'RQ') {
                        upd_qualified_count = count_data[i]['total_count'];
                        $("#qualified_count").show();
                    } else if(count_data[i]['project_category'] == 'RD') {
                        upd_declined_count = count_data[i]['total_count'];
                        $("#declined_count").show();
                    } else if(count_data[i]['project_category'] == 'PP') {
                        upd_closing_count = count_data[i]['total_count'];
                        $("#closing_count").show();
                    } else if(count_data[i]['project_category'] == 'PA') {
                        upd_progress_count = count_data[i]['total_count'];
                        $("#in_progress_count").show();
                    } else if(count_data[i]['project_category'] == 'PC') {
                        upd_completed_count = count_data[i]['total_count'];
                        $("#completed_count").show();
                    }
                }
                setCountProposals(upd_proposal_count);
                setCountDeclined(upd_declined_count);
                setCountQualified(upd_qualified_count);
                setCountClosing(upd_closing_count);
                setCountProgress(upd_progress_count);
                setCountCompleted(upd_completed_count);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function changeStatusTableRow(e) { 
        e.preventDefault();
        var current_elem_id = e.target.id;
        var status_value = $("#"+current_elem_id).val();
        var current_target = current_elem_id.split("_status_");
        var target_type = current_target[0];
        var target_id = current_target[1];

        if (target_type == 'project') {
            updateProjectStatus(target_id,'status',status_value,'table');
        } else if (target_type == 'projectsub') {
            updateProjectStatus(target_id,'substatus',status_value,'table');
        } else if (target_type == 'request') {
            updateRequestStatus(target_id,status_value,'table');
        }
    }

    function updateRequestStatus(requestid,updatestatus,updatesource) {
        var addData = new Object();
        addData.id = requestid;
        addData.status = updatestatus;

        var update_url = 'projects/request_update/';
        if(updatestatus == 'A') {
            update_url = 'projects/request_approve/';
        }
        axiosInstance.post(update_url,addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                loadProjectManagerCounts();
                $("#project_"+requestid).hide();
                if(updatesource == 'modal') {
                    showProjectModal(requestid);
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function updateProjectStatus(projectid,updatetype,updatestatus,updatesource) {
        var addData = new Object();
        addData.id = projectid;
        addData.status = updatestatus;
        addData.type = updatetype;

        var update_url = 'projects/project_update/';
        axiosInstance.post(update_url,addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                if(updatetype !== 'substatus') {
                    loadProjectManagerCounts();
                    $("#project_"+projectid).hide();
                }
                if(updatesource == 'modal') {
                    showProjectModal(projectid);
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function loadProjectModals() {
        var projectbox = document.getElementById('viewprojectbox');
        var project_instance = window.M.Modal.init(projectbox,{opacity:'75%'});

        var projectbox2 = document.getElementById('newprojectbox');
        var project_instance2 = window.M.Modal.init(projectbox2,{opacity:'75%'});

        
        //project_instance.open();
    }

    function openProject(e) { 
        var parentfound = 0;
        var current_project_id = 0;
        var current_target = e.target;
        var external_link = 0;
        
        while(parentfound == 0) {
            if('id' in current_target) {
                var element_id = current_target.id;
                if(element_id.indexOf('ext_link_') >= 0) {
                    external_link = 1;
                } else if(element_id.indexOf('project_') >= 0) {
                    current_project_id = parseInt(element_id.replace("project_",""));
                    parentfound = 1;
                }
            }
            if(parentfound == 0) {
                current_target = current_target.parentElement;
            }
            
        }
        if(external_link == 0) {
            showProjectView(current_project_id);
            //showProjectModal(current_project_id);
        }
    }

    function showProjectView(projectid) {
        setCurrentProjectView(<ProjectManagerPublic projectID={projectid} goBackLink={showProjectManagerRows} />)
        $("#pm_rows").hide();
        $("#pm_view").show();
        current_project_view = 1;
    }

    function showProjectManagerRows() {
        $("#pm_rows").show();
        $("#pm_view").hide();
        current_project_view = 0;
        setCurrentProjectView('');
    }

    function showProjectModal(projectid) {
        var tmphtml = <></>;
        for(var i = 0;i < active_project_data.length;i++) {
            var project_identifier = active_project_data[i]['work_request_id'];
            if(project_identifier == projectid) {

                var work_request_id = active_project_data[i]['work_request_id'];
                var full_name = active_project_data[i]['first_name']+' '+active_project_data[i]['last_name'];
                var company_email = active_project_data[i]['email_address'];
                var company_name = active_project_data[i]['company_name'];
                var company_website = active_project_data[i]['company_website'];
                var request_quote_amount = active_project_data[i]['request_quote_amount'];
                var request_date = active_project_data[i]['request_month']+'/'+active_project_data[i]['request_day']+'/'+active_project_data[i]['request_year'];
                var request_message = active_project_data[i]['request_message'];
                var request_client = company_name;
                if((company_name.length == 0)||(company_name == 'null')||(company_name == '')||(company_name == undefined)) {
                    request_client = full_name;
                }
                var deliverable_list = '';
                var dcnt = 0;
                for(var j = 0; j < active_project_data[i]['request_deliverables'].length;j++) {
                    if(active_project_data[i]['request_deliverables'][j]) {
                        if(dcnt > 0) {
                            deliverable_list = deliverable_list+", ";
                        }
                        deliverable_list = deliverable_list+active_project_data[i]['request_deliverables'][j]['option_title'];
                        dcnt++;
                    }
                }

                var website_html = <a href={company_website} target="_blank" className="pageTextColorClass"
                                    title="Visit Client Website" 
                                    style={{cursor:'pointer'}}>
                                {company_website}
                                </a>;

                var tmp_row = <><div className="col s12" style={{fontSize:"16px",marginTop:"0px",padding:"0px 20px"}}>
                                <div className="headline-font white-text row" style={{fontSize:"24px"}}>Project: {request_client}</div>
                                <div className="row">
                                    Client: {request_client}
                                </div>
                                <div className="row">
                                    Contact Name: {full_name}
                                </div>
                                <div className="row">
                                    Contact Email: {company_email}
                                </div>
                                <div className="row">
                                    Client Website: {website_html}
                                </div>
                                <div className="row">
                                    Project Status: {tableStatusText}
                                </div>
                                <div className="row">
                                    Last Updated: {request_date}
                                </div>
                                <div className="row">
                                    Project Value: ${request_quote_amount.toLocaleString()}
                                </div>
                                <div className="row">
                                    Project Deliverables: {deliverable_list}
                                </div>
                                <div className="row">
                                    Client Project Scope: {request_message}
                                </div>
                            </div>
                            </>;
                setModalHTML(tmp_row);
            }
        }


        var projectbox = document.getElementById('viewprojectbox');
        var project_instance = window.M.Modal.getInstance(projectbox);
        project_instance.open();
    }

    function showNewProjectModal() {
        var projectbox = document.getElementById('newprojectbox');
        var project_instance = window.M.Modal.getInstance(projectbox);
        project_instance.open();
        
    }

    function addNewProjectSimple() {
        var project_name = 'New Project';
        var client_name = 'New Client';

        var addData = new Object();
        addData.id = user_member_id;
        addData.oid = user_org_id;
        addData.pName = project_name;
        addData.pClient = client_name;

        var update_url = 'projects/project_new/';
        axiosInstance.post(update_url,addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                window.location.reload();
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function closeAllModals() {
        console.log('close modals');
    }

    function showSelectForms() {
        var select_elems = document.querySelectorAll('select');
        var instances = window.M.FormSelect.init(select_elems);
    }

    function saveProjectChanges() {
        console.log('save project changes');
    }

    if(pageSet == false) {
        setPage(true);
        setTimeout(function(){
            loadProjectManagerCounts();
            changePageSection('new');
            loadProjectModals();
        },50);
    }

    return (<>
    {pageGlobalHeader}
    <div className="row" style={{padding:'0px 20px',margin:'0px',minHeight:'95vh'}}>
        <div className="col s12">
            <div className="row" style={{position:'relative',padding:'0px',margin:'0px',fontSize:'16px',marginTop:'20px',fontFamily:'helvetica'}}>
                <div className="col s12 m3 black-text" style={{padding:'0px',marginTop:'0px'}}>
                    <div className="row transparent" style={{position:'relative',margin:'0px',padding:"0px",marginBottom:'0px',minHeight:'80vh',width:'90%',borderRight:'1px solid '+pageBorderColorClass}}>
                        <div className="col s12 white-text headline-font" style={{position:'relative',margin:'0px',padding:"10px 20px 10px 20px",fontSize:'30px'}}>
                            Project Manager
                        </div>
                        <div className="col s12 white-text" style={{position:'relative',margin:'0px',padding:"0px 20px",fontSize:'14px'}}>
                            Stay on top of all of your projects including new proposals, projects in progress and completed work.
                        </div>
                        <div className="col s12 subheadline-font white-text" style={{padding:'0px',marginTop:'40px',width:'80%'}}>
                            <div id="new_proposals_button" className="section-left-rail-button section-left-rail-button-selected" onClick={()=>changePageSection('new')}>
                                <span>New Requests</span>
                                <span id="new_proposal_count" className="leftrailcount">{new_proposal_count}</span>
                            </div>
                            <div id="closing_proposals_button" className="section-left-rail-button" onClick={()=>changePageSection('closing')}>
                                <span>Proposals</span>
                                <span id="closing_count" className="leftrailcount">{closing_count}</span>
                            </div>
                            <div id="progress_proposals_button" className="section-left-rail-button" onClick={()=>changePageSection('progress')}>
                                <span>Active Projects</span>
                                <span id="in_progress_count" className="leftrailcount">{in_progress_count}</span>
                            </div>
                            <div id="completed_proposals_button" className="section-left-rail-button" onClick={()=>changePageSection('completed')}>
                                <span>Completed</span>
                                <span id="completed_count" className="leftrailcount">{completed_count}</span>
                            </div>
                            <div id="declined_proposals_button" className="section-left-rail-button" onClick={()=>changePageSection('declined')}>
                                <span>Declined</span>
                                <span id="declined_count" className="leftrailcount">{declined_count}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col s12 m9 black-text" style={{padding:'0px',paddingRight:'10px',marginTop:'0px'}}>
                    <div id="pm_rows">
                        <div className="row rounded-section transparent" style={{position:'relative',margin:'0px',padding:"10px 20px",marginBottom:'0px'}}>
                            <div className="col s12 white-text" style={{padding:'0px',paddingBottom:'0px'}}>
                                <div className="left white-text headline-font" style={{position:'relative',margin:'0px',padding:"0px",fontSize:'30px'}}>
                                    <span className="left">{headlineText}</span>
                                    <span className="left material-symbols-outlined" 
                                        style={{marginLeft:'10px',marginTop:'12px',cursor:'pointer'}}
                                        title="Add New Proposal or Project" onClick={()=>showNewProjectModal()}>add_circle</span>
                                </div>
                                <div className="right right-align" style={{marginRight:'0px',marginTop:'0px',marginBottom:'0px'}}>
                                    <div className="left" style={{fontSize:'12px',marginRight:'10px',marginTop:'20px',color:pageBorderColorClass}}>Total Value</div>
                                    <div id="section_total_value" className="left headline-font" style={{fontSize:'30px',color:pageAccentColorClass}}>$10,000</div>
                                </div>
                            </div>
                        </div>
                        <div className="row rounded-section" style={{position:'relative',margin:'0px',padding:"10px",marginBottom:'20px',minHeight:'70vh'}}>
                            <div className="col s12 white-text" style={{padding:'10px',paddingBottom:'0px'}}>
                                {tableLabelsHTML}
                                <div className="row" style={{border:'1px solid #EBEBEB',height:'1px',marginBottom:'15px',display:'none'}}>
                                </div>
                            </div>
                            {tableRowsHTML}
                            <div className="col s12 white-text" style={{padding:'10px',paddingBottom:'0px'}}>
                                <div className="row" style={{margin:'0px',padding:"0px",marginBottom:'5px'}}>
                                    <div id="show_more_button" className="center subheadline-text section-left-rail-button" 
                                        style={{margin:'0px',fontSize:'16px',display:'none'}} onClick={()=>showMoreRows()}>
                                        Show More
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="pm_view">
                        {currentProjectView}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="viewprojectbox" className="modal page-modal page-modal-large" style={{background:'#132C51',minHeight:'80vh',borderRadius:'10px'}}>
            <div className="modal-content" style={{minHeight:'80vh'}}>
                <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                    {modalHTML}
                </div>
            </div>
        </div>
        <div id="newprojectbox" className="modal" style={{background:'white',minHeight:'40vh',borderRadius:'10px'}}>
            <div className="modal-content">
                <div className="row rounded-section" style={{margin:'0px',padding:"0px"}}>
                    <div className="col s12" style={{margin:'0px',padding:"20px 30px 10px 20px"}}>
                        <div className="row center headline-font" style={{margin:'0px',marginTop:'20px',fontSize:'30px'}}>
                            NEW PROJECT
                        </div>
                        <div className="row center" style={{margin:'0px',marginTop:'10px',fontSize:'16px'}}>
                            Click To Create New Project
                        </div>
                        <div className="row white-text" style={{margin:'0px',marginTop:'25px',marginBottom:'20px'}}>
                            <div id="create-project-button" className="row center-align white-text" style={{marginBottom:'10px'}}>
                                <div className="col s10 offset-s1 m6 offset-m3 waves-effect waves-light white-text z-depth-2" 
                                    style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 10px',cursor:'pointer',border:'3px solid #4360CD', background:'#4360CD'}} 
                                    onClick={()=>addNewProjectSimple()}>
                                    Create New Project
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                    
        </>
    );
};