import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import {axiosInstance, closeAllModals, validateEmailText } from "../../utils";
import {setAccountSignOn} from "../../redux/Login/actions";

const SignupForm = (props) => {    
    const dispatch = useDispatch();
    const { memberID, orgID } = useSelector((state) => state.login);

    const [pageSet, setPage] = useState(false);
    const [headerHTML, setHeaderHTML] = useState(null);
    const [sectionHTML, setSectionHTML] = useState(null);
    const [linksHTML, setLinksHTML] = useState(null);
    const [optionHTML, setOptionHTML] = useState(null);
    const [currentEmailAddress, setCurrentEmailAddress] = useState(null);

    let startingRate = 0;

    useEffect(() => {
        document.body.style.backgroundColor="#F7F7F7";
    }, []);

    function registerForWaitroom() {
        var member_first_name = $("#firstName").val();
        var member_last_name = $("#lastName").val();
        var member_email = $("#memberEmail").val();

        var addData = new Object();
        addData.email = member_email;
        addData.firstName = member_first_name;
        addData.lastName = member_last_name;

        if((member_first_name.length == 0)&&(member_last_name.length == 0)) {
            alert('Add Valid First and Last Name');
        } else if(validateEmailText(member_email) == 'fail') {
            alert('Add Valid Email Address');
        } else {
            $("#save-new-button").hide();
            $("#save-new-loading").show();
            setCurrentEmailAddress(member_email);

            axiosInstance.post('member/waitlist_add_code/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    setTimeout(function() {
                        $("#save-new-button").show();
                        $("#save-new-loading").hide();
                        showEmailCodeForm();
                    },2000);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function confirmWaitroomEmailCode() {
        var member_email = $("#memberEmail").val();
        var email_code = $("#memberEmailCode").val();
        var member_first_name = $("#firstName").val();
        var member_last_name = $("#lastName").val();

        var addData = new Object();
        addData.email = member_email;
        addData.emailCode = email_code;
        addData.firstName = member_first_name;
        addData.lastName = member_last_name;

        if(email_code.length == 0) {
            alert('Add Confirmation Code Sent To Your Email');
        } else {
            $("#save-code-button").hide();
            $("#save-code-loading").show();

            axiosInstance.post('member/member_add/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    let login_info = result.data[0];
                    if (login_info['status'] == 'success') {
                        // New User - Add to Users Table
                        let member_id = login_info['id'];
                        let org_id = login_info['orgID'];
                        let logintime = login_info['time'];
                        let logintoken = login_info['token'];

                        // Login to Site
                        dispatch(setAccountSignOn(member_id,org_id,logintoken,logintime));

                        setTimeout(function() {
                            $("#save-code-button").show();
                            $("#save-code-loading").hide();
                            goToNextStep();
                        },2000);
                    } else if (login_info['status'] == 'failed_code') {
                        setTimeout(function() {
                            $("#save-code-button").show();
                            $("#save-code-loading").hide();
                            alert('Enter an Active Email Code. Double Check Your Code From Your Email.');
                        },2000);
                    } else {
                        setTimeout(function() {
                            $("#save-code-button").show();
                            $("#save-code-loading").hide();
                            alert('No Account Under This Email');
                        },2000);
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function showEmailCodeForm() {
        $("#signup-email-form").hide();
        $("#signup-email-code-form").fadeIn();
    }

    function switchForms(formname) {
        props.changeForm(formname);
    }

    function goToNextStep() {
        props.completedStep(1);
    }

    if(pageSet == false) {
        setPage(true);
    }

    return (<>
            <div id="signup-email-form">
                <div className="row" style={{fontSize:'30px',margin:'0px',marginTop:'10px'}}>
                    <div className="left headline-font">Get Started</div>
                </div>
                <div className="section-divider"></div>
                <div className="row" style={{marginTop:'10px',marginBottom:'0px',display:'flex',flexDirection:'row'}}>
                    <div className="col s12" style={{flex:'1',padding:'0px 20px'}}>
                        <div className="row">
                            <div className="col s12" style={{padding:'10px 20px'}}>
                                <div className="row" style={{marginBottom:'10px'}}>
                                    <div className="col s12 l6">
                                        <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                            First Name
                                        </div>
                                        <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                            <input type="text" id="firstName"
                                            className="left browser-default rounded-input z-depth-1"
                                            style={{width:"98%",marginRight:'2%'}} />
                                        </div>
                                    </div>
                                    <div className="col s12 l6">
                                        <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                            Last Name
                                        </div>
                                        <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                            <input type="text" id="lastName"
                                            className="left browser-default rounded-input z-depth-1"
                                            style={{width:"98%",marginRight:'2%'}} />
                                        </div>
                                    </div>
                                    <div className="col s12" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                        <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                            Email Address
                                        </div>
                                        <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                            <input type="text" id="memberEmail"
                                            className="left browser-default rounded-input z-depth-1"
                                            style={{width:"98%",marginRight:'2%'}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{marginBottom:'0px'}}>
                            <div id="save-new-divider" className="section-divider"></div>
                        </div>
                        <div className="row" style={{fontSize:'18px',marginBottom:'0px',padding:'20px 30px',paddingRight:'10px'}}>
                            <div id="go-signup" className="black-text left" style={{cursor:'pointer',marginTop:'10px'}} onClick={()=>switchForms('login')}>
                                    Login
                            </div>
                            <div id="save-new-button" className="waves-effect waves-light white-text z-depth-2 right" 
                                    style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD',marginRight:'20px'}} 
                                    onClick={()=>registerForWaitroom()} >
                                    Create Account
                            </div>
                            <div id="save-new-loading" style={{display:'none'}}>
                                <div className="preloader-wrapper small active right">
                                    <div className="spinner-layer spinner-blue-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div><div className="gap-patch">
                                        <div className="circle"></div>
                                    </div><div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="signup-email-code-form" style={{display:'none'}}>
                <div className="row" style={{fontSize:'30px',margin:'0px',marginTop:'10px'}}>
                    <div className="left headline-font">Confirm Your Email</div>
                </div>
                <div className="section-divider"></div>
                <div className="row" style={{marginTop:'10px',marginBottom:'0px',display:'flex',flexDirection:'row'}}>
                    <div className="col s12" style={{flex:'1',padding:'0px 20px'}}>
                        <div className="row">
                            <div className="col s12" style={{padding:'10px 20px'}}>
                                <div className="row" style={{marginBottom:'10px'}}>
                                    <div className="col s12">
                                        <div className="row" style={{fontSize:'20px',padding:'0px 10px',marginBottom:'30px'}}>
                                            Email Sent: {currentEmailAddress}
                                        </div>
                                    </div>
                                    <div className="col s12" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                        <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                            Email Confirmation Code:
                                        </div>
                                        <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                            <input type="text" id="memberEmailCode"
                                            className="left browser-default rounded-input z-depth-1"
                                            style={{width:"98%",marginRight:'2%'}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div id="save-new-divider" className="section-divider"></div>
                        </div>
                        <div className="row" style={{fontSize:'18px',marginBottom:'0px',padding:'20px 30px',paddingRight:'10px'}}>
                            <div id="go-signup" className="black-text left" style={{cursor:'pointer',marginTop:'10px'}} onClick={()=>switchForms('login')}>
                                    Login
                            </div>
                            <div id="save-code-button" className="waves-effect waves-light white-text z-depth-2 right" 
                                    style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD',marginRight:'20px'}}
                                    onClick={()=>confirmWaitroomEmailCode()} >
                                    Confirm Email
                            </div>
                            <div id="save-code-loading" style={{display:'none'}}>
                                <div className="preloader-wrapper small active right">
                                    <div className="spinner-layer spinner-blue-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div><div className="gap-patch">
                                        <div className="circle"></div>
                                    </div><div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
    );
};

export default SignupForm;