import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import {axiosInstance, closeAllModals} from "../../utils";
import rate_card_info from "./rate_card_sample.json";

let project_info = []
const ProjectSettings = (props) => {    
    const { memberID, orgID } = useSelector((state) => state.login);
    
    const [pageSet, setPage] = useState(false);
    const [headerHTML, setHeaderHTML] = useState(null);
    const [linksHTML, setLinksHTML] = useState(null);
    const [optionHTML, setOptionHTML] = useState(null);
    const [settingsHTML, setSettingsHTML] = useState([]);
    const [projectID, setProjectID] = useState(props.projectID);
    
    let startingRate = 0;

    useEffect(() => {
        document.body.style.backgroundColor="#F7F7F7";
        showSelectForms();
      }, []);

      function grabProjectInfo() {
        var addData = new Object();
        addData.pid = encodeURIComponent(projectID);

        axiosInstance.post('projects/project_info/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var project_details = result.data; 
                project_info = project_details;
                loadProjectInfo(project_info[0]);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function showSelectForms() {
        var select_elems = document.querySelectorAll('select');
        var instances = window.M.FormSelect.init(select_elems);
    }

    function loadProjectInfo(pdetails) {
        var projectname = pdetails['project_name'];
        projectname = 'Evian Podcast Feature';
        if(pdetails['project_amount'] == null) {
            pdetails['project_amount'] = 0;
        }
        var budget_text = pdetails['project_amount'].toLocaleString()+" "+pdetails['project_currency'];
        if(pdetails['project_currency'] == 'USD') {
            budget_text = '$'+budget_text;
        }

        var main_background = '';
        if(main_background.length > 0) {
            document.body.style.background = main_background;
        }
        var pdeliverables = loadProjectDeliverables(pdetails['project_deliverables']);
        

        var tmp_html = <><div className="col s12 m8" style={{padding:'10px'}}>
                                <div className="row" style={{margin:'0px',padding:"5px",marginTop:'0px'}}>
                                    Client Name
                                </div>
                                <div className="row" style={{margin:'0px'}}>
                                    <input type="text" id="client-name" className="browser-default rounded-input z-depth-1" defaultValue={pdetails['client_name']} disabled />
                                </div>
                                <div className="row" style={{margin:'0px',padding:"5px",marginTop:'15px'}}>
                                    Project Title
                                </div>
                                <div className="row" style={{margin:'0px'}}>
                                    <input type="text" id="project-name" className="browser-default rounded-input z-depth-1" defaultValue={pdetails['project_name']} />
                                </div>
                                <div className="row" style={{margin:'0px',padding:"5px",marginTop:'15px'}}>
                                    Project Budget (Total Cost)
                                </div>
                                <div className="row" style={{margin:'0px'}}>
                                    <input type="text" id="project-budget" 
                                        className="left browser-default rounded-input z-depth-1"
                                        style={{width:"49%",marginRight:'2%'}}  
                                        defaultValue={pdetails['project_amount'].toLocaleString()} />
                                    <select id="project-budget-currency"  
                                            className="browser-default rounded-input left  z-depth-1 in-row-select-light"
                                            style={{width:"49%",color:'black',height:'45px',marginTop:'0px'}}>
                                        <option value="USD">USD (U.S. Dollars)</option>
                                    </select>
                                </div>
                                <div className="row" style={{margin:'5px',marginTop:'15px'}}>
                                    <span className="left right-align" style={{marginTop:'0px',width:"49%",marginRight:'2%'}} >
                                        Pre-Payment / Deposit:<br/>
                                        <span className="right-align" style={{marginTop:'2px',fontSize:'14px'}}>(Due at Start Of Work)</span>
                                    </span>
                                    <input type="text" id="project-percent-prepay" 
                                        className="left browser-default rounded-input z-depth-1"
                                        style={{width:"20%",marginRight:'2%'}}  
                                        defaultValue={pdetails['payment_upfront_percentage']}/>
                                    <select id="project-budget-currency"  
                                            className="browser-default rounded-input left  z-depth-1 in-row-select-light"
                                            style={{width:"27%",color:'black',height:'45px',marginTop:'0px'}}>
                                        <option value="percent">% Percent</option>
                                        <option value="flat">Flat Rate</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col s12 m4" style={{padding:'10px'}}>
                                <div className="row" style={{margin:'0px',padding:"5px"}}>
                                    Project Photo
                                </div>
                                <div className="row center-align" style={{margin:'0px'}}>
                                    <img className="z-depth-1" style={{width:'200px',height:'200px',background:'#EBEBEB',borderRadius:'5px'}} />
                                </div>
                                <div className="row right-align" style={{margin:'0px'}}>
                                    <input type="file" id="project-image" className="browser-default" style={{width:'100%',fontFamily:'helvetica',fontSize:'14px'}} />
                                </div>
                            </div>
                            <div className="col s12" style={{padding:'10px'}}>
                                <div className="row" style={{margin:'0px',padding:"5px",marginTop:'10px'}}>
                                    Project Completion Timeline (Estimate)
                                </div>
                                <div className="row" style={{margin:'0px',marginTop:'0px'}}>
                                    <select id="project-timeline-type"  
                                            className="browser-default rounded-input left z-depth-1 in-row-select-light"
                                            style={{width:"20%",marginRight:'2%',color:'black',height:'45px',marginTop:'0px'}}>
                                        <option value="time_range" selected>Time Range</option>
                                        <option value="date_range">Date Range</option>
                                        <option value="fixed_date">Fixed Date</option>
                                    </select>
                                    <input type="text" id="project-timeline-text-metric" 
                                        className="left browser-default rounded-input z-depth-1"
                                        style={{width:"15%",marginRight:'2%'}}  
                                        defaultValue={pdetails['payment_upfront_percentage']}/>
                                    <select id="project-timeline-measure-text-metric"  
                                            className="browser-default rounded-input left z-depth-1 in-row-select-light"
                                            style={{width:"20%",color:'black',height:'45px',marginTop:'0px'}}>
                                        <option value="days">Day(s)</option>
                                        <option value="weeks" selected>Week(s)</option>
                                        <option value="months">Month(s)</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col s12" style={{padding:'10px'}}>
                                <div className="row" style={{margin:'0px',padding:"5px"}}>
                                    Project Deliverables
                                </div>
                                <div className="row" style={{margin:'0px'}}>
                                    {pdeliverables}
                                </div>
                            </div>
                            <div className="col s12" style={{padding:'10px'}}>
                                <div className="row" style={{margin:'0px',padding:"5px"}}>
                                    Project Details
                                </div>
                                <div className="row" style={{margin:'0px'}}>
                                    <textarea id="request-notes" className="browser-default rounded-input z-depth-1" style={{minHeight:'150px'}}></textarea>
                                </div>
                            </div></>;       
        setSettingsHTML(tmp_html);
        setTimeout(function() {
            showSelectForms();
        },500);
    }

    function loadProjectDeliverables(options_list) {
        
        var final_options = new Array();
        for(var i = 0;i < options_list.length;i++) {
            var option_id = options_list[i]['rate_card_option_id'];
            var option_network = options_list[i]['option_type'];
            var option_title = options_list[i]['deliverable_title'];
            var option_rate = options_list[i]['option_rate'];
            var tmp_option = <div key={i} className="col s12 m6" style={{padding:'10px'}}>
                            <div className="row rounded-section" style={{margin:'0px',padding:"10px 15px",borderRadius:'5px'}}>
                                <input type="text" 
                                        className="left browser-default rounded-input z-depth-1" 
                                        style={{width:"15%",marginRight:'2%'}}  
                                    value="1" />
                                <span className="left">{option_title}</span>
                            </div>
                        </div>
            final_options.push(tmp_option);
        }

        return final_options;
    }

    function saveProjectInfo() {
        let project_name = $("#project-name").val();
        let project_image = $("#project-image").val();
        let project_budget = $("#project-budget").val().replace(",","");
        let project_budget_currency = $("#project-budget-currency").val()
        let project_deliverables = $("#project-deliverables").val();
        let project_details = $("#project-details").val();

        var addData = new Object();
        addData.pID = projectID;
        addData.pName = encodeURIComponent(project_name);
        addData.pEmail = encodeURIComponent(project_image);
        addData.pBudget = encodeURIComponent(project_budget);
        addData.pBudgetCurrency = encodeURIComponent(project_budget_currency);
        // addData.pDeliverables = encodeURIComponent(project_deliverables);
        
        
        if(project_name.length <= 2) {
            alert('Please Add A Project Title.');
        } else {
            $("#project-save-button").hide();
            $("#project-loading-button").show();
            $("#project-save-button2").hide();
            $("#project-loading-button2").show();
            console.log(addData);
            axiosInstance.post('projects/project_edit/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    $("#project-loading-button").hide();
                    $("#project-saved-button").show();
                    $("#project-loading-button2").hide();
                    $("#project-saved-button2").show();
                    // setTimeout(function(){
                    //     window.location.reload();
                    // },2000);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    if(pageSet == false) {
        setPage(true);
        setTimeout(function(){
            grabProjectInfo();
        },50);
    }

    return (
    <div className="row subheadline-font" style={{position:'relative',fontSize:'16px',padding:'0px',margin:'0px',marginTop:'0px'}}>
                <div className="col s12 black-text" style={{padding:'10px'}}>
                    <div id="collab-request-section" className="row rounded-section white z-depth-1" style={{margin:'0px',padding:"0px"}}>
                        <div id="project-settings-form" className="col s12" style={{margin:'0px',padding:"20px 30px"}}>
                            <div className="row" style={{fontSize:'30px',margin:'0px'}}>
                                <div className="left headline-font">EDIT PROJECT</div>
                                <div id="project-save-button" className="right waves-effect waves-light white-text z-depth-2" 
                                        style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD'}} 
                                        onClick={()=>saveProjectInfo()} >
                                        Save Project
                                </div>
                                <div className="right" style={{marginRight:'20px',marginTop:'12px',fontSize:'16px',cursor:'pointer'}} onClick={()=>props.cancelCallback()}>Back</div>
                                <div id="project-loading-button" className="right" style={{display:'none'}}>
                                    <div class="preloader-wrapper small active">
                                        <div class="spinner-layer spinner-blue-only">
                                        <div class="circle-clipper left">
                                            <div class="circle"></div>
                                        </div><div class="gap-patch">
                                            <div class="circle"></div>
                                        </div><div class="circle-clipper right">
                                            <div class="circle"></div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="project-saved-button" className="right waves-effect waves-light white-text z-depth-2" 
                                        style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid green', background:'green',display:'none'}} >
                                        Project Saved!
                                </div>
                            </div>
                            <div className="section-divider"></div>
                            <div className="row" style={{margin:'0px'}}>
                                Update project details including any information about the collaboration requirements, deliverables, timelines and budget.
                            </div>
                            <div className="row" style={{marginTop:'10px'}}>
                                {settingsHTML}
                            </div>
                            <div className="row" style={{marginTop:'10px'}}>
                                <div id="project-save-button2" className="row center-align" style={{marginBottom:'10px'}}>
                                    <div className="col s10 offset-s1 m6 offset-m3 waves-effect waves-light white-text z-depth-2" 
                                        style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD'}} 
                                        onClick={()=>saveProjectInfo()} >
                                        Save Project
                                    </div>
                                </div>
                                <div id="project-loading-button2" className="row center-align" style={{marginBottom:'10px',display:'none'}}>
                                    <div class="preloader-wrapper small active">
                                        <div class="spinner-layer spinner-blue-only">
                                        <div class="circle-clipper left">
                                            <div class="circle"></div>
                                        </div><div class="gap-patch">
                                            <div class="circle"></div>
                                        </div><div class="circle-clipper right">
                                            <div class="circle"></div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="project-saved-button2" className="row center-align" style={{marginBottom:'10px',display:'none'}}>
                                    <div className="col s10 offset-s1 m6 offset-m3 waves-effect waves-light white-text z-depth-2" 
                                        style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid green', background:'green'}} >
                                        Project Saved!
                                    </div>
                                </div>
                                <div id="project-cancel-button" className="row center-align" style={{marginBottom:'10px'}}>
                                    <div className="col s10 offset-s1 m6 offset-m3 black-text" 
                                        style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px'}} 
                                        onClick={()=>props.cancelCallback()} >
                                        Cancel
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
    );
};

export default ProjectSettings;