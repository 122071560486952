import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import {GlobalHeader, GlobalLeftRail} from '../../components';
import rate_card_info from "./rate_card_creator_sample.json";

export const RateCardCreator = (props) => {    
    const { memberID, orgID } = useSelector((state) => state.login);
    
    const [pageSet, setPage] = useState(false);
    const [headerHTML, setHeaderHTML] = useState(<></>);
    const [optionHTML, setOptionHTML] = useState([]);
    const [selectionHTML, setSelectionHTML] = useState([]);
    const [rateCardID, setRateCardID] = useState(0);    
    const [currentRate, setCurrentRate] = useState(0);
    

    let startingRate = 0;

    useEffect(() => {
        document.body.style.background="#F7F7F7";
        document.body.style.backgroundColor="#F7F7F7";
        setTimeout(function(){
            resizeRequestWindow();
        },500);
      }, []);

    function loadRateCardInfo() {
        setRateCardID(parseInt(rate_card_info['rate_card_id']));
        startingRate = parseInt(rate_card_info['starting_rate']);
        setCurrentRate(parseInt(rate_card_info['starting_rate']));
        var main_background = rate_card_info['card_background'];
        if(main_background.length > 0) {
            document.body.style.background = main_background;
        }
        var main_title = rate_card_info['rate_card_title'];
        var main_subtitle = rate_card_info['rate_card_subtitle'];
        var tmp_header = <>
                            <div className="row headline-font" style={{margin:'0px',fontSize:'30px'}}>
                                {main_title}
                            </div>
                            <div className="row" style={{margin:'0px'}}>
                                {main_subtitle}
                            </div>
                            <div className="rounded-section" style={{border:'1px solid #EBEBEB',padding:'5px 7px',position:'absolute',top:'20px',right:'25px'}}>
                                Instagram
                            </div>
                        </>;
        setHeaderHTML(tmp_header);
        loadRateCardOptions();
    }

    function loadRateCardOptions() {
        
        var final_options = new Array();
        for(var i = 0;i < rate_card_info['rate_card_options'].length;i++) {
            var option_id = rate_card_info['rate_card_options'][i]['rate_card_option_id'];
            var option_network = rate_card_info['rate_card_options'][i]['option_network'];
            var option_title = rate_card_info['rate_card_options'][i]['option_title'];
            var option_rate = rate_card_info['rate_card_options'][i]['option_rate'];
            var tmp_option = <div key={i} className="col s12 m6" style={{padding:'10px'}}>
                            <div className="row rounded-section white z-depth-1" style={{margin:'0px',padding:"10px 15px",borderRadius:'5px'}}>
                                <span id={"ig-static-post-"+option_id} data-optionid={option_id} data-optiontitle={option_title} data-optionrate={option_rate} 
                                    className="left material-icons rate-option" style={{marginRight:'10px',marginTop:'5px',fontSize:'16px',cursor:'pointer'}} 
                                    onClick={(e)=>toggleOptionSelect(e)}>add</span>
                                <span className="left" style={{marginRight:'15px',borderRight:'1px solid #EBEBEB',width:'1px',height:'25px'}}></span>
                                <span className="left">{option_title}</span>
                            </div>
                        </div>
            final_options.push(tmp_option);
        }

        setOptionHTML(final_options);
    }

    function toggleOptionSelect(e) {
        e.preventDefault();
        var option_id = e.target.dataset.optionid;
        var option_title = e.target.dataset.optiontitle;
        if($("#ig-static-post-"+option_id).html() == 'add') {
            $("#ig-static-post-"+option_id).html('check');
            $("#ig-static-post-"+option_id).addClass('green-text');
        } else {
            $("#ig-static-post-"+option_id).html('add');
            $("#ig-static-post-"+option_id).removeClass('green-text');
        }
    }


    function resizeRequestWindow() {
        var newheight = $("#collab-request-section").height();
        var otherheight = $("#collab-request-summary").outerHeight();
        if(newheight > otherheight) {
            $("#collab-request-summary").css({'min-height':newheight+'px'});
        } else {
            $("#collab-request-section").css({'min-height':otherheight+'px'});
        }
    }

    if(pageSet == false) {
        setPage(true);
        setTimeout(function(){
            loadRateCardInfo();
        },50);
    }

    return (<>
    <GlobalHeader />
    <div className="row" style={{padding:'0px 20px',margin:'0px',minHeight:'100vh'}}>
        <div className="col s12">
            <div className="row subheadline-font" style={{position:'relative',padding:'0px',margin:'0px',fontSize:'16px',marginTop:'20px'}}>
                <div className="col s12 black-text" style={{padding:'0px',marginTop:'0px'}}>
                    <div className="row rounded-section white z-depth-1" style={{position:'relative',margin:'0px',padding:"15px 30px",minHeight:'150px',marginBottom:'20px'}}>
                        <div className="col s12 l4 black-text" style={{padding:'10px'}}>
                            <div className="row headline-font" style={{position:'relative',margin:'0px',padding:"0px 15px",fontSize:'40px'}}>
                                Your Rate Card
                            </div>
                            <div className="row" style={{position:'relative',margin:'0px',padding:"0px 15px"}}>
                                Sample Text
                            </div>
                        </div>
                        <div className="col s12 l8 black-text" style={{padding:'10px'}}>
                            <div className="row rounded-section white z-depth-1" style={{position:'relative',margin:'0px',padding:"15px 30px",minHeight:'150px',marginBottom:'20px',marginTop:'10px'}}>
                                {headerHTML}
                                <div className="rounded-section" style={{position:'absolute',bottom:'0px',right:'30px'}}>
                                    <a href="/" className="white-text"><img src="./assets/global/shake_logo_black_tmp.png" height="35px"/></a>
                                </div>
                            </div>
                            <div id="collab-request-section" className="row rounded-section white z-depth-1" style={{margin:'0px',padding:"0px",minHeight:'65vh'}}>
                                <div id="base-collab-form" className="col s12" style={{margin:'0px',padding:"20px 30px"}}>
                                    <div className="row" style={{fontSize:'30px',margin:'0px'}}>
                                        <span className="left headline-font">Let's Collaborate</span>
                                        <span className="right" 
                                            style={{fontSize:'16px',marginTop:'20px',cursor:'pointer'}} >
                                                View Info Form</span>
                                    </div>
                                    <div className="section-divider"></div>
                                    <div className="row" style={{margin:'0px'}}>
                                        Use the form below to select which collaboration options you're looking to work on. This request form will 
                                        be the starting point from which the collaboration is based on.
                                    </div>
                                    <div className="row" style={{marginTop:'20px'}}>
                                        {optionHTML}
                                    </div>
                                </div>
                                <div id="finish-collab-form" className="col s12" style={{margin:'0px',padding:"20px 30px",display:'none'}}>
                                    <div className="row" style={{fontSize:'30px',margin:'0px'}}>
                                        <span className="left headline-font">Finish Your Request</span>
                                        <span className="right" 
                                            style={{fontSize:'16px',marginTop:'20px',cursor:'pointer'}} >
                                                Back to Selection</span>
                                    </div>
                                    <div className="section-divider"></div>
                                    <div className="row" style={{margin:'0px'}}>
                                        Explain your collaboration request in more details including your brand or client's brand and any information about 
                                        the collaboration requirements, timelines and budget.
                                    </div>
                                    <div className="row" style={{marginTop:'10px'}}>
                                        <div className="col s12 m6" style={{padding:'10px'}}>
                                            <div className="row" style={{margin:'0px',padding:"5px"}}>
                                                Your Name*
                                            </div>
                                            <div className="row" style={{margin:'0px'}}>
                                                <input type="text" id="request-name" className="browser-default rounded-input z-depth-1"/>
                                            </div>
                                        </div>
                                        <div className="col s12 m6" style={{padding:'10px'}}>
                                            <div className="row" style={{margin:'0px',padding:"5px"}}>
                                                Your Email*
                                            </div>
                                            <div className="row" style={{margin:'0px'}}>
                                                <input type="email" id="request-email" className="browser-default rounded-input z-depth-1"/>
                                            </div>
                                        </div>
                                        <div className="col s12 m6" style={{padding:'10px'}}>
                                            <div className="row" style={{margin:'0px',padding:"5px"}}>
                                                Brand/Client Name
                                            </div>
                                            <div className="row" style={{margin:'0px'}}>
                                                <input type="text" id="request-brand" className="browser-default rounded-input z-depth-1"/>
                                            </div>
                                        </div>
                                        <div className="col s12 m6" style={{padding:'10px'}}>
                                            <div className="row" style={{margin:'0px',padding:"5px"}}>
                                                Brand/Client Website*
                                            </div>
                                            <div className="row" style={{margin:'0px'}}>
                                                <input type="text" id="request-website" className="browser-default rounded-input z-depth-1"/>
                                            </div>
                                        </div>
                                        <div className="col s12" style={{padding:'10px'}}>
                                            <div className="row" style={{margin:'0px',padding:"5px"}}>
                                                Additional Notes, Dates and Project Scope*
                                            </div>
                                            <div className="row" style={{margin:'0px'}}>
                                                <textarea id="request-notes" className="browser-default rounded-input z-depth-1" style={{minHeight:'150px'}}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="completed-collab-form" className="col s12" style={{margin:'0px',padding:"20px 30px",display:'none'}}>
                                    <div className="row" style={{fontSize:'30px',margin:'0px'}}>
                                        <span className="left headline-font">Request Submitted</span>
                                        <span className="right" 
                                            style={{fontSize:'16px',marginTop:'20px',cursor:'pointer'}} >
                                                Back to Options</span>
                                    </div>
                                    <div className="section-divider"></div>
                                    <div className="row" style={{margin:'0px',marginTop:'20px',fontSize:'16px'}}>
                                        You're Collaboration Request had been submitted! We'll get back to you as soon as we can.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div></>
    );
};