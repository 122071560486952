import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import {axiosInstance, closeAllModals, dateSlashToDash, dateDashToSlash, dateDaysBetween, randomNumberString} from "../../utils";
import '../../stylesheets/payments.css';

let invoice_info = [];
let last_invoice_num = 0;

const InvoiceCreator = (props) => {    
    
    const { memberID, orgID } = useSelector((state) => state.login);

    const [pageSet, setPage] = useState(false);
    const [headerHTML, setHeaderHTML] = useState(null);
    const [linksHTML, setLinksHTML] = useState(null);
    const [optionHTML, setOptionHTML] = useState(null);
    const [clientSelectHTML, setClientSelectHTML] = useState([]);
    const [orgSelectHTML, setOrgSelectHTML] = useState([]);
    const [currencySelectHTML, setCurrencySelectHTML] = useState([]);
    const [currency_symbol,setCurrencySymbol] = useState('$');

    let startingRate = 0;

    useEffect(() => {
        document.body.style.backgroundColor="#F7F7F7";
        showDatePickers();
    }, []);

    function showSelectForms() {
        var select_elems = document.querySelectorAll('select');
        var instances = window.M.FormSelect.init(select_elems);
    }

    function showDatePickers() {
        var date_elems = document.querySelectorAll('.datepicker');
        var instances = window.M.Datepicker.init(date_elems,{
            format: 'mm/dd/yyyy',
            onClose: function() {
                var input_id = $(this)[0]['el'].id;
                if(input_id == 'invoice-date-start') {
                    updateDatesNet('startdate');
                } else if(input_id == 'invoice-date-due') {
                    updateDatesNet('enddate');
                }
            }
        });
    }

    function updateDatesNet(updatesource) {
        var net_days = $("#invoice-net-days").val();
        var date_start = $("#invoice-date-start").val();
        var date_due = $("#invoice-date-due").val();

        if(net_days > 365) {
            net_days = net_days.substring(0,3);
            $("#invoice-net-days").val(net_days);
        }  

        if(updatesource == 'startdate') {
            if((date_start.length > 0)&&(date_due.length > 0)) {
                var new_netdays = dateDaysBetween(date_start,date_due,'text');
                $("#invoice-net-days").val(new_netdays);

            } else if((net_days.length > 0)&&(date_start.length > 0)) {
                var new_enddate = dateAddSubtract(date_start,'text','add',net_days,'text');
                $("#invoice-date-due").val(new_enddate);

                var delem = document.getElementById('invoice-date-due');
                var dinstance = window.M.Datepicker.getInstance(delem);
                dinstance.setDate(new Date(new_enddate));
            }
        } else if(updatesource == 'enddate') {
            if((date_start.length > 0)&&(date_due.length > 0)) {
                var new_netdays = dateDaysBetween(date_start,date_due,'text');
                $("#invoice-net-days").val(new_netdays);

            } else if((net_days.length > 0)&&(date_due.length > 0)) {
                var new_startdate = dateAddSubtract(date_due,'text','sub',net_days,'text');
                $("#invoice-date-start").val(new_startdate);

                var delem = document.getElementById('invoice-date-start');
                var dinstance = window.M.Datepicker.getInstance(delem);
                dinstance.setDate(new Date(new_startdate));
            }
        } else if(updatesource == 'netdays') {
            if((net_days.length > 0)&&(date_start.length > 0)) {
                var new_enddate = dateAddSubtract(date_start,'text','add',net_days,'text');
                $("#invoice-date-due").val(new_enddate);

                var delem = document.getElementById('invoice-date-due');
                var dinstance = window.M.Datepicker.getInstance(delem);
                dinstance.setDate(new Date(new_enddate));

            } else if((net_days.length > 0)&&(date_due.length > 0)) {
                var new_startdate = dateAddSubtract(date_due,'text','sub',net_days,'text');
                $("#invoice-date-start").val(new_startdate);

                var delem = document.getElementById('invoice-date-start');
                var dinstance = window.M.Datepicker.getInstance(delem);
                dinstance.setDate(new Date(new_startdate));
            }
        }
    }

    function dateAddSubtract(datetxt,datetype,datedirection,daysamount,returntype) {
        var currentdate;
        if(datetype == 'text') {
            var datesplit = datetxt.split("/");
            currentdate = new Date(datesplit[2],(datesplit[0]-1),(datesplit[1]));
        } else {
            currentdate = datetxt;
        }
        
        var current_timestamp = currentdate.getTime();
        var next_timestamp = current_timestamp;
        if(datedirection == 'sub') {
            next_timestamp = current_timestamp - (daysamount*(1000 * 3600 * 24));
        } else {
            next_timestamp = current_timestamp + (daysamount*(1000 * 3600 * 24));
        }
        let nextdate = new Date(next_timestamp);

        if(returntype == 'text') {
            var currentmonth = nextdate.getMonth()+1;
            var currentyear = nextdate.getFullYear();
            var currentday = nextdate.getDate();

            var final_date = '';
            if(currentmonth < 10) {
                final_date = final_date + '0';
            }
            final_date = final_date + currentmonth+'/';
            if(currentday < 10) {
                final_date = final_date + '0';
            }
            final_date = final_date + currentday+'/';
            final_date = final_date + currentyear;

            return final_date;
        } else {
            return nextdate;
        }
    }

    function invoiceNumberRandom() {
        var number_length = 6;
        var random_string = randomNumberString(number_length);

        $("#invoice-number-form").val(random_string);
    }

    function invoiceNumberNext() {
        $("#invoice-number-form").val((last_invoice_num+1));
    }

    function saveNewInvoice() {
        var invoice_number = $("#invoice-number-form").val();
        var invoice_date_start = dateSlashToDash($("#invoice-date-start").val());
        var invoice_date_due = dateSlashToDash($("#invoice-date-due").val());
        var invoice_net_days = $("#invoice-net-days").val();
        var invoice_support_email = $("#invoice-support-email").val();

        var client_id = $("#invoice-client-id").val();
        var client_name = $("#invoice-client-name").val();
        var client_email = $("#invoice-client-email").val();
        var client_company = $("#invoice-client-company").val();
        var client_website = $("#invoice-client-website").val();

        if((invoice_number.length == 0)||(invoice_number == 0)) {
            invoice_number = last_invoice_num+1;
        }

        var addData = new Object();
        addData.id = memberID;
        addData.orgID = orgID;

        addData.invoiceNumber = invoice_number;
        addData.dateStart = invoice_date_start;
        addData.dateDue = invoice_date_due;
        addData.dueTerms = invoice_net_days;
        addData.supportEmail = invoice_support_email;

        addData.clientID = client_id;
        addData.clientName = client_name;
        addData.clientEmail = client_email;
        addData.clientCompany = client_company;
        addData.clientWebsite = client_website;

        if((client_name.length == 0)&&(client_company.length == 0)) {
            alert('Add Company or Contact Name For Invoice');
        } else {
            $("#save-new-button").hide();
            $("#save-new-loading").show();
            axiosInstance.post('payments/invoice_add/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var invoice_details = result.data; 
                    let invoiceid = invoice_details[0]['invoice_unique_id'];
                    if(invoice_details.length > 0) {
                        props.nextStepLink(invoiceid);
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function getLastInvoiceInfo() {
        var addData = new Object();
        addData.id = encodeURIComponent(memberID);
        addData.invoiceStatus = encodeURIComponent('LAST');
        axiosInstance.post('payments/invoice_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var invoice_list = result.data; 
                last_invoice_num = invoice_list[0]['invoice_alternate_number'];
                invoiceNumberNext();
            }
        }).catch(error => {
            console.log(error);
        });
    }

    if(pageSet == false) {
        setPage(true);
        getLastInvoiceInfo();
    }

    return (
    <div className="row subheadline-font" style={{position:'relative',fontSize:'16px',padding:'0px',margin:'0px',marginTop:'0px'}}>
        <div className="col s12 black-text" style={{padding:'10px'}}>
            <div id="collab-request-section" className="row rounded-section white z-depth-1" style={{margin:'0px',padding:"0px"}}>
                <div id="project-settings-form" className="col s12" style={{margin:'0px',padding:"20px 30px"}}>
                    <div className="row" style={{fontSize:'30px',margin:'0px'}}>
                        <div className="left headline-font">Create New Invoice</div>
                        <div className="right" style={{marginRight:'20px',marginTop:'12px',fontSize:'16px',cursor:'pointer'}} onClick={()=>props.goBackLink()}>Back</div>
                    </div>
                    <div className="section-divider"></div>
                    <div className="row" style={{margin:'0px'}}>
                        &nbsp;
                    </div>
                    <div className="row" style={{marginTop:'10px',marginBottom:'50px',display:'flex',flexDirection:'row'}}>
                        <div className="col s12" style={{flex:'1',padding:'0px 20px'}}>
                            <div className="row headline-font" style={{fontSize:'24px',padding:'0px 10px',marginBottom:'10px'}}>
                                CLIENT DETAILS
                            </div>
                            <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px'}}>
                                <div className="section-divider"></div>
                            </div>
                            <div className="row">
                                <div className="col s12" style={{padding:'10px 20px'}}>
                                    <div className="row" style={{marginBottom:'10px'}}>
                                        <div className="col s12 l6">
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                Company Name
                                            </div>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                <input type="text" id="invoice-client-company"
                                                className="left browser-default rounded-input z-depth-1"
                                                style={{width:"98%",marginRight:'2%'}} />
                                            </div>
                                        </div>
                                        <div className="col s12 l6">
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                Contact Full Name
                                            </div>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                <input type="text" id="invoice-client-name"
                                                className="left browser-default rounded-input z-depth-1"
                                                style={{width:"98%",marginRight:'2%'}} />
                                            </div>
                                        </div>
                                        <div className="col s12 l6" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                Contact Email Address (optional)
                                            </div>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                <input type="text" id="invoice-client-email"
                                                className="left browser-default rounded-input z-depth-1"
                                                style={{width:"98%",marginRight:'2%'}} />
                                            </div>
                                        </div>
                                        <div className="col s12 l6" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                Contact Website (optional)
                                            </div>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                <input type="text" id="invoice-client-website"
                                                className="left browser-default rounded-input z-depth-1"
                                                style={{width:"98%",marginRight:'2%'}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row headline-font" style={{fontSize:'24px',padding:'0px 10px',marginBottom:'10px'}}>
                                INVOICE DETAILS
                            </div>
                            <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px'}}>
                                <div className="section-divider"></div>
                            </div>
                            <div className="row">
                                <div className="col s12" style={{padding:'10px 20px'}}>
                                    <div className="row" style={{marginBottom:'20px'}}>
                                        
                                        <div className="col s12 m3">
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                Invoice Start Date
                                            </div>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                <input type="text" id="invoice-date-start"
                                                className="left browser-default datepicker rounded-input z-depth-1"
                                                style={{width:"98%",marginRight:'2%'}}  />
                                            </div>
                                        </div>
                                        <div className="col s12 m3">
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                Net (Days)
                                            </div>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                <input type="text" id="invoice-net-days"
                                                className="left browser-default rounded-input z-depth-1"
                                                style={{width:"98%",marginRight:'2%'}}
                                                onChange={()=>updateDatesNet('netdays')} />
                                            </div>
                                        </div>
                                        <div className="col s12 m3">
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                Invoice Due Date
                                            </div>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                <input type="text" id="invoice-date-due"
                                                className="left browser-default datepicker rounded-input z-depth-1"
                                                style={{width:"98%",marginRight:'2%'}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'10px'}}>
                                        <div className="col s12 l6">
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                <span className="left"></span>Invoice Number
                                                <span className="right black-black-link" onClick={()=>invoiceNumberRandom()}  style={{marginRight:'20px',fontSize:'14px'}}>
                                                    Randomize
                                                </span>
                                                <span className="right" style={{marginRight:'10px',marginLeft:'10px',fontSize:'14px'}}>|</span>
                                                <span className="right black-black-link" onClick={()=>invoiceNumberNext()}  style={{fontSize:'14px'}}>
                                                    Next Number
                                                </span>
                                            </div>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                <input type="text" id="invoice-number-form"
                                                className="left browser-default rounded-input z-depth-1"
                                                style={{width:"98%",marginRight:'2%'}} />
                                            </div>
                                        </div>
                                        <div className="col s12 l6" style={{display:'none'}}>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                Invoice Support Email (optional)
                                            </div>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                <input type="text" id="invoice-support-email"
                                                className="left browser-default rounded-input z-depth-1"
                                                style={{width:"98%",marginRight:'2%'}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div id="save-new-divider" className="section-divider"></div>
                </div>
                <div className="row center-align" style={{fontSize:'30px',marginBottom:'0px',padding:'20px 30px'}}>
                    <div id="save-new-button" className="waves-effect waves-light white-text z-depth-2" 
                            style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD',marginRight:'20px'}} 
                            onClick={()=>saveNewInvoice()} >
                            Save & Continue
                    </div>
                    <div id="save-new-loading" style={{display:'none'}}>
                        <div class="preloader-wrapper small active">
                            <div class="spinner-layer spinner-blue-only">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div><div class="gap-patch">
                                <div class="circle"></div>
                            </div><div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row center-align" style={{fontSize:'30px',marginBottom:'20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
    );
};

export default InvoiceCreator;