import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import {axiosInstance, closeAllModals, dateSlashToDash} from "../../utils";
import '../../stylesheets/payments.css';

const InvoicePaidBox = (props) => {    
    const { memberID, orgID } = useSelector((state) => state.login);
    const [pageSet, setPage] = useState(false);

    useEffect(() => {
        document.body.style.backgroundColor="#F7F7F7";
        loadMaterializeForms();
    }, []);

    function markInvoicePaidExternal() {
        var payment_method = $("#invoice-payment-method").val();
        var payment_date = $("#invoice-payment-date").val();
        var payment_date_text = dateSlashToDash(payment_date);

        var addData = new Object();
        addData.invoiceUniqID = encodeURIComponent(props.invoiceID);
        addData.orgID = encodeURIComponent(props.orgID);
        addData.paymentMethod = encodeURIComponent(payment_method);
        addData.paymentTime = payment_date_text;
        addData.paymentAmount = encodeURIComponent(props.paymentAmount);
        addData.paymentCurrency = encodeURIComponent(props.paymentCurrency);

        $("#mark-paid-button").hide();
        $("#mark-paid-loading").show();

        axiosInstance.post('payments/complete_payment_external/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                props.pageUpdate();
                setTimeout(function(){
                    $("#mark-paid-button").show();
                    $("#mark-paid-loading").hide();
                    $("#mark-paid-form").hide();
                    $("#mark-paid-confirmed").show();
                },2000);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function loadMaterializeForms() {
        var elems = document.getElementById('mark-paid-modal');
        var instances = window.M.Modal.init(elems);

        var select_elems = document.querySelectorAll('select');
        var instances2 = window.M.FormSelect.init(select_elems);

        var body_elem = document.getElementsByTagName('body');
        var date_elems = document.getElementById('invoice-payment-date');
        var instances3 = window.M.Datepicker.init(date_elems,{
            format: 'mm/dd/yyyy',
            container: body_elem,

        });
        instances3.setDate(new Date());
    }

    if(pageSet == false) {
        setPage(true);
    }

    return (<div id="mark-paid-modal" className="modal page-modal-medium">
                <div className="row" style={{margin:'0px'}}>
                    <div id="mark-paid-form" className="col s10 offset-s1 center-align">
                        <div className="row headline-font" style={{fontSize:'40px',marginTop:'30px'}}>
                            MARK INVOICE PAID
                        </div>
                        <div className="row">
                            <div className="col s12 m10 offset-m1">
                            Close this invoice and mark it as paid. Choose the payment method used below to categorize for your records how the payment was made outside of Shake.
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12" style={{padding:'10px'}}>
                                <div className="row" style={{margin:'0px'}}>
                                    <div className="col s12 m8 offset-m2 l6 offset-l3" style={{padding:'0px'}}>
                                        <div className="row center-align" style={{margin:'0px',padding:"5px"}}>
                                            Invoice Paid With:
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{margin:'0px'}}>
                                    <div className="col s12 m8 offset-m2 l6 offset-l3" style={{padding:'0px 5px',marginBottom:'20px'}}>
                                        <select id="invoice-payment-method"  
                                                className="browser-default rounded-input z-depth-1 in-row-select-light center-align"
                                                style={{width:"100%",color:'black',height:'45px',marginTop:'0px'}}>
                                            <option value="wire">Bank Wire or Transfer</option>
                                            <option value="check">Check</option>
                                            <option value="app">Payment App (Paypal, Zelle, Venmo, etc)</option>
                                            <option value="cash">Cash</option>
                                            <option value="crypto">Cryptocurrency</option>
                                            <option value="credit">Credit Card (Outside of Shake)</option>
                                            <option value="other">Other Method</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row" style={{margin:'0px'}}>
                                    <div className="col s12 m8 offset-m2 l6 offset-l3" style={{padding:'0px'}}>
                                        <div className="row center-align" style={{margin:'0px',padding:"5px"}}>
                                            Invoice Paid Date:
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{margin:'0px'}}>
                                    <div className="col s12 m8 offset-m2 l6 offset-l3" style={{padding:'0px 5px',marginBottom:'20px'}}>
                                        <input type="text" id="invoice-payment-date"
                                                    className="left browser-default datepicker rounded-input z-depth-1 center-align"
                                                    style={{width:"100%"}} />
                                    </div>
                                </div>
                                <div className="row" style={{margin:'0px'}}>

                                
                                    <div className="col s12 m8 offset-m2 l6 offset-l3 center-align" style={{padding:'0px 5px',marginBottom:'20px'}}>
                                        <div id="mark-paid-button" className="waves-effect waves-light white-text z-depth-2" 
                                            style={{background:'#132C51',width:'100%',borderRadius:'10px',fontSize:'14px',padding:'10px 15px',cursor:'pointer',border:'1px solid #132C51'}}
                                            onClick={()=>markInvoicePaidExternal()}>
                                                SUBMIT
                                        </div>
                                        <div id="mark-paid-loading" className="center-align" style={{display:'none'}}>
                                            <div className="preloader-wrapper small active" style={{marginTop:'4px'}}>
                                                <div className="spinner-layer spinner-blue-only">
                                                <div className="circle-clipper left">
                                                    <div className="circle"></div>
                                                </div><div className="gap-patch">
                                                    <div className="circle"></div>
                                                </div><div className="circle-clipper right">
                                                    <div className="circle"></div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="mark-paid-confirmed" className="col s10 offset-s1 center-align" style={{display:'none',marginBottom:'20px',minHeight:'40vh'}}>
                        <div className="row headline-font" style={{fontSize:'40px',marginTop:'30px'}}>
                            INVOICE PAID 🎉
                        </div>
                        <div className="row">
                            <div className="col s12 m10 offset-m1">
                            This invoice has now been closed and marked as paid. You can find all of your invoices in the Payments Manager.
                            </div>
                        </div>
                        <div className="row" style={{margin:'0px'}}>
                            <div className="col s12 m8 offset-m2 l6 offset-l3" style={{padding:'0px 5px',marginTop:'50px',marginBottom:'20px'}}>
                                <div className="waves-effect waves-light white-text z-depth-2" 
                                    style={{background:'#132C51',width:'100%',borderRadius:'10px',fontSize:'14px',padding:'10px 15px',cursor:'pointer',border:'1px solid #132C51'}}
                                    onClick={()=>closeAllModals()}>
                                        CLOSE
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default InvoicePaidBox;