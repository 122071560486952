import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback} from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import {axiosInstance, closeAllModals} from "../../utils";
import {InvoiceViewer} from "../../components";
import { useParams } from 'react-router-dom';

let card_info = []
export const InvoicePublic = () => {   
    let props = useParams(); 
    const { memberID, orgID } = useSelector((state) => state.login);

    const [pageSet, setPage] = useState(false);
    const [headerHTML, setHeaderHTML] = useState(null);
    const [invoiceHTML, setInvoiceHTML] = useState(null);
    let startingRate = 0;

    function showInvoiceView() {
        let invoiceid = '';
        if(props.invoiceID) {
            invoiceid = props.invoiceID;
        }

        if(invoiceid.length > 0) {
            setInvoiceHTML(<InvoiceViewer invoiceID={invoiceid} goBackLink={goBack} publicPage={1} />)
        } else {
            var tmp_noinvoice = <div className="col s12 black-text" style={{padding:'10px',marginTop:'0px'}}>
                                    <div className="row rounded-section white z-depth-1" style={{position:'relative',margin:'0px',padding:"15px 30px",minHeight:'90vh'}}>
                                        Invoice Not Found
                                    </div>
                                </div>
            setInvoiceHTML(tmp_noinvoice);
        }
        
    }

    function goBack() {
        console.log('Go Back');
    }

    useEffect(() => {
        document.body.style.backgroundColor="#F7F7F7";
      }, []);

    if(pageSet == false) {
        setPage(true);
        showInvoiceView();
    }

    return (
    <div className="row" style={{margin:'0px',minHeight:'100vh'}}>
        <div className="col s12 m10 offset-m1" style={{padding:'0px'}}>
            <div className="row subheadline-font" style={{position:'relative',fontSize:'16px',padding:'0px',margin:'0px',marginTop:'5px'}}>
                {invoiceHTML}
            </div>
        </div>
    </div>
    );
};