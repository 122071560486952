import React, { useEffect, useState } from "react";
import {axiosInstance, closeAllModals, validateEmailText, timestampToText} from "../../utils";
import {
  PaymentElement,
  AddressElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import $ from "jquery";

const StripeCheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const paymentElementOptions = {
        layout: "accordion",
        requestPayerName: true,
        requestPayerEmail: true
    }

    const paymentAddressOptions = {
      mode: 'billing',
      
    }

    useEffect(() => {
        
    }, []);

    useEffect(() => {
      if (!stripe) {
        return;
      }
      const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
      loadPaymentStatus(clientSecret) 
        
    }, [stripe]);

    function loadPaymentStatus(clientSecret) {
      if (!clientSecret) {
        return;
      }

      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
    }

    const handleStripeSubmit = async (e) => {
        e.preventDefault();
    
        if (!stripe || !elements) {
          return;
        }

        var billingName = $("#billing_name").val();
        var billingEmail =  $("#billing_email").val();
        
        if(billingName.length < 5) {
          setMessage("Add Billing Name");  
        } else if(validateEmailText(billingEmail) == 'fail') {
          setMessage("Add Billing Email Address");  
        } else {
          setIsLoading(true);
          const payment_result = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: window.location.href,
            },
            redirect:'if_required'
          });

          if(payment_result['error'] != undefined) {
            if (payment_result['error']['type'] === "card_error" || payment_result['error']['type'] === "validation_error") {
              setMessage(payment_result['error']['message']);
            } else {
              setMessage("An unexpected error occurred. Try again in a little bit.");
            }
            setIsLoading(false);

          } else if(payment_result['paymentIntent']['status'] == 'succeeded') {
            let payment_id = payment_result['paymentIntent']['id'];
            let payment_method = payment_result['paymentIntent']['payment_method'];
            let payment_timestamp = payment_result['paymentIntent']['created'];
            saveStripePayment(payment_method,payment_id,payment_timestamp);

            // setTimeout(function() {
            //   setMessage("Payment Succeeded!");  
            // },3000);

            // setTimeout(function() {
            //   //setIsLoading(false);
            //   var billingEmail2 =  $("#billing_email").val();
            //   props.paymentSuccess(billingEmail2);
            // },3000);

          } else {
            setIsLoading(false);
          }

        }
        
    };

    function saveStripePayment(payment_method,payment_id,payment_timestamp) {

      var billingName = $("#billing_name").val();
      var billingEmail =  $("#billing_email").val();
      var payment_date_text = timestampToText(payment_timestamp,1000);

      var addData = new Object();
      addData.invoiceUniqID = encodeURIComponent(props.invoiceID);
      addData.orgID = encodeURIComponent(props.orgID);
      addData.paymentSource = 'stripe';
      addData.paymentMethod = encodeURIComponent(payment_method);
      addData.paymentID = encodeURIComponent(payment_id);
      addData.paymentTime = payment_date_text;
      addData.paymentAmount = encodeURIComponent(props.paymentAmount);
      addData.paymentCurrency = encodeURIComponent(props.paymentCurrency);
      addData.billingName = encodeURIComponent(billingName);
      addData.billingEmail = encodeURIComponent(billingEmail);

      axiosInstance.post('payments/complete_payment/',addData).then(result => {
          if(Object.keys(result.data).length > 0) {
            // setTimeout(function() {
            //   setMessage("Payment Succeeded!");  
            // },3000);
  
            setTimeout(function() {
              //setIsLoading(false);
              var billingEmail2 =  $("#billing_email").val();
              props.paymentSuccess(billingEmail2);
            },2000);
          }
      }).catch(error => {
          console.log(error);
      });
    }

    return (
        <div id="checkout">
            <form id="payment-form" className="stripeform" onSubmit={handleStripeSubmit}>
              <div className="row">
                  <div className="col s12 m6" style={{marginBottom:'10px'}}>
                      <label className="browser-default p-FieldLabel Label Label--empty" for="Field-nameInput">Billing Name</label>
                      <div>
                        <input id="billing_name" 
                                type="text" 
                                placeholder="Name" 
                                aria-invalid="false" 
                                aria-required="true" 
                                className="browser-default p-Input-input Input Input--empty" 
                                defaultValue=""
                                style={{width:'100%'}} />
                      </div>
                  </div>
                  <div className="col s12 m6" style={{marginBottom:'10px'}}>
                      <label className="browser-default p-FieldLabel Label Label--empty" for="billing_email">Billing Email</label>
                      <div>
                        <input id="billing_email" 
                                type="text" 
                                placeholder="Email" 
                                aria-invalid="false" 
                                aria-required="true" 
                                className="browser-default p-Input-input Input Input--empty" 
                                defaultValue=""
                                style={{width:'100%'}} />
                      </div>
                  </div>
                </div>
                <PaymentElement id="payment-element" options={paymentElementOptions} />
                <button disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">
                    {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                </span>
                </button>
                {message && <div id="payment-message">{message}</div>}
            </form>
        </div>
    );
}

export default StripeCheckoutForm;