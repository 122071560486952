import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import {axiosInstance, closeAllModals, validateEmailText } from "../../../../utils";
import {SignupForm, LoginForm} from "../../../../components";

export const SignupSplash = (props) => {    
    
    const { memberID, orgID } = useSelector((state) => state.login);

    const [pageSet, setPage] = useState(false);
    const [headerHTML, setHeaderHTML] = useState(null);
    const [sectionHTML, setSectionHTML] = useState(null);

    let startingRate = 0;

    useEffect(() => {
        document.body.style.backgroundColor="#F7F7F7";
    }, []);

    function switchForms(formname) {
        if (formname == 'login') {
            setSectionHTML(<LoginForm completedStep={goToNextStep} changeForm={switchForms} />);
        } else {
            setSectionHTML(<SignupForm completedStep={goToNextStep} changeForm={switchForms} />);
        }
    }

    function goToNextStep() {
        props.completedStep(1);
    }

    if(pageSet == false) {
        setPage(true);
        if(window.location.href.indexOf('login') >= 0) {
            switchForms('login');
        } else {
            switchForms('signup');
        }
    }

    return (
    <div className="row subheadline-font" style={{position:'relative',fontSize:'16px',padding:'0px',margin:'0px',marginTop:'0px'}}>
        <div className="col s12 black-text" style={{padding:'10px'}}>
            <div className="row rounded-section white z-depth-1" style={{margin:'0px',padding:"0px",overflow:'hidden'}}>
                <div className="col s12 m6 light-blue-background" style={{fontSize:'20px',borderRight:'1px solid #EBEBEB',margin:'0px',padding:"20px 40px",minHeight:'470px'}}>
                    <div className="row center-align" style={{fontSize:'36px',margin:'0px'}}>
                        <div className="left headline-font">Signup For Shake</div>
                    </div>
                    <div className="row" style={{margin:'0px',marginTop:'10px',marginBottom:'20px',paddingRight:'50px'}}>
                        Get the creator tools you need to earn more, save time, & look professional
                    </div>
                    <div className="row" style={{margin:'0px',marginTop:'10px',paddingLeft:'10px',paddingRight:'50px'}}>
                        <span className="material-symbols-outlined" style={{verticalAlign:'text-top'}}>check_circle</span>&nbsp;&nbsp;Streamline Your Invoices
                    </div>
                    <div className="row" style={{margin:'0px',marginTop:'10px',paddingLeft:'10px',paddingRight:'50px'}}>
                        <span className="material-symbols-outlined" style={{verticalAlign:'text-top'}}>check_circle</span>&nbsp;&nbsp;Automated Payment Followups and Reminders
                    </div>
                    <div className="row" style={{margin:'0px',marginTop:'10px',paddingLeft:'10px',paddingRight:'50px'}}>
                        <span className="material-symbols-outlined" style={{verticalAlign:'text-top'}}>check_circle</span>&nbsp;&nbsp;Accept Credit Cards, Wire Transfers and Payment Apps From Over 140 Countries
                    </div>
                    <div className="row" style={{margin:'0px',marginTop:'10px',paddingLeft:'10px',paddingRight:'50px'}}>
                        <span className="material-symbols-outlined" style={{verticalAlign:'text-top'}}>check_circle</span>&nbsp;&nbsp;Track Your Revenue & Payments
                    </div>
                </div>
                <div className="col s12 m6" style={{margin:'0px',padding:"20px 30px",minHeight:'400px'}}>
                    {sectionHTML}
                </div>
            </div>
        </div>
    </div>
    );
};