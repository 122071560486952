import React from 'react'

const GlobalHeaderSignup = () => {    

    return (<nav className="transparent z-depth-0" style={{height:'50px'}}>
                <div className="nav-wrapper" style={{padding:'0px 30px 0px 45px'}}>
                    <a href="/" className="brand-logo">
                        <img src="./assets/global/shake_logo_blue.png" height="40px" style={{marginTop:'17px'}} />
                    </a>
                    <ul id="nav-mobile" className="right hide-on-med-and-down black-text" style={{marginTop:'10px',lineHeight:'50px'}}>
                        <li><a href="/#/signup?login" className="black-text">Login</a></li>
                    </ul>
                </div>
            </nav>
    );
};

export default GlobalHeaderSignup;