import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import {GlobalHeader, GlobalHeaderDarkMode, GlobalLeftRail} from '../../components';
import {AccountDetails, OrgDetails, PaymentSettings} from './Sections';
import {axiosInstance, closeAllModals} from "../../utils";
import {clearAccountSignOn} from "../../redux/Login/actions"

let current_table_page = 0;
let current_rows_html = [];
let current_invoice_view = 0;
let primary_currency = '$';
let current_color_scheme = 'light';
let tableStatusText = '';
let pageTextColorClass = 'white-text';
let pageTableColorClass = 'table-row-dark';
let pageBorderColorClass = 'white';
let pageAccentColorClass = '#77E7A5';
let pageGlobalHeader = <GlobalHeaderDarkMode />;
export const AccountSettings = () => {    
    const dispatch = useDispatch();
    const { memberID, orgID } = useSelector((state) => state.login);

    const [pageSet, setPage] = useState(false);
    const [headlineText, setHeadlineText] = useState('');
    const [tableDateText, setTableDateText] = useState('');
    const [tableRowsHTML, setTableRowsHTML] = useState([]);
    const [payment_section_total, setPaymentSectionTotal] = useState(0);    
    const [currentSettingsView,setCurrentSettingsView] = useState('');
    const user_member_id = 1;
    const user_org_id = 1;
    let startingRate = 0;

    
    if(current_color_scheme == 'light') {
        pageBorderColorClass = '#132C51';
        pageAccentColorClass = '#77E7A5';
        pageGlobalHeader = <GlobalHeader />;
    } else {
        pageBorderColorClass = 'white';
        pageAccentColorClass = '#77E7A5';
        pageGlobalHeader = <GlobalHeaderDarkMode />;
    }
    
    useEffect(() => {
        if(current_color_scheme == 'light') {
            document.body.style.background="#FCF5EC";
            document.body.style.backgroundColor="#FCF5EC";
            document.body.style.background="radial-gradient(#FFF7F7,#FCF5EC)";
            document.body.style.background="radial-gradient(#FFFFFF,#F0EDE9)";

            pageTextColorClass = 'navy-text';
            pageTableColorClass = 'table-row-light';
            $(".white-text").each(function(){
                $(this).removeClass("white-text").addClass("navy-text");
            });
            $(".table-row-dark").each(function(){
                $(this).removeClass("table-row-dark").addClass("table-row-light");
            });
        } else {
            document.body.style.background="radial-gradient(#4360CD, #132C51)";
            pageTextColorClass = 'white-text';
            pageTableColorClass = 'table-row-dark';
            $(".navy-text").each(function(){
                $(this).removeClass("navy-text").addClass("white-text");
            });
            $(".table-row-light").each(function(){
                $(this).removeClass("table-row-light").addClass("table-row-dark");
            });
        } 
      }, []);

    function changePageSection(pagename) {
        $(".section-left-rail-button").each(function(){
            $(this).removeClass("section-left-rail-button-selected");
        });
        $("#"+pagename+"_button").addClass("section-left-rail-button-selected");
        if(pagename == 'account_details') {
            setCurrentSettingsView(<AccountDetails />);
        } else if(pagename == 'organizations') {
            setCurrentSettingsView(<OrgDetails />);
        } else if(pagename == 'payment_settings') {
            setCurrentSettingsView(<PaymentSettings />);
        } else if(pagename == 'logout') {
            logoutAccount();
        }
    }

    function logoutAccount() {
        if(window.confirm('Are You Sure You Want To Logout?')) {
            dispatch(clearAccountSignOn());
            window.location.href="/";
        }
    }

    if(pageSet == false) {
        setPage(true);
        setTimeout(function(){
            changePageSection('account_details');
        },50);
        
    }

    return (<>
    {pageGlobalHeader}
    <div className="row" style={{padding:'0px 20px',margin:'0px',minHeight:'95vh'}}>
        <div className="col s12">
            <div className="row" style={{position:'relative',padding:'0px',margin:'0px',fontSize:'16px',marginTop:'20px',fontFamily:'helvetica'}}>
                <div className="col s12 m12 l3 black-text" style={{padding:'0px',marginTop:'0px'}}>
                    <div className="row transparent" style={{position:'relative',margin:'0px',padding:"0px",marginBottom:'0px',minHeight:'80vh',width:'90%',borderRight:'1px solid '+pageBorderColorClass}}>
                        <div className="col s12 white-text headline-font" style={{position:'relative',margin:'0px',padding:"10px 20px 10px 20px",fontSize:'30px'}}>
                            Account Settings
                        </div>
                        <div className="col s12 white-text" style={{position:'relative',margin:'0px',padding:"0px 20px",fontSize:'14px'}}>
                            Update your account, organization and payment details.
                        </div>
                        <div className="col s12 subheadline-font white-text" style={{padding:'0px',marginTop:'40px',width:'80%'}}>
                            <div id="account_details_button" className="section-left-rail-button section-left-rail-button-selected" onClick={()=>changePageSection('account_details')}>
                                Account Details
                            </div>
                            <div id="organizations_button" className="section-left-rail-button" onClick={()=>changePageSection('organizations')}>
                                Organizations
                            </div>
                            <div id="payment_settings_button" className="section-left-rail-button" onClick={()=>changePageSection('payment_settings')}>
                                Payment Settings
                            </div>
                            <div id="logout_button" className="section-left-rail-button" onClick={()=>changePageSection('logout')}>
                                Logout
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col s12 m12 l9 black-text" style={{padding:'0px',paddingRight:'10px',marginTop:'0px'}}>
                    {currentSettingsView}
                </div>
            </div>
        </div>
    </div></>
    );
};