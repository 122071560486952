import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import {axiosInstance, closeAllModals, validateEmailText } from "../../../../utils";

export const AccountDetails = (props) => {    
    
    const { memberID, orgID } = useSelector((state) => state.login);

    const [pageSet, setPage] = useState(false);
    const [headerHTML, setHeaderHTML] = useState(null);
    const [linksHTML, setLinksHTML] = useState(null);
    const [optionHTML, setOptionHTML] = useState(null);
    const [clientSelectHTML, setClientSelectHTML] = useState([]);

    let startingRate = 0;

    useEffect(() => {
        document.body.style.backgroundColor="#F7F7F7";
    }, []);

    function getAccountDetails() {
        var addData = new Object();
        addData.id = memberID;
        addData.orgID = orgID;

        axiosInstance.post('member/member_info/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var member_details = result.data; 
                $("#firstName").val(member_details[0]['first_name']);
                $("#lastName").val(member_details[0]['last_name']);
                $("#memberEmail").val(member_details[0]['email_address']);
                $("#memberCountry").val();
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function saveAccountDetails() {
        var member_first_name = $("#firstName").val();
        var member_last_name = $("#lastName").val();
        var member_email = $("#memberEmail").val();
        var member_country = $("#memberCountry").val();

        var addData = new Object();
        addData.id = memberID;
        addData.email = member_email;
        addData.firstName = member_first_name;
        addData.lastName = member_last_name;
        addData.country = member_country;

        if((member_first_name.length == 0)&&(member_last_name.length == 0)) {
            alert('Add Valid First and Last Name');
        } else if(validateEmailText(member_email) == 'fail') {
            alert('Add Valid Email Address');
        } else {
            $("#save-new-button").hide();
            $("#save-new-loading").show();
            axiosInstance.post('member/member_update/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    setTimeout(function() {
                        $("#save-new-button").show();
                        $("#save-new-loading").hide();
                        getAccountDetails();
                    },2000);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }


    if(pageSet == false) {
        setPage(true);
        getAccountDetails();
    }

    return (
    <div className="row subheadline-font" style={{position:'relative',fontSize:'16px',padding:'0px',margin:'0px',marginTop:'0px'}}>
        <div className="col s12 black-text" style={{padding:'10px'}}>
            <div id="collab-request-section" className="row rounded-section white z-depth-1" style={{margin:'0px',padding:"0px"}}>
                <div id="project-settings-form" className="col s12" style={{margin:'0px',padding:"20px 30px"}}>
                    <div className="row" style={{fontSize:'30px',margin:'0px'}}>
                        <div className="left headline-font">Account Details</div>
                    </div>
                    <div className="section-divider"></div>
                    <div className="row" style={{margin:'0px'}}>
                        &nbsp;
                    </div>
                    <div className="row" style={{marginTop:'10px',marginBottom:'50px',display:'flex',flexDirection:'row'}}>
                        <div className="col s12" style={{flex:'1',padding:'0px 20px'}}>
                            <div className="row">
                                <div className="col s12" style={{padding:'10px 20px'}}>
                                    <div className="row" style={{marginBottom:'10px'}}>
                                        <div className="col s12 l6">
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                First Name
                                            </div>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                <input type="text" id="firstName"
                                                className="left browser-default rounded-input z-depth-1"
                                                style={{width:"98%",marginRight:'2%'}} />
                                            </div>
                                        </div>
                                        <div className="col s12 l6">
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                Last Name
                                            </div>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                <input type="text" id="lastName"
                                                className="left browser-default rounded-input z-depth-1"
                                                style={{width:"98%",marginRight:'2%'}} />
                                            </div>
                                        </div>
                                        <div className="col s12 l6" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                Email Address
                                            </div>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                <input type="text" id="memberEmail"
                                                className="left browser-default rounded-input z-depth-1"
                                                style={{width:"98%",marginRight:'2%'}} />
                                            </div>
                                        </div>
                                        <div className="col s12 l6" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                Country
                                            </div>
                                            <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                <input type="text" id="memberCountry"
                                                className="left browser-default rounded-input z-depth-1"
                                                style={{width:"98%",marginRight:'2%'}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div id="save-new-divider" className="section-divider"></div>
                </div>
                <div className="row center-align" style={{fontSize:'30px',marginBottom:'0px',padding:'20px 30px'}}>
                    <div id="save-new-button" className="waves-effect waves-light white-text z-depth-2" 
                            style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD',marginRight:'20px'}} 
                            onClick={()=>saveAccountDetails()} >
                            Save Account Details
                    </div>
                    <div id="save-new-loading" style={{display:'none'}}>
                        <div class="preloader-wrapper small active">
                            <div class="spinner-layer spinner-blue-only">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div><div class="gap-patch">
                                <div class="circle"></div>
                            </div><div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row center-align" style={{fontSize:'30px',marginBottom:'20px'}}>
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
    );
};