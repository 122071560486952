export * from "./axios_helper"

export function closeAllModals() {
  var modalelems = document.querySelectorAll('.modal');
  for(var i = 0; i < modalelems.length;i++) {
      var modal_instance = window.M.Modal.getInstance(modalelems[i]);
      if(modal_instance) {
          modal_instance.close();
      }
  }
}

export function validateEmailText(emailtext) {
  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (emailtext.match(validRegex)) {
    return 'pass';
  } else {
    return 'fail';
  }
}

export function timestampToText(timestamp,multiplier) {
  var time_date = new Date((timestamp*multiplier));
  var time_date_text = time_date.getUTCFullYear()+'-';
  if(time_date.getUTCMonth() < 10) {
    time_date_text = time_date_text+'0';
  }
  time_date_text = time_date_text+time_date.getUTCMonth()+'-';
  if(time_date.getUTCDate() < 10) {
    time_date_text = time_date_text+'0';
  }
  time_date_text = time_date_text+time_date.getUTCDate()+' ';
  if(time_date.getUTCHours() < 10) {
    time_date_text = time_date_text+'0';
  }
  time_date_text = time_date_text+time_date.getUTCHours()+':';
  if(time_date.getUTCMinutes() < 10) {
    time_date_text = time_date_text+'0';
  }
  time_date_text = time_date_text+time_date.getUTCMinutes()+':';
  if(time_date.getUTCSeconds() < 10) {
    time_date_text = time_date_text+'0';
  }
  time_date_text = time_date_text+time_date.getUTCSeconds();

  return time_date_text;
}

export function dateSlashToDash(datestring) {
  var dashdate = '';
  var datesplit = datestring.split("/");
  if(datesplit.length == 3) {
      dashdate = datesplit[2]+'-'+datesplit[0]+'-'+datesplit[1];
  }

  return dashdate;
}

export function dateDashToSlash(datestring) {
  var slashdate = '';
  var datesplit0 = datestring.split("T");
  var datesplit = datesplit0[0].split("-");
  if(datesplit.length == 3) {
      slashdate = datesplit[1]+'/'+datesplit[2]+'/'+datesplit[0];
  }
  return slashdate;
}

export function dateDaysBetween(start,end,datetype) {
  var startdate;
  var enddate;
  if(datetype == 'text') {
      var datesplit1 = start.split("/");
      var datesplit2 = end.split("/");
      startdate = new Date(datesplit1[2],datesplit1[0]-1,datesplit1[1]);
      enddate = new Date(datesplit2[2],datesplit2[0]-1,datesplit2[1]);
  } else {
      startdate = start;
      enddate = end;
  }

  var date_diff = enddate.getTime() - startdate.getTime();
  var days_diff = Math.round(date_diff / (1000 * 3600 * 24));
  
  return days_diff;
}


export function randomNumberString(length) {
  let result = '';
  const characters = '0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
      var nextnumber = characters.charAt(Math.floor(Math.random() * charactersLength));
      if((nextnumber == '0')&&(counter == 0)) {

      } else {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
      }
  }
  return result;
}