const initialState = {
  login_status: false,
  memberID: (localStorage.getItem('shakeTokenVal')? (localStorage.getItem('shakeTokenVal').indexOf('.') > 0 ? localStorage.getItem('shakeTokenVal').split('.')[0] : 0): 1),
  orgID:  (localStorage.getItem('shakeTokenVal')? (localStorage.getItem('shakeTokenVal').indexOf('.') > 0 ? localStorage.getItem('shakeTokenVal').split('.')[1] : 0): 1),
  loginTime: localStorage.getItem('shakeTokenTime')||'',
  loginToken: localStorage.getItem('shakeTokenID')||'',
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_USER":
      return {...state,login_status: true,};

    case "LOGOUT_USER":
      return {...state,login_status: false,};

    case "SET_ACCOUNT_SIGNON":
        return {
          ...state,
          memberID: action.payload.member_id,
          orgID: action.payload.org_id,
          loginToken: action.payload.token,
          loginTime: action.payload.token_time,
        };

    default:
      return {...state};
  }
};

export default loginReducer;