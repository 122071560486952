import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import {GlobalHeader, GlobalHeaderDarkMode, GlobalLeftRail} from '../../components';
import {InvoiceViewer, InvoiceEditor, InvoiceCreator} from '../../components';
import {PaymentSettings} from '../Settings/Sections';
import {axiosInstance, closeAllModals} from "../../utils";
import payments_json from "./payments_sample.json";

let current_table_page = 0;
let current_rows_html = [];
let current_invoice_view = 0;
let primary_currency = '$';
let current_color_scheme = 'light';
let tableStatusText = '';
let pageTextColorClass = 'white-text';
let pageTableColorClass = 'table-row-dark';
let pageBorderColorClass = 'white';
let pageAccentColorClass = '#77E7A5';
let pageGlobalHeader = <GlobalHeaderDarkMode />;
export const PaymentsManager = () => {    
    const { memberID, orgID } = useSelector((state) => state.login);

    const [pageSet, setPage] = useState(false);
    const [headlineText, setHeadlineText] = useState('');
    const [tableDateText, setTableDateText] = useState('');
    const [tableRowsHTML, setTableRowsHTML] = useState([]);
    const [payment_section_total, setPaymentSectionTotal] = useState(0);    
    const [currentInvoiceView,setCurrentInvoiceView] = useState('');
    const [currentSettingsView, setCurrentSettingsView] = useState('');
    const user_member_id = memberID;
    const user_org_id = orgID;
    let startingRate = 0;

    
    if(current_color_scheme == 'light') {
        pageBorderColorClass = '#132C51';
        pageAccentColorClass = '#77E7A5';
        pageGlobalHeader = <GlobalHeader />;
    } else {
        pageBorderColorClass = 'white';
        pageAccentColorClass = '#77E7A5';
        pageGlobalHeader = <GlobalHeaderDarkMode />;
    }
    
    useEffect(() => {
        if(current_color_scheme == 'light') {
            document.body.style.background="#FCF5EC";
            document.body.style.backgroundColor="#FCF5EC";
            document.body.style.background="radial-gradient(#FFF7F7,#FCF5EC)";
            document.body.style.background="radial-gradient(#FFFFFF,#F0EDE9)";
            

            pageTextColorClass = 'navy-text';
            pageTableColorClass = 'table-row-light';
            $(".white-text").each(function(){
                $(this).removeClass("white-text").addClass("navy-text");
            });
            $(".table-row-dark").each(function(){
                $(this).removeClass("table-row-dark").addClass("table-row-light");
            });
        } else {
            document.body.style.background="radial-gradient(#4360CD, #132C51)";
            pageTextColorClass = 'white-text';
            pageTableColorClass = 'table-row-dark';
            $(".navy-text").each(function(){
                $(this).removeClass("navy-text").addClass("white-text");
            });
            $(".table-row-light").each(function(){
                $(this).removeClass("table-row-light").addClass("table-row-dark");
            });
        } 
      }, []);

    function getInvoiceTable(invoicestatus,tablepage) {
        var addData = new Object();
        addData.id = encodeURIComponent(user_member_id);
        addData.invoiceStatus = encodeURIComponent(invoicestatus);
        addData.page = tablepage;
        axiosInstance.post('payments/invoice_list/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var invoice_list = result.data; 
                loadInvoiceTable(invoice_list,tablepage);
            } else {
                loadInvoiceTable([],tablepage);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function loadInvoiceTable(invoicearr=[],tablepage) {
        
        var startnum = 0;
        if(tablepage > 0) {
            startnum = tableRowsHTML.length;
        }
        var final_rows = new Array();
        var invoice_sum = 0;
        for(var i = 0;i < invoicearr.length;i++) {
            var invoice_id = invoicearr[i]['invoice_id'];
            var invoice_unique_id = invoicearr[i]['invoice_unique_id'];
            var invoice_number_alt = invoicearr[i]['invoice_alternate_number'];
            var invoice_status = invoicearr[i]['invoice_status'];
            var client_name = invoicearr[i]['client_name'];
            var client_website = invoicearr[i]['client_website'];
            var client_email = invoicearr[i]['client_email_address'];
            var client_company = invoicearr[i]['client_company_name'];
            var invoice_due_date_terms = invoicearr[i]['due_date_terms_days'];
            var invoice_date_start = invoicearr[i]['date_invoice_start'];
            var invoice_date_due = invoicearr[i]['date_invoice_due'];
            var start_date_month = invoicearr[i]['invoice_start_month'];
            var due_date_month = invoicearr[i]['invoice_due_month'];
            var start_date_text = invoicearr[i]['invoice_start_monthname'].substring(0,3)+" "+invoicearr[i]['invoice_start_day']+", "+ invoicearr[i]['invoice_start_year'];
            var due_date_text = invoicearr[i]['invoice_due_monthname'].substring(0,3)+" "+invoicearr[i]['invoice_due_day']+", "+ invoicearr[i]['invoice_due_year'];
            var invoice_days_remaining = invoicearr[i]['days_remaining']; 
            var invoice_subtotal = invoicearr[i]['subtotal'];
            var invoice_fees = invoicearr[i]['platform_fees'];
            var invoice_amount_paid = invoicearr[i]['amount_paid'];
            var invoice_total_due = invoicearr[i]['total_due'];
            var invoice_currency = invoicearr[i]['invoice_currency'];
            var invoice_custom_notes = invoicearr[i]['custom_notes'];
            var invoice_items = invoicearr[i]['invoice_items'];

            if(invoice_subtotal == null) {
                invoice_subtotal = 0;
            }

            var invoice_sum = invoice_sum + invoice_subtotal;
            var invoice_status_text = 'Pending';
            if(invoice_status == 'C') {
                invoice_status_text = 'Paid';
            } else if(invoice_status == 'F') {
                invoice_status_text = 'Failed';
            } else if(invoice_status == 'R') {
                invoice_status_text = 'Archived';
            }

            var currency_symbol = '$';
            if(invoice_currency == 'USD') {
                currency_symbol = '$';
            }
            primary_currency = '$';

            var tmp_row = <div key={invoice_unique_id} id={"invoice_"+invoice_unique_id}  
                                className={"col s12 rounded-section z-depth-1 "+pageTableColorClass} onClick={(e)=>openInvoice(e)}>
                                <div className="row" style={{margin:"0px",padding:"20px 0px",fontSize:'14px',marginBottom:'0px'}}>
                                    <div className="col s12 m2 l3">
                                        {i+1+startnum}. {client_company} Contract
                                    </div>
                                    <div className="col s12 m2 l2">
                                        {client_name}
                                    </div>
                                    <div className="col s12 m3 l3">
                                        {due_date_text}
                                    </div>
                                    <div className="col s12 m2 l1 center-align">
                                        {invoice_status_text}
                                    </div>
                                    <div className="col s12 m2 l2 center-align">
                                        {start_date_text}
                                    </div>
                                    <div className="col s12 m2 l1 center-align">
                                        {currency_symbol+invoice_subtotal.toLocaleString('en-US')}
                                    </div>
                                </div>
                            </div>
            final_rows.push(tmp_row);
            
        }

        if((final_rows.length == 0)&&(tablepage == 0)) {
            var borderc = "1px solid "+pageBorderColorClass;
            var tmp_row = <div key={"no_results_available"} id={"no_results_available"}  className={"col s12 rounded-section"}>
                                <div className="row navy-text" style={{margin:"0px",marginTop:'10px',padding:"20px 10px",fontSize:'16px',borderTop:borderc, marginBottom:'0px'}}>
                                    No Invoices Available
                                </div>
                            </div>;
            final_rows.push(tmp_row);
        }
        if (tablepage == 0) {
            setTableRowsHTML(final_rows);
        } else {
            invoice_sum = invoice_sum+payment_section_total;
            if(final_rows.length > 0) {
                var tmp_rows_html = tableRowsHTML.concat(final_rows);
                setTableRowsHTML(tmp_rows_html);
            } else {
                $("#show_more_button").hide();
            }
        }
        
        setPaymentSectionTotal(invoice_sum);

        //showInvoiceEditor(invoice_unique_id);

        //showInvoiceView(invoice_unique_id);
    }


    function loadProjectTableRows(itemarr=[],tablepage) {
        
        var final_arr = payments_json;
        var startnum = 0;
        if(tablepage > 0) {
            startnum = tableRowsHTML.length;
        }
        var final_rows = new Array();
        for(var i = 0;i < final_arr.length;i++) {
            var option_id = final_arr[i]['rate_card_option_id'];
            var tmp_row = <div key={i} id={"invoice_"+i}  
                                className={"col s12 rounded-section z-depth-1 "+pageTableColorClass} onClick={(e)=>openInvoice(e)}>
                                <div className="row" style={{margin:"0px",padding:"20px 0px",fontSize:'14px',marginBottom:'0px'}}>
                                    <div className="col s12 m2 l3">
                                        {i+1+startnum}. Brand Name Social Campaign
                                    </div>
                                    <div className="col s12 m2 l2">
                                        Brand Name
                                    </div>
                                    <div className="col s12 m3 l3">
                                        IG Video, TikTok Video
                                    </div>
                                    <div className="col s12 m2 l1 center-align">
                                        {tableStatusText}
                                    </div>
                                    <div className="col s12 m2 l2 center-align">
                                        12/{i+1+startnum}/2022
                                    </div>
                                    <div className="col s12 m2 l1 center-align">
                                        $1,000
                                    </div>
                                </div>
                            </div>
            final_rows.push(tmp_row);
            
        }
        if (tablepage == 0) {
            setTableRowsHTML(final_rows);
        } else {
            var tmp_rows_html = tableRowsHTML.concat(final_rows);
            setTableRowsHTML(tmp_rows_html);
        }
    }

    function showMoreRows() {
        current_table_page = current_table_page+1;
        loadProjectTableRows([],current_table_page);
    }

    function openInvoice(e) { 
        var parentfound = 0;
        var current_invoice_id = '';
        var current_target = e.target;
        var external_link = 0;
        
        while(parentfound == 0) {
            if('id' in current_target) {
                var element_id = current_target.id;
                if(element_id.indexOf('ext_link_') >= 0) {
                    external_link = 1;
                } else if(element_id.indexOf('invoice_') >= 0) {
                    current_invoice_id = element_id.replace("invoice_","");
                    parentfound = 1;
                }
            }
            if(parentfound == 0) {
                current_target = current_target.parentElement;
            }
            
        }
        if(external_link == 0) {
            showInvoiceView(current_invoice_id);
        }
    }

    function showInvoiceView(invoiceid) {
        setCurrentInvoiceView(<InvoiceViewer invoiceID={invoiceid} goBackLink={showInvoiceRows} editInvoiceLink={showInvoiceEditor} publicPage={0} />)
        $("#invoice_rows").hide();
        $("#invoice_view").show();
        current_invoice_view = 1;
    }

    function showInvoiceEditor(invoiceid) {
        setCurrentInvoiceView(<InvoiceEditor invoiceID={invoiceid} goBackLink={showInvoiceView} />)
        $("#invoice_rows").hide();
        $("#invoice_view").show();
        current_invoice_view = 1;
    }

    function showInvoiceCreator() {
        setCurrentInvoiceView(<InvoiceCreator nextStepLink={showInvoiceEditor} goBackLink={showInvoiceRows} />)
        $("#invoice_rows").hide();
        $("#invoice_view").show();
        current_invoice_view = 1;
    }

    function showInvoiceRows() {
        $("#invoice_rows").show();
        $("#invoice_view").hide();
        current_invoice_view = 0;
        setCurrentInvoiceView('');
    }

    function changePageSection(pagename) {
        current_table_page = 0;
        setPaymentSectionTotal(0);
        $(".section-left-rail-button").each(function(){
            $(this).removeClass("section-left-rail-button-selected");
        });
        $("#"+pagename+"_button").addClass("section-left-rail-button-selected");
        if(pagename == 'pending_invoices') {
            setHeadlineText('Pending Invoices');
            setTableDateText('Created');
            tableStatusText = 'Pending';
            getInvoiceTable('P',current_table_page);
        } else if(pagename == 'paid_invoices') {
            setHeadlineText('Paid Invoices');
            setTableDateText('Paid');
            tableStatusText = 'Paid';
            getInvoiceTable('C',current_table_page);
        } else if(pagename == 'reports_tax') {
            setHeadlineText('Reports & Tax Forms');
            setTableDateText('Updated');
            tableStatusText = 'Closing';
            loadProjectTableRows([],current_table_page);
        } else if(pagename == 'payment_settings') {
            setHeadlineText('Payment Settings');
            setTableDateText('Updated');
            tableStatusText = 'Creating';
            $("#invoice_view").hide();
            $("#invoice_rows").hide();
            $("#invoice_settings").show();
            setCurrentSettingsView(<PaymentSettings />);
        }

        if(pagename != 'payment_settings') {
            showInvoiceRows();
        }
    }

    function toggleOptionSelect(e) {
        e.preventDefault();
        var option_id = e.target.dataset.optionid;
        var option_title = e.target.dataset.optiontitle;
        if($("#ig-static-post-"+option_id).html() == 'add') {
            $("#ig-static-post-"+option_id).html('check');
            $("#ig-static-post-"+option_id).addClass('green-text');
        } else {
            $("#ig-static-post-"+option_id).html('add');
            $("#ig-static-post-"+option_id).removeClass('green-text');
        }
    }


    if(pageSet == false) {
        setPage(true);
        setTimeout(function(){
            changePageSection('pending_invoices');
            
        },50);
        
    }

    return (<>
    {pageGlobalHeader}
    <div className="row" style={{padding:'0px 20px',margin:'0px',minHeight:'95vh'}}>
        <div className="col s12">
            <div className="row" style={{position:'relative',padding:'0px',margin:'0px',fontSize:'16px',marginTop:'20px',fontFamily:'helvetica'}}>
                <div className="col s12 m12 l3 black-text" style={{padding:'0px',marginTop:'0px'}}>
                    <div className="row transparent" style={{position:'relative',margin:'0px',padding:"0px",marginBottom:'0px',minHeight:'80vh',width:'90%',borderRight:'1px solid '+pageBorderColorClass}}>
                        <div className="col s12 white-text headline-font" style={{position:'relative',margin:'0px',padding:"10px 20px 10px 20px",fontSize:'30px'}}>
                            Payments Manager
                        </div>
                        <div className="col s12 white-text" style={{position:'relative',margin:'0px',padding:"0px 20px",fontSize:'14px'}}>
                            Quickly create new invoices, get paid and view previous payments.
                        </div>
                        <div className="col s12 subheadline-font white-text" style={{padding:'0px',marginTop:'40px',width:'80%'}}>
                            <div id="pending_invoices_button" className="section-left-rail-button section-left-rail-button-selected" onClick={()=>changePageSection('pending_invoices')}>
                                Pending Invoices
                            </div>
                            <div id="paid_invoices_button" className="section-left-rail-button" onClick={()=>changePageSection('paid_invoices')}>
                                Paid Invoices
                            </div>
                            <div id="reports_tax_button" className="section-left-rail-button" onClick={()=>changePageSection('reports_tax')} style={{display:'none'}}>
                                Reports & Taxes
                            </div>
                            <div id="payment_settings_button" className="section-left-rail-button" onClick={()=>changePageSection('payment_settings')}>
                                Payment Settings
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col s12 m12 l9 black-text" style={{padding:'0px',paddingRight:'10px',marginTop:'0px'}}>
                    
                    
                    <div id="invoice_rows">
                        <div className="row rounded-section transparent" style={{position:'relative',margin:'0px',padding:"10px 20px",marginBottom:'0px'}}>
                            <div className="col s12 white-text" style={{padding:'0px',paddingBottom:'0px'}}>
                                <div className="left white-text headline-font" style={{position:'relative',margin:'0px',padding:"0px",fontSize:'30px'}}>
                                    <span className="left">{headlineText}</span>
                                    <span className="left material-symbols-outlined" 
                                        style={{marginLeft:'10px',marginTop:'12px',cursor:'pointer'}}
                                        title="Create New Invoice"
                                        onClick={()=>showInvoiceCreator()}>add_circle</span>
                                </div>
                                <div className="right right-align" style={{marginRight:'0px',marginTop:'0px',marginBottom:'0px'}}>
                                    <div className="left" style={{fontSize:'12px',marginRight:'10px',marginTop:'20px',color:pageBorderColorClass}}>Total Value</div>
                                    <div className="left headline-font" style={{fontSize:'30px',color:pageAccentColorClass}}>{primary_currency+payment_section_total.toLocaleString('en-US')}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row rounded-section" style={{position:'relative',margin:'0px',padding:"10px",marginBottom:'20px',minHeight:'70vh'}}>
                            <div className="col s12 white-text" style={{padding:'10px',paddingBottom:'0px'}}>
                                <div className="row" style={{position:'relative',margin:'0px',padding:"0px",fontSize:'14px',marginBottom:'5px',fontWeight:'900'}}>
                                    <div className="col s12 m2 l3">
                                        Invoice Name
                                    </div>
                                    <div className="col s12 m2 l2">
                                        Client Name
                                    </div>
                                    <div className="col s12 m2 l3">
                                        Deliverables
                                    </div>
                                    <div className="col s12 m2 l1 center-align">
                                        Status
                                    </div>
                                    <div className="col s12 m2 l2 center-align">
                                        {tableDateText}
                                    </div>
                                    <div className="col s12 m2 l1 center-align">
                                        Amount
                                    </div>
                                </div>
                                <div className="row" style={{border:'1px solid #EBEBEB',height:'1px',marginBottom:'15px',display:'none'}}>
                                </div>
                            </div>
                            {tableRowsHTML}
                            <div className="col s12 white-text" style={{padding:'10px',paddingBottom:'0px'}}>
                                <div className="row" style={{margin:'0px',padding:"0px",marginBottom:'5px'}}>
                                    <div id="show_more_button" className="center subheadline-text section-left-rail-button" 
                                        style={{margin:'0px',fontSize:'16px',display:'none'}} onClick={()=>showMoreRows()}>
                                        Show More
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="invoice_view">
                        {currentInvoiceView}
                    </div>
                    <div id="invoice_settings" style={{display:'none'}}>
                        {currentSettingsView}
                    </div>
                </div>
            </div>
        </div>
    </div></>
    );
};