import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import {axiosInstance, closeAllModals, dateSlashToDash, dateDashToSlash, dateDaysBetween, randomNumberString} from "../../utils";
import '../../stylesheets/payments.css';

let invoice_info = [];

const InvoiceEditor = (props) => {    
    const { memberID, orgID } = useSelector((state) => state.login);

    const [pageSet, setPage] = useState(false);
    const [headerHTML, setHeaderHTML] = useState(null);
    const [linksHTML, setLinksHTML] = useState(null);
    const [optionHTML, setOptionHTML] = useState(null);
    const [invoiceDetailsHTML, setInvoiceDetailsHTML] = useState([]);
    const [invoiceItemsHTML, setInvoiceItemsHTML] = useState([]);
    const [currency_symbol,setCurrencySymbol] = useState('$');
    const [invoice_notes,setInvoiceNotes] = useState('');
    const [invoiceSummary, setInvoiceSummary] = useState([]);
    const [invoiceID, setInvoiceID] = useState(props.invoiceID);
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [total_due_amount, setTotalDueAmount] = useState(0.00);

    let startingRate = 0;

    useEffect(() => {
        document.body.style.backgroundColor="#F7F7F7";
        showDatePickers();
    }, []);

    function grabInvoiceInfo(loadsection='all') {
        if(props.invoiceID != null) {
            setInvoiceID('ILN7pOy9Id');
        } else {
            setInvoiceID(props.invoiceID);
        }
        var addData = new Object();
        addData.invoiceUniqID = encodeURIComponent(invoiceID);
        axiosInstance.post('payments/invoice_info/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var invoice_details = result.data; 
                invoice_info = invoice_details;
                if(invoice_details.length > 0) {
                    if(loadsection == 'reload_details') {
                        loadInvoiceDetails(invoice_details[0]);
                    } else if(loadsection == 'reload_items') {
                        loadInvoiceItems(invoice_details[0]);
                    } else {
                        loadInvoiceDetails(invoice_details[0]);
                        loadInvoiceItems(invoice_details[0]);
                    }
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function showSelectForms() {
        var select_elems = document.querySelectorAll('select');
        var instances = window.M.FormSelect.init(select_elems);
    }

    function showDatePickers() {
        var date_elems = document.querySelectorAll('.datepicker');
        var instances = window.M.Datepicker.init(date_elems,{
            format: 'mm/dd/yyyy',
            onClose: function() {
                var input_id = $(this)[0]['el'].id;
                if(input_id == 'invoice-date-start') {
                    updateDatesNet('startdate');
                } else if(input_id == 'invoice-date-due') {
                    updateDatesNet('enddate');
                }
            }
        });
    }

    function updateDatesNet(updatesource) {
        var net_days = $("#invoice-net-days").val();
        var date_start = $("#invoice-date-start").val();
        var date_due = $("#invoice-date-due").val();

        if(net_days > 365) {
            net_days = net_days.substring(0,3);
            $("#invoice-net-days").val(net_days);
        }  

        if(updatesource == 'startdate') {
            
            if((date_start.length > 0)&&(date_due.length > 0)) {
                console.log(date_start, date_due);
                var new_netdays = dateDaysBetween(date_start,date_due,'text');
                $("#invoice-net-days").val(new_netdays);

            } else if((net_days.length > 0)&&(date_start.length > 0)) {
                var new_enddate = dateAddSubtract(date_start,'text','add',net_days,'text');
                $("#invoice-date-due").val(new_enddate);

                var delem = document.getElementById('invoice-date-due');
                var dinstance = window.M.Datepicker.getInstance(delem);
                dinstance.setDate(new Date(new_enddate));
            }
        } else if(updatesource == 'enddate') {
            if((date_start.length > 0)&&(date_due.length > 0)) {
                var new_netdays = dateDaysBetween(date_start,date_due,'text');
                $("#invoice-net-days").val(new_netdays);

            } else if((net_days.length > 0)&&(date_due.length > 0)) {
                var new_startdate = dateAddSubtract(date_due,'text','sub',net_days,'text');
                $("#invoice-date-start").val(new_startdate);

                var delem = document.getElementById('invoice-date-start');
                var dinstance = window.M.Datepicker.getInstance(delem);
                dinstance.setDate(new Date(new_startdate));
            }
        } else if(updatesource == 'netdays') {
            if((net_days.length > 0)&&(date_start.length > 0)) {
                console.log(net_days,date_start);
                var new_enddate = dateAddSubtract(date_start,'text','add',net_days,'text');
                $("#invoice-date-due").val(new_enddate);

                var delem = document.getElementById('invoice-date-due');
                var dinstance = window.M.Datepicker.getInstance(delem);
                dinstance.setDate(new Date(new_enddate));

            } else if((net_days.length > 0)&&(date_due.length > 0)) {
                var new_startdate = dateAddSubtract(date_due,'text','sub',net_days,'text');
                $("#invoice-date-start").val(new_startdate);

                var delem = document.getElementById('invoice-date-start');
                var dinstance = window.M.Datepicker.getInstance(delem);
                dinstance.setDate(new Date(new_startdate));
            }
        }
    }

    function dateAddSubtract(datetxt,datetype,datedirection,daysamount,returntype) {
        var currentdate;
        if(datetype == 'text') {
            var datesplit = datetxt.split("/");
            currentdate = new Date(datesplit[2],(datesplit[0]-1),(datesplit[1]));
        } else {
            currentdate = datetxt;
        }
        
        var current_timestamp = currentdate.getTime();
        var next_timestamp = current_timestamp;
        if(datedirection == 'sub') {
            next_timestamp = current_timestamp - (daysamount*(1000 * 3600 * 24));
        } else {
            next_timestamp = current_timestamp + (daysamount*(1000 * 3600 * 24));
        }
        let nextdate = new Date(next_timestamp);

        if(returntype == 'text') {
            var currentmonth = nextdate.getMonth()+1;
            var currentyear = nextdate.getFullYear();
            var currentday = nextdate.getDate();

            var final_date = '';
            if(currentmonth < 10) {
                final_date = final_date + '0';
            }
            final_date = final_date + currentmonth+'/';
            if(currentday < 10) {
                final_date = final_date + '0';
            }
            final_date = final_date + currentday+'/';
            final_date = final_date + currentyear;

            return final_date;
        } else {
            return nextdate;
        }
    }

    function invoiceNumberRandom() {
        var number_length = 6;
        var random_string = randomNumberString(number_length);

        $("#invoice-number-form").val(random_string);
    }

    function toggleEditDetails() {
        if($("#invoice-details-update-form").is(":visible")) {
            $("#invoice-details-update-form").hide();
            $("#invoice-details-display").show();
        } else {
            $("#invoice-details-update-form").show();
            $("#invoice-details-display").hide();
        }
    }

    function toggleEditClient() {
        if($("#client-update-form").is(":visible")) {
            $("#client-update-form").hide();
            $("#client-display-form").show();
        } else {
            $("#client-update-form").show();
            $("#client-display-form").hide();
        }
    }

    function loadInvoiceDetails(idetails) {
        var invoice_id = idetails['invoice_id'];
        var invoice_unique_id = idetails['invoice_unique_id'];
        var invoice_number_alt = idetails['invoice_alternate_number'];
        var invoice_status = idetails['invoice_status'];
        var client_name = idetails['client_name'];
        var client_website = idetails['client_website'];
        var client_email = idetails['client_email_address'];
        var client_company = idetails['client_company_name'];
        var invoice_due_date_terms = idetails['due_date_terms_days'];
        var invoice_date_start = idetails['date_invoice_start'];
        var invoice_date_due = idetails['date_invoice_due'];
        var start_date_month = idetails['invoice_start_month'];
        var due_date_month = idetails['invoice_due_month'];
        var start_date_text = idetails['invoice_start_monthname'].substring(0,3)+" "+idetails['invoice_start_day']+", "+ idetails['invoice_start_year'];
        var due_date_text = idetails['invoice_due_monthname'].substring(0,3)+" "+idetails['invoice_due_day']+", "+ idetails['invoice_due_year'];
        var invoice_days_remaining = idetails['days_remaining']; 
        var invoice_subtotal = idetails['subtotal'];
        var invoice_fees = idetails['platform_fees'];
        var invoice_amount_paid = idetails['amount_paid'];
        var invoice_total_due = idetails['total_due'];
        var invoice_currency = idetails['invoice_currency'];
        var invoice_custom_notes = idetails['custom_notes'];

        var currency_symbol = '$';
        if(invoice_currency == 'USD') {
            currency_symbol = '$';
        }
        setInvoiceNumber(invoice_number_alt);

        var invoice_notes = <>Thank you for your business! <br/>Please contact us at support@shake.com for any questions about this invoice.</>;
        if(invoice_custom_notes.length > 0) {
            invoice_notes = invoice_custom_notes;
        }
        setInvoiceNotes(invoice_notes);

        var tmp_html = <><div className="row">
                            <div className="col s12 m6" style={{padding:'10px'}}>
                                <div id="client-display-form" className="row" style={{margin:'0px',padding:"0px 5px"}}>
                                    
                                    <div className="col s12" style={{margin:'0px',padding:"0px 5px"}}>
                                        {client_company}
                                    </div>
                                    <div className="col s12" style={{margin:'0px',padding:"0px 5px"}}>
                                        {client_name}
                                    </div>
                                    <div className="col s12" style={{margin:'0px',padding:"0px 5px"}}>
                                        {client_website}
                                    </div>
                                    <div className="col s12" style={{padding:'0px',marginBottom:'10px',marginTop:'10px',fontSize:'14px'}}>
                                        <div className="left" style={{cursor:'pointer',borderRadius:'10px',padding:'0px 10px 2px 10px',border:'1px solid #EBEBEB'}} 
                                            onClick={()=>toggleEditClient()} >
                                            <span className="material-symbols-outlined" style={{verticalAlign:'text-bottom',fontSize:'16px'}}>edit</span>&nbsp;&nbsp;Edit Bill To
                                        </div>
                                    </div>
                                </div>
                                <div id="client-update-form" className="row" style={{display:'none',margin:'0px',padding:"0px 5px",border:'1px solid #EBEBEB',borderRadius:'10px'}}>
                                    <div className="col s12" style={{padding:'10px 20px'}}>
                                        <div className="row headline-font" style={{fontSize:'24px',padding:'0px 10px',marginBottom:'10px'}}>
                                            Bill To Details
                                        </div>
                                        <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px'}}>
                                            <div className="section-divider"></div>
                                        </div>
                                        <div className="row" style={{marginBottom:'10px'}}>
                                            <div className="col s12">
                                                <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                    Company Name
                                                </div>
                                                <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                    <input type="text" id="invoice-client-company"
                                                    className="left browser-default rounded-input "
                                                    style={{width:"98%",marginRight:'2%'}} 
                                                    defaultValue={client_company} />
                                                </div>
                                            </div>
                                            <div className="col s12">
                                                <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                    Contact Full Name
                                                </div>
                                                <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                    <input type="text" id="invoice-client-name"
                                                    className="left browser-default rounded-input "
                                                    style={{width:"98%",marginRight:'2%'}} 
                                                    defaultValue={client_name} />
                                                </div>
                                            </div>
                                            <div className="col s12">
                                                <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                    Contact Email Address (optional)
                                                </div>
                                                <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                    <input type="text" id="invoice-client-email"
                                                    className="left browser-default rounded-input "
                                                    style={{width:"98%",marginRight:'2%'}} 
                                                    defaultValue={client_email} />
                                                </div>
                                            </div>
                                            <div className="col s12">
                                                <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                    Contact Website (optional)
                                                </div>
                                                <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                    <input type="text" id="invoice-client-website"
                                                    className="left browser-default rounded-input "
                                                    style={{width:"98%",marginRight:'2%'}} 
                                                    defaultValue={client_website} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginBottom:'10px'}}>
                                            <div id="cancel-contact-button" className="left black-black-link" onClick={()=>toggleEditClient()} style={{marginLeft:'20px',marginTop:'10px'}}>
                                                    Cancel
                                            </div>
                                            <div id="save-contact-button" className="right waves-effect waves-light white-text z-depth-2" 
                                                    style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD',marginRight:'20px'}} 
                                                    onClick={()=>saveInvoiceDetails('client')} >
                                                    Save Client Details
                                            </div>
                                            <div id="save-contact-loading" className="right" style={{display:'none',marginRight:'20px',fontSize:'18px'}}>
                                                <div class="preloader-wrapper small active">
                                                    <div class="spinner-layer spinner-blue-only">
                                                    <div class="circle-clipper left">
                                                        <div class="circle"></div>
                                                    </div><div class="gap-patch">
                                                        <div class="circle"></div>
                                                    </div><div class="circle-clipper right">
                                                        <div class="circle"></div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 m6" style={{padding:'10px'}}>
                                <div id="invoice-details-display" className="row right-align" style={{margin:'0px',padding:"0px 5px"}}>
                                    
                                    <div className="col s12" style={{margin:'0px',padding:"0px 5px"}}>
                                        <div className="col s7 m8">
                                            Invoice ID:
                                        </div>
                                        <div className="col s5 m4">
                                            {invoice_number_alt}
                                        </div>
                                    </div>
                                    <div className="col s12" style={{margin:'0px',padding:"0px 5px"}}>
                                        <div className="col s7 m8">
                                            Invoice Date:
                                        </div>
                                        <div className="col s5 m4">
                                            {start_date_text}
                                        </div>
                                    </div>
                                    <div className="col s12" style={{margin:'0px',padding:"0px 5px"}}>
                                        <div className="col s7 m8">
                                            Terms:
                                        </div>
                                        <div className="col s5 m4">
                                            Net {invoice_due_date_terms} Days
                                        </div>
                                    </div>
                                    <div className="col s12" style={{margin:'0px',padding:"0px 5px",fontWeight:'bold'}}>
                                        <div className="col s7 m8">
                                            Due Date:
                                        </div>
                                        <div className="col s5 m4">
                                            {due_date_text}
                                        </div>
                                    </div>
                                    <div className="col s12" style={{marginBottom:'10px',marginTop:'10px',fontSize:'14px'}}>
                                        <div className="right" style={{cursor:'pointer',borderRadius:'10px',padding:'0px 10px 2px 10px',border:'1px solid #EBEBEB'}} 
                                            onClick={()=>toggleEditDetails()} >
                                            <span className="material-symbols-outlined" style={{verticalAlign:'text-bottom',fontSize:'16px'}}>edit</span>&nbsp;&nbsp;Edit Details
                                        </div>
                                    </div>
                                </div>
                                <div id="invoice-details-update-form" className="row" style={{display:'none',margin:'0px',padding:"0px 5px",border:'1px solid #EBEBEB',borderRadius:'10px'}}>
                                    <div className="col s12" style={{padding:'10px 20px'}}>

                                        <div className="row headline-font" style={{fontSize:'24px',padding:'0px 10px',marginBottom:'10px'}}>
                                            Invoice Details
                                        </div>
                                        <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px'}}>
                                            <div className="section-divider"></div>
                                        </div>
                                        <div className="row" style={{marginBottom:'10px'}}>
                                            <div className="col s12">
                                                <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                    <span className="left">Invoice Number</span>
                                                    <span className="right black-black-link" onClick={()=>invoiceNumberRandom()}  style={{marginRight:'20px',fontSize:'14px'}}>
                                                        Randomize
                                                    </span>
                                                </div>
                                                <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                    <input type="text" id="invoice-number-form"
                                                    className="left browser-default rounded-input"
                                                    style={{width:"98%",marginRight:'2%'}} 
                                                    defaultValue={invoice_number_alt} />
                                                </div>
                                            </div>
                                            <div className="col s12">
                                                <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                    Invoice Start Date
                                                </div>
                                                <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                    <input type="text" id="invoice-date-start"
                                                    className="left browser-default datepicker rounded-input "
                                                    style={{width:"98%",marginRight:'2%'}}
                                                    defaultValue={dateDashToSlash(invoice_date_start)}  />
                                                </div>
                                            </div>
                                            <div className="col s12">
                                                <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                    Net (Days)
                                                </div>
                                                <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                    <input type="text" id="invoice-net-days"
                                                    className="left browser-default rounded-input "
                                                    style={{width:"98%",marginRight:'2%'}}
                                                    onChange={()=>updateDatesNet('netdays')}
                                                    defaultValue={invoice_due_date_terms} />
                                                </div>
                                            </div>
                                            <div className="col s12">
                                                <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                    Invoice Due Date
                                                </div>
                                                <div className="row" style={{padding:'0px 10px',marginBottom:'10px'}}>
                                                    <input type="text" id="invoice-date-due"
                                                    className="left browser-default datepicker rounded-input "
                                                    style={{width:"98%",marginRight:'2%'}}
                                                    defaultValue={dateDashToSlash(invoice_date_due)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginBottom:'10px'}}>
                                            <div id="cancel-details-button" className="left black-black-link" onClick={()=>toggleEditDetails()} style={{marginLeft:'20px',marginTop:'10px'}}>
                                                    Cancel
                                            </div>
                                            <div id="save-details-button" className="right waves-effect waves-light white-text z-depth-2" 
                                                    style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD',marginRight:'20px'}} 
                                                    onClick={()=>saveInvoiceDetails('details')} >
                                                    Save Invoice Details
                                            </div>
                                            <div id="save-details-loading" className="right" style={{display:'none',marginRight:'20px',fontSize:'18px'}}>
                                                <div class="preloader-wrapper small active">
                                                    <div class="spinner-layer spinner-blue-only">
                                                    <div class="circle-clipper left">
                                                        <div class="circle"></div>
                                                    </div><div class="gap-patch">
                                                        <div class="circle"></div>
                                                    </div><div class="circle-clipper right">
                                                        <div class="circle"></div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div></>;
            setInvoiceDetailsHTML(tmp_html);
            
            showDatePickers();

            setTimeout(function(){
                if(invoice_date_start.length > 0) {
                    var delem = document.getElementById('invoice-date-start');
                    var dinstance = window.M.Datepicker.getInstance(delem);
                    dinstance.setDate(new Date(invoice_date_start));
                }
                
                if(invoice_date_due.length > 0) {
                    var delem2 = document.getElementById('invoice-date-due');
                    var dinstance2 = window.M.Datepicker.getInstance(delem2);
                    dinstance2.setDate(new Date(invoice_date_due));
                }
            },2000);
            
    }

    function loadInvoiceItems(idetails) {
        var invoice_id = idetails['invoice_id'];
        var invoice_unique_id = idetails['invoice_unique_id'];
        var invoice_number_alt = idetails['invoice_alternate_number'];
        var invoice_subtotal = idetails['subtotal'];
        var invoice_currency = idetails['invoice_currency'];
        var invoice_items = idetails['invoice_items'];

        var currency_symbol = '$';
        if(invoice_currency == 'USD') {
            currency_symbol = '$';
        }
        
        var item_html = [];
        var item_subtext = '';
        for(var j = 0; j < invoice_items.length;j++) {
            var item_name = invoice_items[j]['item_name'];
            var item_description = invoice_items[j]['item_description'];
            var item_price = invoice_items[j]['item_price'];
            var item_quantity = invoice_items[j]['item_quantity'];
            var item_total = invoice_items[j]['item_total'];

            var tmp_item_html = <>
                                <div data-rownum={j} className="row itemrow" style={{position:'relative',borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',fontSize:'14px'}}>
                                    <div className="col s12 m2 l3" style={{padding:'0px 10px'}}>
                                        <input type="text" id={"item_name_"+j} 
                                                className="left browser-default rounded-input "
                                                style={{width:"98%",marginRight:'2%'}}  
                                                defaultValue={item_name} />
                                    </div>
                                    <div className="col s12 m5 l5" style={{padding:'0px 10px'}}>
                                        
                                        <textarea type="text" id={"item_description_"+j}  
                                                className="left browser-default rounded-input "
                                                style={{width:"98%",maxWidth:"98%",marginRight:'2%',minHeight:'70px'}}  
                                                defaultValue={item_description} />
                                    </div>
                                    <div className="col s12 m2 l2 right-align" style={{padding:'0px 10px'}}>
                                        <input type="text" id={"item_price_"+j}  
                                                className="left browser-default rounded-input "
                                                style={{width:"98%",marginRight:'2%'}}  
                                                defaultValue={item_price.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                                onChange={()=>updatePriceTotals()} />
                                    </div>
                                    <div className="col s12 m2 l1 center" style={{padding:'0px 10px'}}>
                                        <input type="text" id={"item_quantity_"+j}  
                                                className="left browser-default rounded-input "
                                                style={{width:"98%",marginRight:'2%'}}  
                                                defaultValue={item_quantity}
                                                onChange={()=>updatePriceTotals()}  />
                                    </div>
                                    <div id={"item_total_"+j} className="col s12 m1 right-align" style={{padding:'0px 10px',fontSize:'16px',marginTop:'12px'}}>
                                        {currency_symbol+item_total.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    </div>
                                    <div id={"item_remove_"+j} style={{position:'absolute',right:'-10px',top:'15px'}}>
                                        <span data-rownum={j} className="material-icons" style={{cursor:'pointer',fontSize:'20px',color:"grey"}} 
                                                 onClick={(e)=>removeInvoiceRow(e)}>delete</span>
                                    </div>
                                </div>
                                <div id={"item_divider_"+j} className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px'}}>
                                    <div className="section-divider"></div>
                                </div></>;
            item_html.push(tmp_item_html);

            if(j > 0) {
                item_subtext = item_subtext+" + ";
            }
            item_subtext = item_subtext+item_description;
        }
        setInvoiceItemsHTML(item_html);
        
        var total_due_final = invoice_subtotal;
        setTotalDueAmount(total_due_final);
    }

    function addNewInvoiceRow() {
        var currentInvoiceRows = [...invoiceItemsHTML];
        var j = 0;
        $(".itemrow").each(function(){
            var rownum = $(this).data("rownum");
            if(rownum > j) {
                j = rownum;
            }
        });

        j = j+1;
        var tmp_item_html = <>
                                <div data-rownum={j} className="row itemrow" 
                                    style={{position:'relative',borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',fontSize:'14px'}}>
                                    <div className="col s12 m2 l3" style={{padding:'0px 10px'}}>
                                        <input type="text" id={"item_name_"+j} 
                                                className="left browser-default rounded-input "
                                                style={{width:"98%",marginRight:'2%'}}  
                                                defaultValue="" />
                                    </div>
                                    <div className="col s12 m5" style={{padding:'0px 10px'}}>
                                    
                                        <textarea type="text" id={"item_description_"+j}  
                                                className="left browser-default rounded-input "
                                                style={{width:"98%",maxWidth:"98%",marginRight:'2%',minHeight:'70px'}}  
                                                defaultValue="" />
                                    </div>
                                    <div className="col s12 m2 right-align" style={{padding:'0px 10px'}}>
                                        <input type="text" id={"item_price_"+j}  
                                                className="left browser-default rounded-input "
                                                style={{width:"98%",marginRight:'2%'}}  
                                                defaultValue="0.00"
                                                onChange={()=>updatePriceTotals()} />
                                    </div>
                                    <div className="col s12 m2 l1 center" style={{padding:'0px 10px'}}>
                                        <input type="text" id={"item_quantity_"+j}  
                                                className="left browser-default rounded-input "
                                                style={{width:"98%",marginRight:'2%'}}  
                                                defaultValue="1"
                                                onChange={()=>updatePriceTotals()}  />
                                    </div>
                                    <div id={"item_total_"+j} className="col s12 m1 right-align" style={{padding:'0px 10px',fontSize:'16px',marginTop:'12px'}}>
                                        $0.00
                                    </div>
                                    <div id={"item_remove_"+j} style={{position:'absolute',right:'-10px',top:'15px'}}>
                                        <span data-rownum={j} className="material-icons" style={{cursor:'pointer',fontSize:'20px',color:"grey"}} 
                                                 onClick={(e)=>removeInvoiceRow(e)}>delete</span>
                                    </div>
                                </div>
                                <div id={"item_divider_"+j} className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px'}}>
                                    <div className="section-divider"></div>
                                </div></>;
        currentInvoiceRows.push(tmp_item_html);
        setInvoiceItemsHTML(currentInvoiceRows);
    }

    function removeInvoiceRow(e) {
        e.preventDefault();
        var rownum = e.target.dataset.rownum;
        var newInvoiceRows = [];
        if(window.confirm('Are You Sure You Want To Remove This Item?')) {
            $(".itemrow").each(function(){
                var thisrownum = $(this).data("rownum");
                if(rownum == thisrownum) {
                    $(this).remove();
                    $("#item_divider_"+rownum).remove();
                }
            });
        }

        updatePriceTotals();
    }

    function getFinalItems() {
        var final_items = [];
        $(".itemrow").each(function(){
            var rownum = $(this).data("rownum");
            var item_name = $("#item_name_"+rownum).val();
            var item_description = $("#item_description_"+rownum).val();
            var item_price = $("#item_price_"+rownum).val();
            var item_quantity = $("#item_quantity_"+rownum).val();
            var item_total = item_price*item_quantity;

            var item_object = {};
            item_object['item_name'] = item_name;
            item_object['item_description'] = item_description;
            item_object['item_price'] = item_price;
            item_object['item_quantity'] = item_quantity;
            item_object['item_total'] = item_total;

            if(item_name.length > 0) {
                final_items.push(item_object);
            }
            
        });

        return final_items;
    }

    function updatePriceTotals() {
        var total_value = 0;
        $(".itemrow").each(function(){
            var rownum = $(this).data("rownum");
            var rowprice  = 0;
            if($("#item_price_"+rownum).val().length > 0) {
                rowprice = parseFloat($("#item_price_"+rownum).val().replace(",","").replace("$",""));
            }

            var rowquantity = 0;
            if($("#item_quantity_"+rownum).val().length > 0) {
                rowquantity = parseFloat($("#item_quantity_"+rownum).val().replace(",",""));
            }
            
            var item_value = rowprice*rowquantity;
            var item_value_text = currency_symbol+item_value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
            $("#item_total_"+rownum).html(item_value_text);

            total_value = total_value + item_value;
        });

        setTotalDueAmount(total_value);
    }

    function saveInvoiceDetails(detailssection) {
        var invoice_number = $("#invoice-number-form").val();
        var invoice_date_start = dateSlashToDash($("#invoice-date-start").val());
        var invoice_date_due = dateSlashToDash($("#invoice-date-due").val());
        var invoice_net_days = $("#invoice-net-days").val();
        var invoice_support_email = $("#invoice-support-email").val();

        var client_id = $("#invoice-client-id").val();
        var client_name = $("#invoice-client-name").val();
        var client_email = $("#invoice-client-email").val();
        var client_company = $("#invoice-client-company").val();
        var client_website = $("#invoice-client-website").val();

        var addData = new Object();
        addData.id = memberID;
        addData.invoiceUniqID=props.invoiceID;

        var makeupdate = 'Y';
        if(detailssection == 'client') {
            addData.clientID = client_id;
            addData.clientName = client_name;
            addData.clientEmail = client_email;
            addData.clientCompany = client_company;
            addData.clientWebsite = client_website;
            if((client_name.length == 0)&&(client_company.length == 0)) {
                makeupdate = 'N';
                alert('Add Company or Contact Name For Invoice');
            } else {
                $("#save-contact-button").hide();
                $("#save-contact-loading").show();
            }
        } else {
            addData.invoiceNumber = invoice_number;
            addData.dateStart = invoice_date_start;
            addData.dateDue = invoice_date_due;
            addData.dueTerms = invoice_net_days;
            addData.supportEmail = invoice_support_email;
            if((invoice_number.length == 0)||(invoice_number == 0)) {
                makeupdate = 'N';
                alert('Add Invoice Number');
            } else if((invoice_date_start.length == 0)&&(invoice_date_due.length == 0)) {
                makeupdate = 'N';
                alert('Add Invoice Start Date or Invoice Due Date');
            } else {
                $("#save-details-button").hide();
                $("#save-details-loading").show();
            }
        }
        
        if(makeupdate == 'Y') {
            axiosInstance.post('payments/invoice_update/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    
                    grabInvoiceInfo('reload_details');

                    setTimeout(function(){
                        if(detailssection == 'client') {
                            $("#save-contact-button").show();
                            $("#save-contact-loading").hide();
                            toggleEditClient();
                        } else {
                            $("#save-details-button").show();
                            $("#save-details-loading").hide();
                            toggleEditDetails();
                        };
                    },2000);
                    
                }
            }).catch(error => {
                console.log(error);
            });
        } 
        
    }

    function saveInvoiceItems() {
        var item_array = getFinalItems();

        var addData = new Object();
        addData.id = memberID;
        addData.invoiceUniqID=props.invoiceID;
        addData.invoiceItems=JSON.stringify(item_array);

        $("#save-items-button").hide();
        $("#save-items-loading").show();
        axiosInstance.post('payments/invoice_add_items/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var final_info = result.data;
                if(final_info[0]['status'] == 'success') {
                    setTimeout(function(){
                        goBackToPage();
                    },3000);
                } else {
                    $("#save-items-button").show();
                    $("#save-items-loading").hide();
                    alert('Whoops! Check your invoice items and try again.');
                }
                
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function goBackToPage() {

        props.goBackLink(props.invoiceID);
    }

    if(pageSet == false) {
        setPage(true);
        setTimeout(function(){
            grabInvoiceInfo();
        },50);
    }

    return (
    <div className="row subheadline-font" style={{position:'relative',fontSize:'16px',padding:'0px',margin:'0px',marginTop:'0px'}}>
                <div className="col s12 black-text" style={{padding:'10px'}}>
                    <div id="collab-request-section" className="row rounded-section white z-depth-1" style={{margin:'0px',padding:"0px",minHeight:'80vh'}}>
                        <div id="project-settings-form" className="col s12" style={{margin:'0px',padding:"20px 30px"}}>
                            <div className="row" style={{fontSize:'30px',margin:'0px'}}>
                                <div className="left headline-font">Edit Invoice #{invoiceNumber}</div>
                                <div className="right" style={{marginRight:'20px',marginTop:'12px',fontSize:'16px',cursor:'pointer'}} onClick={()=>goBackToPage()}>Back</div>
                            </div>
                            <div className="section-divider"></div>
                            <div className="row" style={{margin:'0px'}}>
                                &nbsp;
                            </div>
                            <div className="row" style={{marginTop:'10px',marginBottom:'50px',display:'flex',flexDirection:'row'}}>
                                <div className="col s12" style={{flex:'1',padding:'0px 20px'}}>
                                    {invoiceDetailsHTML}
                                    <div className="row">
                                        <div className="col s12" style={{padding:'10px 20px'}}>
                                            <div className="row light-blue-background" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px'}}>
                                                <div className="col s12 m2 l3" style={{padding:'10px'}}>
                                                    Item
                                                </div>
                                                <div className="col s12 m5" style={{padding:'10px'}}>
                                                    Description
                                                </div>
                                                <div className="col s12 m2 right-align" style={{padding:'10px'}}>
                                                    Unit Price
                                                </div>
                                                <div className="col s12 m2 l1 center" style={{padding:'10px'}}>
                                                    Quantity
                                                </div>
                                                <div className="col s12 m1 right-align" style={{padding:'10px'}}>
                                                    Total
                                                </div>
                                            </div>
                                            {invoiceItemsHTML}
                                            
                                            <div className="row" style={{borderRadius:'10px',padding:'0px 10px',marginBottom:'10px',display:'flex',flexDirection:'row'}}>
                                                <div className="left-align" style={{flex:'1',padding:'0px 10px'}}>
                                                    <div className="row" style={{marginTop:'-10px'}}>
                                                        <div className="col s12"  style={{padding:'10px'}}>
                                                            <div id="add_new_item_button" className="waves-effect waves-light navy-text" 
                                                                    style={{cursor:'pointer',width:'auto',borderRadius:'10px',fontSize:'16px',padding:'5px 20px',border:'1px solid #EBEBEB', background:'white',marginRight:'20px'}} 
                                                                    onClick={()=>addNewInvoiceRow()} >
                                                                    + Add New Item
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row hide-on-med-and-down show-on-large-only" style={{borderRadius:'10px',padding:'0px 10px',marginTop:'0px',marginBottom:'10px'}}>
                                                        Notes:
                                                    </div>
                                                    <div className="row hide-on-med-and-down show-on-large-only" style={{borderRadius:'10px',padding:'0px 10px',marginTop:'10px',marginBottom:'10px',maxWidth:'300px'}}>
                                                        {invoice_notes}
                                                    </div>
                                                </div>
                                                <div className="right-align" style={{padding:'0px 10px',minWidth:'300px',fontSize:'16px'}}>
                                                    <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginTop:'10px',marginBottom:'10px'}}>
                                                        <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                                            Subtotal
                                                        </div>
                                                        <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                                            {currency_symbol+total_due_amount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'10px'}}>
                                                        <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                                            Taxes & Fees
                                                        </div>
                                                        <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                                            {currency_symbol+'0.00'}
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'10px'}}>
                                                        <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                                            Amount Paid
                                                        </div>
                                                        <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                                            -{currency_symbol+'0.00'}
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'0px'}}>
                                                        <div className="section-divider"></div>
                                                    </div>
                                                    <div className="row" style={{borderRadius:'10px',padding:'0px 0px',marginBottom:'10px',fontSize:'20px',fontWeight:'bold'}}>
                                                        <div className="col s8 left-align" style={{padding:'0px 10px'}}>
                                                            Total
                                                        </div>
                                                        <div className="col s4 right-align" style={{padding:'0px 10px'}}>
                                                            {currency_symbol+total_due_amount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="pay-now-divider" className="section-divider"></div>
                            <div className="row center-align" style={{fontSize:'30px',marginBottom:'0px',padding:'20px 30px'}}>
                                <div id="cancel-save-button" className="left black-black-link" onClick={()=>goBackToPage()} style={{marginLeft:'20px',marginTop:'10px',fontSize:'18px'}}>
                                        Back
                                </div>
                                <div id="save-items-button" className="waves-effect waves-light white-text z-depth-2" 
                                        style={{cursor:'pointer',borderRadius:'10px',fontSize:'18px',padding:'5px 20px',border:'3px solid #4360CD', background:'#4360CD',marginRight:'20px'}} 
                                        onClick={()=>saveInvoiceItems()} >
                                        Save Invoice
                                </div>
                                <div id="save-items-loading" style={{display:'none'}}>
                                    <div class="preloader-wrapper small active">
                                        <div class="spinner-layer spinner-blue-only">
                                        <div class="circle-clipper left">
                                            <div class="circle"></div>
                                        </div><div class="gap-patch">
                                            <div class="circle"></div>
                                        </div><div class="circle-clipper right">
                                            <div class="circle"></div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row center-align" style={{fontSize:'30px',marginBottom:'20px'}}>
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
    );
};

export default InvoiceEditor;