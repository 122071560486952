import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import {GlobalHeaderSignup, GlobalHeaderDarkMode, GlobalLeftRail} from '../../components';
import {SignupSplash, AccountDetails, OrgDetails, PaymentSettings} from './Sections';
import {axiosInstance, closeAllModals} from "../../utils";

let current_table_page = 0;
let current_rows_html = [];
let current_invoice_view = 0;
let primary_currency = '$';
let current_color_scheme = 'light';
let tableStatusText = '';
let pageTextColorClass = 'white-text';
let pageTableColorClass = 'table-row-dark';
let pageBorderColorClass = 'white';
let pageAccentColorClass = '#77E7A5';
let pageGlobalHeader = <GlobalHeaderSignup />;
export const AccountSignup = () => {    
    const { memberID, orgID } = useSelector((state) => state.login);

    const [pageSet, setPage] = useState(false);
    const [headlineText, setHeadlineText] = useState('');
    const [tableDateText, setTableDateText] = useState('');
    const [tableRowsHTML, setTableRowsHTML] = useState([]);
    const [payment_section_total, setPaymentSectionTotal] = useState(0);    
    const [currentSignupView,setCurrentSignupView] = useState('');
    const user_member_id = 1;
    const user_org_id = 1;
    let startingRate = 0;

    
    if(current_color_scheme == 'light') {
        pageBorderColorClass = '#132C51';
        pageAccentColorClass = '#77E7A5';
        pageGlobalHeader = <GlobalHeaderSignup />;
    } else {
        pageBorderColorClass = 'white';
        pageAccentColorClass = '#77E7A5';
        pageGlobalHeader = <GlobalHeaderDarkMode />;
    }
    
    useEffect(() => {
        if(current_color_scheme == 'light') {
            document.body.style.background="#FCF5EC";
            document.body.style.backgroundColor="#FCF5EC";
            document.body.style.background="radial-gradient(#FFF7F7,#FCF5EC)";
            document.body.style.background="radial-gradient(#FFFFFF,#F0EDE9)";

            pageTextColorClass = 'navy-text';
            pageTableColorClass = 'table-row-light';
            $(".white-text").each(function(){
                $(this).removeClass("white-text").addClass("navy-text");
            });
            $(".table-row-dark").each(function(){
                $(this).removeClass("table-row-dark").addClass("table-row-light");
            });
        } else {
            document.body.style.background="radial-gradient(#4360CD, #132C51)";
            pageTextColorClass = 'white-text';
            pageTableColorClass = 'table-row-dark';
            $(".navy-text").each(function(){
                $(this).removeClass("navy-text").addClass("white-text");
            });
            $(".table-row-light").each(function(){
                $(this).removeClass("table-row-light").addClass("table-row-dark");
            });
        } 
      }, []);

    function changePageSection(pagename) {
        if(pagename == 'signup_form') {
            setCurrentSignupView(<SignupSplash completedStep={moveToNextStep} />);
        } else if(pagename == 'account_details') {
            setCurrentSignupView(<AccountDetails completedStep={moveToNextStep}  />);
        } else if(pagename == 'organizations') {
            setCurrentSignupView(<OrgDetails completedStep={moveToNextStep}  />);
        } else if(pagename == 'payment_settings') {
            setCurrentSignupView(<PaymentSettings completedStep={moveToNextStep}  />);
        }
    }

    function moveToNextStep(stepnum) {
        if(stepnum == 1) {
            changePageSection('organizations');
        } else if(stepnum == 2) {
            changePageSection('payment_settings');
        }
    }

    if(pageSet == false) {
        setPage(true);
        setTimeout(function(){
            changePageSection('signup_form');
        },50);
        
    }

    return (<>
    {pageGlobalHeader}
    <div className="row" style={{padding:'0px 20px',margin:'0px',minHeight:'95vh'}}>
        <div className="col s12">
            <div className="row" style={{position:'relative',padding:'0px',margin:'0px',fontSize:'16px',marginTop:'20px',fontFamily:'helvetica'}}>
                <div className="col s12 m10 offset-m1 black-text" style={{padding:'0px',paddingRight:'10px',marginTop:'0px'}}>
                    {currentSignupView}
                </div>
            </div>
        </div>
    </div></>
    );
};